.voucherArea {

  label {
    font-size: inherit;
  }

	.voucherCode {
    border-radius: 10px;
    margin-right: 10px;
    // width: 78%
    &:focus {
      color: #000;
      background-color: #fff;
      order-color: #a1a3a7;
      outline: 0;
      box-shadow: none;
    }
  }
  
  #voucherCodeBtn {
    // width: 22%;
  }

  .phlebotomyError {
    color: var(--red);
  }

  .btnVoucherCode {
    background-color: #F6F6F6;
    border: 1px solid #F6F6F6;
    font-weight: 500;
    letter-spacing: 0.2px;
    border-radius: 30px!important;
    color: #3E3C3A !important;

    &:hover {
      background-color: var(--primary-color) !important;
      color: #fff !important;
    }
  }
  @media (max-width: 768px) {
    border-bottom: 2px solid #e2e2e2;
    padding-bottom: 2em;
  }
}