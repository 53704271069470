.demo {

  .manage-account-container .manageAccount {
  	display:none;
  }

  #sidebar-wrapper {
  	// margin-top:100px;

  }
}