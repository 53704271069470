.iconSection{
  color: var(--accent-color);

  .icon{
    &:not(:last-of-type){
      border-right: 1px solid var(--footer-hr-color);
    }

    p{
      font-weight: 400;
    }
  }
}

.disabled{
  color: var(--gray);
  filter: grayscale(1);
  cursor: not-allowed;
}