footer {
  // margin-top: 30px;
  background-color: var(--footer-bg-color);
  color: var(--font-color);
  .navbar-brand svg {
    fill: white;
    width: 85px;
  }

  .app-section{
    background-color: var(--footer-bg-color-apps);
    height: 109px;

    p{
      font-size: 16px;
      font-weight: 600;
      color: var(--title-color);
    }

    img{
      max-height:40px;
      max-width: 132px;
    }
  }

  .disclaimer-section {
    color: var(--title-color);
  }

  .certified-section{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--title-color);
    text-transform: uppercase;
    font-weight: 600;
  }

  hr{
    width: 90%;
    border-color: var(--footer-hr-color);
  }

  .terms-section {
    a{
      color: var(--footer-font-color);
      font-weight: 500;
      letter-spacing: 1px;

      &:hover{
        color: var(--footer-font-color-hover);
      }
    }
  }

  .company-section{
    max-width: 1400px;
    margin-inline: auto;

    img{
      max-width: 122px;
    }

    .company-address,
    .company-name{
      font-size: 10px;
      color: var(--title-color);
    }
  }


  .footer-base {
    color: var(--footer-font-color);
    padding: 30px 0px;
    
    ul {
      list-style: none;
      display: table;
      padding: 0;
      table-layout: fixed;
      border-spacing: 15px 0px;
      margin: 0;
    }

    li {
      display: table-cell;
    }

    a {
      color: var(--footer-font-color);
    }
  }
}

.edge footer{
  .company-section img.forth-logo{
    filter: invert(1);
  }
}

@media only screen and (max-width: 576px) {
  footer {
    text-align: center;
    .navbar-brand, #navbarNav {
      width: 100%;
      text-align: center;
      margin: 0;
    }
  }
  .footer-base {
    ul, li {
      width: 100% !important;
      display: block !important;
    }
    ul {
      margin: 10px 0px !important;
    }
    li {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}