#bespokeContainer {
  font-family: 'Poppins', sans-serif!important;
  color: black;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background: var(--background-color);
  z-index: 6000;
  overflow: scroll;
  overflow-x: hidden;
  height: auto;
  scroll-behavior: smooth;

  h1 {
    font-size: 24px;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    font-family: inherit;
  }

  &.edge {
    --primary-color: #db5a3e;
    --primary-color-hover: #ab432c;
    --primary-color-darker: #8e2e18;
    --background-color: #F0F2F3;
  }

  &.life {
    --primary-color: #5474E5;
    --primary-color-hover: #4762c5;
    --primary-color-darker: #3B52A1;
    --background-color: #F5ECE7;
  }

  #bespokeTestHeader{
    letter-spacing: 0.1em;
    background-color: var(--background-color);
  
    .bespokeTestLogo{
      margin-left: 40px;
      max-width: 205px;
      max-height: 60px;
      width: 100%;
      object-fit: contain;

      .mobile {
        height:35px;
      }
      .partnerMaxHeight {
        max-height: 80px;
      }
    }

    button{
      padding: 10px 20px;
      width: auto;
      border: 1px solid transparent;
      font-weight: 500;
      border-radius: 10px !important;
      font-size: 16px;
      background-color: transparent;
      color: var(--primary-color);
      border-color: var(--primary-color);
      transition: all 0.2s ease-in;

      &:hover {
        background-color: var(--primary-color) !important;
        color: white;
      }
    }

  }
  
  #trustpilotBanner {
    background-color: #726267;
    width: 100%;
    text-align: center;
    padding: 10px;
  
    img{
      max-width: 200px;
      width: 100%;
    }
  }
  
  #bespokeTestContent {

    .title{
      border-bottom: 1px solid #D6C8CD;
    }

    .infoIcons{
      margin: 40px auto 0px auto;
      max-width: 500px;
      
      img{
        padding: 0px 30px;
        max-height: 73px;

        &:not(:last-child){
          border-right: 1px solid #D6C8CD;
        }
      }
    }

    @media screen and (max-width: 768px){
      .headerText h2{
        font-size: 12px;
        font-weight: 300;
        text-align: center;
        padding: 0px 30px;
        margin-bottom: 0px;
        line-height: 20px;
        letter-spacing: 0.8px;
      }

      .infoIcons{
        max-width: fit-content;
        img{
          padding: 0px 10px;
          max-height: 63px;
        }
      }

      .bespokeTitle{
        font-size: 18px;
        font-weight: 400;
      }
    }

    .selected_test{
      border-radius: 10px;

      .image{
        background-color: #D9CDC6;
      }

      .test_title{
        font-size: 16px;
      }

      .test_price{
        font-size: 24px;
        font-weight: 500;

        .price_icon{
          font-size: 16px;
        }
      }
    }

    .healthGoalsButton{
      background-color: #110D15;
      padding: 10px;
      margin-bottom: 30px;
      border-radius: 32px;
      position: relative;
      isolation: isolate;
      
      .selectedArea{
        background-color: #3E3C3A;
        border-radius: 32px;
        z-index: -1;
        transition: all 0.2s ease-in;
      }

      button{
        padding: 10px 20px;
        background: none;
        border: none;
        color: #FFFFFF;
      }
    }

    #bespokeSearch {
      .form-control:focus {
        box-shadow: none;
      }
      
      p{
        color: #7A6E68;
      }

      .bespokeSearchInput{
        height: 50px;
        background: #fff;
        border-radius: 15px;
        border: 2px solid #D9CDC6;

        span {
          color: #3E3C3A;
          opacity: 1;
          border: 0;
          background-color: transparent;
        }
  
        input[type="search"]{
          padding-left: 10px;
          border: 0;
          color:#000000;
    
          &::placeholder{
            color:#00000060;
          }
    
          &:focus{
            background-color: #FFFFFF;
          }
    
          &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            height: 1em;
            width: 1em;
            border-radius: 50em;
            color: black;
            background: url(../img/xmark-light.svg) no-repeat 50% 50%;
            background-size: contain;
            opacity: 1;
          }
        }
      
        button {
          border: 0;
          color: #557479;
          margin-left: -40px;
          z-index: 100;
          &:hover {
            color: #557479!important;
            background-color: transparent!important;
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    .searchPills{
      max-width: unset;
      overflow-x: auto;
      
      &::-webkit-scrollbar {
        display: none;
      }

      .pill{
        background-color: #D9CDC6;
        color: #7A6E68;
        font-size: 14px;
        padding: 5px 10px;
        border-radius: 10px;
        margin: 0px 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: auto;
        min-width: fit-content;
  
        &:first-child{
          margin-left: 0px;
        }
      }
    }

    #testCard {
      background: #fff;
      color: #666;
      border-radius: 20px !important;
      display: flex;
      flex-direction: column;
      align-items: start;
      height: 100%;
      color: #000000;

      &.border{
        border: 1px solid var(--primary-color) !important;
      }

      .groupPlusTestBanner{
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translate(-50%);
        background-color: #FC5555;
        border-radius: 10px;
        text-transform: uppercase;
        color: white;
        font-weight: 500;
        letter-spacing: 1px;
        width: 75%;
        text-align: center;
        padding: 3px;
      }

      .testCard-header{
        .plusTestPrice{
          font-size: 32px;
          font-weight: 500;
          span{
            font-size: 24px;
          }
        }
        .oldPrice{
          font-size: 16px;
          color: #927E85;
          text-decoration: line-through;
          margin-bottom: -5px;
        }

        .discount{
          font-size: 12px;
          color: #FF5555;
          margin-bottom: 0;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
      }

      .testCard-body {
        font-size: 14px;
        flex: 1;
        font-weight: 300;
        @media only screen and (max-width: 767px) {
          font-size: 13px;
          letter-spacing: 0px;
          font-weight: 200;
        }
      }

      .testCard-footer{
        width: 100%;
        
        button{
          margin: 10px 0px;
          padding: 12px;
          width: 100%;
          border: 1px solid transparent;
          font-weight: 500;
          border-radius: 10px;
          font-size: 16px;

          &.add{
            background-color: var(--primary-color);
            color: white;
          }

          &.remove{
            background-color: white;
            color: var(--primary-color);
            border-color: var(--primary-color);
          }
        }
      }

      .testCard-biomarkers{
        background-color: var(--background-color);
        border-radius: 5px;
        font-size: 14px;

        .marker_counter {
          font-weight: 400;
          span{
            font-weight: 600;
          }
        }

        button{
          background-color: transparent;
          border-radius: 10px;
          border: 1px solid #3E3C3A;
          color: #3E3C3A;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.5px;
          margin-top: 10px;
          padding: 15px 20px;
          width: 100%;
        }
      }

      @media screen and (max-width: 768px) {
        .modal[data-type="sidebar-modal"].right .modal-dialog,
        .modal[data-type="biomarkers-modal"].right .modal-dialog{
          width: 100vw;
        }
      }
    }

    #placeholderTestCard {
      background-color: white;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      min-height: 474px;
      color: #BBBBBB;

      main{
        flex-grow: 1;
      }

      .placeholder-title,
      .placeholder-price{
        height: 26.3px;
      }

      .placeholder-button{
        height: 46.8px;
        border-radius: 10px;
        background-color: var(--primary-color, currentcolor);
      }

      .placeholder-biomarkers{
        background-color: var(--background-color);
        border-radius: 5px;

        .placeholder-button{
          height: 50px;
          border-radius: 10px;
          background-color: currentcolor;
        }
      }

      footer{
        background: none;
        color: inherit;
      }
    }

    #healthCategoriesAccordion {
      .slick-slider {
        padding: 0;
      }
      .slick-slide {
        .package_price {
          display: flex!important;
          align-items: stretch!important;
          height: 100%;
        }
      }
  
      .slick-slide > div {
        height: 100%;
      }
  
      .slick-track {
        display: flex !important;
        margin: 0 auto;
      }
  
      .slick-slide {
        height: auto;
        margin: 0 5px;
        margin-bottom: 1.5rem !important;
  
      }
   
      .slick-list {
          margin: 0 -5px;
          overflow: visible;
      }
    }

    .accordion{
      --bs-accordion-border-color: #D9CDC6;
    }

    .accordion-icon {
      max-width: 41.5px;
      max-height: 40px;
      img{
        object-fit: contain;
        width: 100%;
      }
    }

    .accordion-biomarker-count{
      color: #797979;
      font-weight: 300 !important;
    }

    .accordion-body .row {
      div:first-child {
        letter-spacing: unset;
        font-weight: unset;
      }
        
      div{
        background-color: transparent;
        text-transform: unset;
        padding-right: calc(var(--bs-gutter-x) * .5);
        padding-left: calc(var(--bs-gutter-x) * .5);
        margin: unset;
      }

      @media screen and (max-width:768px){
        div{
          padding: unset;
        }
      }
    }
    
  }

  #bespokeTestArea{
    z-index: -1;
  }

  #bespokeCartArea{
    background-color: #FFFFFF;
    z-index: -1;
    padding-bottom: 4rem;

    #orderSummary{
      position: sticky;
      top: 0;
      max-height: 100vh;

      &::-webkit-scrollbar{
        display: none;
      }

      .phlebotomySection{
        a{
          color: #3E3C3A;
          text-decoration: underline;
          font-weight: 400;
        }

        .phlebotomyOptionSelected{
          margin: 5px 0;
          border: 1px solid #E1E2E6;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 400;
          padding: 20px 15px;
  
          svg{
            color: #5474E5;
          }
  
          span{
            font-weight: 300;
          }
        }
      }

      .selectedTestSection{
        .selectedTestItem{
          display: flex;
          width: 100%;
          background-color: #EDEFF4;
          margin: 5px 0;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 400;
          padding: 15px;
        }
      }

      .biomarkerSection{
        overflow-y: scroll;
        max-height: 35vh;

      ::-webkit-scrollbar {
          width: 0px;
        }
        .biomarkerAdded{
          display: flex;
          align-items: center;
          width: 100%;
          background-color: #EDEFF4;
          margin: 5px 0;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 400;
          padding: 15px;

        }

        hr{
          margin-top: 30px;
          color: #D2D8E4;
          opacity: 0.75;
        }
      }

      .discountSection{
        .discountInput{
          display: flex;
          gap: 10px; 

          input[type="text"]{
            flex: 1;
            padding-left: 10px;
            border-radius: 10px;
            color:#000000;
      
            &::placeholder{
              color:#00000060;
            }
      
            &:focus{
              background-color: #FFFFFF;
            }
          }

          button{
            padding: 10px 15px;
            width: auto;
            border: 1px solid transparent;
            font-weight: 500;
            border-radius: 10px !important;
            font-size: 16px;
            background-color: white;
            color: var(--primary-color);
            border-color: var(--primary-color);

            &:hover {
              background-color: var(--primary-color) !important;
              color: white;
            }
          }
        }
      }

      .summarySection{
        & > p{
          font-size: 11px;
        }

        .totalPrice {
          font-size: 22px;
        }

        button{
          margin: 10px 0px;
          padding: 12px;
          width: 100%;
          border: 1px solid transparent;
          font-weight: 500;
          border-radius: 10px;
          font-size: 16px;
          transition: all 0.2s ease-in;
          &.checkout{
            background-color: var(--primary-color);
            color: white;

            &.disabled{
              color: #ffffff4d;
              cursor: not-allowed;
            }

            &:hover{
              background-color: var(--primary-color-hover);
            }
          }

          &.saveAndContinue{
            background-color: transparent;
            color: var(--primary-color);
          }
        }

      }

      button.amendSubscription{
        color: var(--primary-color, #5474E5);
        background-color: transparent;
        border: 1px solid var(--primary-color, #5474E5);
        transition: all 0.2s ease-in;
        &:hover{
          background-color: var(--primary-color, #5474E5);
          color: white;
        }
      }

      .amendSubscriptionLink {
        color: var(--primary-color, #5474E5);
        font-weight: 500;
      }

    }
  }

  #stickyPriceBar{
    background-color: var(--primary-color-darker, #3B52A1);
    transition: all 0.2s ease;
    z-index: 0;
    
    &.bottomHigher{
      bottom: 48px !important;
    }

    &.sticky-bottom{
      bottom: 0px !important;
    }

    .price{
      color: white;
      font-size: 34px;
      font-weight: 500;
      span{
        font-size: 24px;
      }
    }

    button{
      margin: 10px 0px;
      padding: 12px;
      width: 100%;
      border: 1px solid transparent;
      font-weight: 500;
      border-radius: 10px;
      font-size: 16px;
      background-color: var(--primary-color);
      color: white;

      &.disabled{
        color: #ffffff4d;
        cursor: not-allowed;
      }

      &:hover{
        background-color: var(--primary-color-hover);
      }
    }

    .arrowButton{
      position: absolute;
      top: -17px;
      left: 30px;
      background-color: var(--primary-color-darker);
      color: white;
      border-radius: 50px;
      width: 35px;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .fadeIn{
      animation: fadeIn 0.5s forwards;
    }

  }

  #bespokeTestFooter {
    background: #000000;
    color:  #fff;
    h5 {
      font-size: 16px;
    }
    .certifiedIcon {
      width: 25px;
    }
    .smallText {
      font-size: 10px;

      img {
        width: 50%;
      }
    }

    .list-group-item {
      background: none;
      a {
        color: white;
      }
    }

    .paymentSection{
      img{
        max-width: 35px;
      }
    }

    .certificateSection{
      img{
        max-width: 100px;
      }
    }

    .forth-logo{
      max-width: 125px;
    }
  }

  .modal[data-type="sidebar-modal"],
  .modal[data-type="biomarkers-modal"],
  .modal[data-type="collectionMethod-modal"],
  .modal[data-type="renameBespoke-modal"] {
    z-index: 9999;
    &.left .modal-dialog, &.right .modal-dialog {
      position: fixed;
      margin: auto;
      width: 30vw;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      --bs-modal-zindex: 9999 !important;
    }

    &.left .modal-content, &.right .modal-content {
      height: 100%;
      overflow-y: auto;
    }

    &.left .modal-body, &.right .modal-body {
      padding: 15px 15px 80px;
    }

    &.left.fade {
      .modal-dialog {
        left: -320px;
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
      }

      &.in .modal-dialog {
        left: 0;
      }
    }

    &.right.fade {
      background: #000c;
      .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
        background-color: #F3F3FB;
      }

      .modal-content {
        border: 0;
        border-radius: 0;
        background-color: inherit;

        .modal-header {
          border: none;

          button:not(.back-button) {
            border: none !important;
            width: 30px !important;
            padding: 0px !important;
          }

          button.back-button{
            background-color: transparent;
            border: none
          }
        }

        .modal-body{
          .accordion{
            --bs-accordion-border-color: #E8E7F8;

            .accordion-button{
              background-color: transparent;
              border-bottom: 1px solid #E8E7F8;
            }

            .accordion-item:first-child{
              border-radius: 10px 10px 0px 0px;
              overflow: hidden;
            }

            .accordion-item:last-child{
              border-radius: 0px 0px 10px 10px;
              overflow: hidden;
            }

            .accordion-body{
              font-size: 12px;

              h4{
                font-size: 10px;
              }

              .biomarkerAffects{
                border-radius: 15px;
                background-color: #E8E7F8;
                color: #53506E;
                padding: 5px 10px;
                display: flex;

                p{
                  margin-bottom: 0px;
                  font-weight: 500;
                }
              }

              button{
                border: none;
                justify-content: center;
                align-items: center;
              }
            }
          }

          button{
            margin: 10px 0px;
            padding: 12px;
            width: 100%;
            border: 1px solid transparent;
            font-weight: 500;
            border-radius: 10px;
            font-size: 16px;
            transition: all 0.2s ease-in;
            letter-spacing: 0.5px;
            &.bg-blue{
              background-color: #5474E5;
              color: white;
  
              &:hover{
                background-color: #4762c5;
              }
            }

            &.bg-orange{
              background-color: #db5a3e;
              color: white;
  
              &:hover{
                background-color: #ab432c;
              }
            }
  
            &.bg-transparent{
              background-color: transparent;
              color: #5474E5;
              
              &.edge{
                color: #db5a3e;
              }

              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }

      &.show .modal-dialog {
        right: 0;
      }
    }

    button.btn-update{
      padding: 10px 15px;
      width: 100%;
      border: 1px solid transparent;
      font-weight: 500;
      border-radius: 10px !important;
      font-size: 16px;
      background-color: #3E3C3A;
      color: white;
      text-transform: capitalize;
    }
  }

  @media screen and (max-width: 1024px){
    .modal[data-type="sidebar-modal"],
    .modal[data-type="biomarkers-modal"],
    .modal[data-type="collectionMethod-modal"],
    .modal[data-type="renameBespoke-modal"] {
      &.left .modal-dialog, &.right .modal-dialog {
        width: 100vw;
      }
    }
  }

  .no-letter-spacing {
    letter-spacing: 0!important;
  }

  .fadeAnimation{
    animation: fadeIn 0.5s forwards;
  }

  .cursor-pointer{
    cursor: pointer;
  }

  .phlebotomyOptionSelected{
    margin: 5px 0;
    border: 1px solid #E1E2E6;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 400;
    padding: 20px 15px;

    i.fa-check-circle{
      color: var(--primary-color, #5474E5);
    }
  
    i:not(.fa-check-circle){
      color: #ADADAD;
    }

    span{
      font-weight: 300;
    }

    &.selected {
      border: 1px solid var(--primary-color);
    }
  }

  #phlebotomyOptions, .phlebotomyPill {
    a {
      color: #000;
      text-decoration: underline;
    }

    .phlebotomyPill {
      border: 1px solid #E1E2E6;
      border-radius: 10px;
      width: 100%;
      padding: 0.9rem 1rem;
      cursor: default;

      &.disabled {
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        opacity: 0.2;
        cursor: not-allowed;
      }

      &.selected {
        border: 1px solid var(--primary-color, #5474E5);
      }

      i.blue-icon{
        color: var(--primary-color, #5474E5);
      }

      .pillContent {
        display:flex;
        align-items: center;
        span {
          font-weight: 200;
        }

        .phlebotomySelector {
          border-radius: 360px;
          border: 1px solid #707070;
          width:30px;
          height:30px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.selected {
            background: var(--primary-color, #5474E5);
            border: 1px solid var(--primary-color, #5474E5);
            color: white;
          }
        }
      }
    }
  }

  #phlebotomyOptions{
    button.checkout{
      margin: 10px 0px;
      padding: 12px;
      width: 100%;
      border: 1px solid transparent;
      font-weight: 500;
      border-radius: 10px;
      font-size: 16px;
      transition: all 0.2s ease-in;
      background-color: var(--primary-color, #5474E5);
      color: white;

      &.disabled{
        color: #ffffff4d;
        cursor: not-allowed;
      }

      &:hover{
        background-color: var(--primary-color-hover);
      }
    }
  }

  #partnerClinicFinder {
    font-weight: 200;

    .partnerClinicSearch {
      input {
        border: 1px solid #E1E2E6;
        border-radius: 10px;
        padding: 15px 20px;
        font-weight: 200;
        width: 85%;
      }

      .buttonContainer {
        width:15%;
        align-self: stretch;
        .submitClinicSearch {
          background: #3E3C3A;
          width: 50px;
          height: 50px;
          margin: 0 auto;
          &:hover {
            background: #5474E5!important;
          }
        }
      }

      .useLocation {
        background: none;
        color: black;
        font-weight: 500;
        svg{
          margin-right: 5px;
        }
      }
    }

    .mapContainer {
      height: 350px;
    }
  }

  #partnerClinicSearchResults  {
    .clinicResults  {
      .partnerClinic  {
        .distance {
          font-size: 10px;
        }
        h5 {
          text-transform: none;
        }
      }
    }
  }

  #addCustomTest {
    background-color: var(--background-color);
    padding-top: 20px;
    
    .close {
      color: #fff;
      opacity: 1;
      padding: 20px;
    }

    .modal-content {
      text-align:center;
      background: none;
      border: none;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

      .customTest-title{
        font-size: 32px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .customTest-subtitle{
        font-weight: 300;
        letter-spacing: 1px;
        color: #777;
      }

      p {
        margin: 20px 0;
      }

      .bespokeTestPrice{
        font-size: 24px;
      }

      .form-control {
        padding: 20px 10px;
        text-align: center;
        border: 1px solid #59BBB2;
        font-size: 18px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
      }

      a {
        color: #fff;
      }

      .btn-createTest{
        margin: 10px 0px;
        padding: 12px;
        width: 100%;
        border: 1px solid transparent;
        font-weight: 500;
        border-radius: 10px !important;
        font-size: 16px;
        transition: all 0.2s ease-in;
        background-color: var(--primary);
        color: white;

        &:hover{
          background-color: var(--primary-lighter) !important;
        }
      }

      .btn-cancel{
        margin: 10px 0px;
        padding: 12px;
        width: 100%;
        border: 1px solid transparent;
        font-weight: 500;
        border-radius: 10px !important;
        font-size: 16px;
        transition: all 0.2s ease-in;
        border: 1px solid var(--primary);
        background-color: transparent;
        color: var(--primary);

        &:hover{
          color: white;
          background-color: var(--primary) !important;
        }
      }

    }
  }
}

body {

  .tooltip{
    z-index: 9999;
  }

  .modal[data-type="sidebar-modal"],
  .modal[data-type="biomarkers-modal"] {
    --bs-modal-zindex: 9999;
    z-index: 9999;
    font-family: 'Poppins', sans-serif!important;
    color: black;
  

    h1 {
      font-size: 24px;
    }
    h1, h2, h3, h4, h5, h6 {
      font-weight: 500;
      font-family: inherit;
    }

    &.left .modal-dialog, &.right .modal-dialog {
      position: fixed;
      margin: auto;
      width: 30vw;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      --bs-modal-zindex: 9999;
    }
  
    &.left .modal-content, &.right .modal-content {
      height: 100%;
      overflow-y: auto;
    }
  
    &.left .modal-body, &.right .modal-body {
      padding: 15px 15px 80px;
    }
  
    &.left.fade {
      .modal-dialog {
        left: -320px;
        -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
        -o-transition: opacity 0.3s linear, left 0.3s ease-out;
        transition: opacity 0.3s linear, left 0.3s ease-out;
      }
  
      &.in .modal-dialog {
        left: 0;
      }
    }
  
    &.right.fade {
      background: #000c;
      .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
        background-color: #F3F3FB;
      }
  
      .modal-content {
        border: 0;
        border-radius: 0;
        background-color: inherit;
  
        .modal-header {
          border: none;
  
          button:not(.back-button) {
            border: none !important;
            width: 30px !important;
            padding: 0px !important;
          }
  
          button.back-button{
            background-color: transparent;
            border: none
          }
        }
  
        .modal-body{
          .accordion{
            --bs-accordion-border-color: #E8E7F8;
  
            .accordion-button{
              background-color: transparent;
              border-bottom: 1px solid #E8E7F8;
            }
  
            .accordion-item:first-child{
              border-radius: 10px 10px 0px 0px;
              overflow: hidden;
            }
  
            .accordion-item:last-child{
              border-radius: 0px 0px 10px 10px;
              overflow: hidden;
            }
  
            .accordion-body{
              font-size: 12px;
  
              h4{
                font-size: 10px;
              }
  
              .biomarkerAffects{
                border-radius: 15px;
                background-color: #E8E7F8;
                color: #53506E;
                padding: 5px 10px;
                display: flex;
  
                p{
                  margin-bottom: 0px;
                  font-weight: 500;
                }
              }
  
              button.read-more{
                border: none;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                text-align: center;
                width: 100%;
                margin-top: 20px;
                font-size: 16px;
              }
            }
          }
        }
      }
  
      &.show .modal-dialog {
        right: 0;
      }
    }
  }

  @media screen and (max-width: 1024px){
    .modal[data-type="sidebar-modal"],
    .modal[data-type="biomarkers-modal"]{
      &.left .modal-dialog, &.right .modal-dialog {
        width: 100vw;
      }
    }
  }
}

@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }

