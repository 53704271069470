.contentBlock {
  h2 {
  	font-weight:400;
  }

  h4 {
    font-weight: 600;
  }

  p {
    margin-bottom: 1rem!important;
  }
  a {
    color: #000;
    text-decoration: underline;
    font-weight: 400;
  }
  .update {
    background:#3E3C3A;
    border-color:#3E3C3A;

    &:hover {
      background: #000!important;
      border-color: #000!important;
    }
  }

  .modalBack {
    background-color: transparent;
    border: none;
  }

  .pinIcon { 
    text-decoration: none;
    color: var(--primary-color);
    &:hover {
      text-decoration: none;
    }
  }
}