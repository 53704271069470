.quantityOptonArea {
  #quantityCounter {
    display:flex;
    align-items:center;
    .quantityOptonTitle {
      letter-spacing: 2px;
      color:#9B9B9B;
    }
    .number {
      display: flex;
      align-items: center;
    }
    .minus, .plus {
      width: 40px;
      background: #fff;
      border-radius: 10px;
      padding: 3px 5px 3px 5px;
      border: 1px solid #E1E2E6;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      cursor: pointer;
    }
    input {
      height: 34px;
      flex: 1 1;
      text-align: center;
      font-size: 15px;
      color: #000000;
      border: 1px solid #E1E2E6;
      border-radius: 0px;
      display: inline-block;
      vertical-align: middle;
      max-width: 45px;
    }
    .minus {
      border-radius: 10px 0px 0px 10px;
      border-right: 0px;
    }
    .plus {
      border-radius: 0px 10px 10px 0px;
      border-left: 0px;
    }
  }
}