body{
  overflow-x: clip;
}
.StickyCart-margins{
  margin-bottom: -1.5rem;
  margin-top: 8rem;
}
.amendSubscription {
  .saveBespokeTest{
    padding: 0.48rem 0.95rem !important;
  }
}

#edit-subscription{
  width: 99.8vw;
  background: var(--bg-color);
  padding: 0px;
  position: relative;
  left: 50%;  
  margin-left: -50vw;
  margin-top: -2rem !important;
}

.edit-subscription{
  padding-top: 3rem;

  .page-header{
     letter-spacing: 3px;
     text-transform: uppercase;
     font-weight: 300;
     font-size: 20px;
     margin-bottom: 3rem;
     color: var(--title-color);
  }

  .kitBox-collapse{
    color: var(--title-color);
    width: 100%;
    padding: 30px 15px;
    margin-top: -1rem !important;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 15px;

    .price {
      color: var(--font-color);
    }
  }

  .cardPayment{
    background-color: var(--bg-color-lighter);
    border-color: var(--bg-color-lighter);
    margin-top: 2em;

    h3{
      color: var(--title-color) !important;
    }

    .cardInfo{
      color: var(--font-color) !important;
    }

    form{
      padding-top: 30px !important;
    }
  }

  .partnerClinicSearch{
    color: var(--font-color);

    h4{
      color: var(--font-color) !important;
    }
  }

  .missingError{
    border-bottom: 2px solid var(--red) !important;
  }

  .padlock{
    margin-bottom: 0;
    svg{
      color:#23B7B7;
    }
  }

  height: auto;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 3rem !important;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    width: 100%;
    padding: 1em;
    margin: 0;
    color: var(--font-color);

    @media (max-width: 576px) {
      padding-left: 28px;
    }

    strong{
      color: var(--title-color);
    }
  }

  [type="radio"]:checked + label,
  .restart [type="radio"]:not(:checked) + label {
    text-align: left;
    width: 100%;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 11px;
    top: 16px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: var(--primary-color);
    position: absolute;
    top: 20px;
    left: 15px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  @media (max-width: 768px) {
    .react-datepicker{
      width: 50%;
    }

  .restart .datepicker {
    height: 20rem;
    z-index: 9999 !important;
  }
  
}

.searchForm { 
  position: relative;
  input{
    border-radius: 33px;
    background: transparent;
    border: 1px solid #70898D;
    padding-left: 30px;
    color: #666; 
  }
  svg{
    position: absolute;
    color: #70898D;

  }

  i.search-lg{
    top: 13px;
    left: 13px;
    
  }
}
  .searchForm input::placeholder{
    color: #70898D;
    font-size: 15px;
    font-weight: 200;
  }
  .custom-control-input ~ .custom-control-label::before{
    background-color: #F0F2F3;
    border-color: #23B7B7;
    top: 0
  }
  .custom-control-input:checked ~ .custom-control-label::before{
    color: #fff;
    border-color: #23B7B7;
    background-color: #23B7B7;
    top: 0
  }

  .form-check-input{
    border-color: #23B7B7;
    background-color: #F0F2F3;
    margin-left: -1em;
    font-size: 16px;

    &:checked{
      border-color: #23B7B7;
      background-color: #23B7B7;
      &:focus{
        border-color: #23B7B7;
        background-color: #23B7B7;
        outline: 0;
        box-shadow: none;
      }
    }

  }

  .custom-control-input ~ .custom-control-label::after{
    top: 0
  }
  .custom-control-input:checked ~ .custom-control-label::after{

    top: 0
  }

  
  .slick-list {
    overflow: hidden;/*important, allows overflow slides to be visible*/
  }

  .baseTests{
    background: #fff ;
    color: black;
    h2{
      font-weight: 400;
      font-size: 13px;
    }
    .price {
      font-size: 25px;
      font-weight: 400;
      span{
        font-size: 15px;
      }
      .per-test{
        color: #484848;
        font-size: 12px;
        float: right;
        font-weight: 100;

      }
    }
    .btn {
      color: #FAA240;
      border: 1px solid #FAA240;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      background:none;
      &:hover {
        background: #FAA240!important;
      }
    }
    .fix-to-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .test-info{
      margin-bottom: 150px;
    }
  }
  .card-header {
    border-radius: 25px !important;
    a {
      font-size: 10px;
      letter-spacing: 0.1em;
      color: #000!important;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
  .card-body{
    background: none !important;
    .tubeColourId1{
      color: black;
    }
  }

  .border-bottom-grey{
    border-bottom: 1px solid #EDEFF4;
  }
  .card-body {
    background:none;
  }

  .marker_counter {
    font-weight:300;
    color: #47B27A;
    font-size: 10px;
    background: url(../img/biomarkerCounter.svg) bottom no-repeat;
    background-size: contain;
    padding: 12px 8px 5px 8px;
    letter-spacing: 0.1px;
    margin-right: 10px;
  }

  #phlebotomyArea{
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 0;
    overflow-x: hidden;
    height: auto;
  }

  .selected {
    /* border: 1px solid #db5a3e; */
    // box-shadow: 0 0 10px #a8a8a8;
    .btn{
      color: #fff !important;
      background: #FAA240!important;
    }
  }

  .currentTest{
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    background-color: #47B27A; 
  }

  .notCurrentTest{
    margin-top: 21px;
  }
  .w-90{
    width: 90% !important;
  }
  .nav-tabs {
    background-color: #fff;
    #forth-tab{
      color: #5ABBB4;
    }    
    #edge-tab{
      color: #db5a3e
    }
    .active, .nav-link:hover {
      border-bottom: 3px solid #23B7B7;
    }

    .nav-link.active::after{
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #23B7B7;
        content: " ";
        position: absolute;
        bottom: -10px;
        left: 47%;
      }
  }

  .sticky{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    width: 102vw;
    background: var(--bg-color-lighter);
    left: 0em;
    margin-left: calc(50% - 50vw);
    margin-bottom: -1.5rem;
  }

  #phlebotomyArea .phlebotomyOption, 
  #bespoke_test_container .phlebotomyOption{
    background: var(--bg-color-lighter);
    padding: 1em;
    border-radius: 10px;
    filter: drop-shadow(0 2px 5px #0000001A);
  }

  #phlebotomyArea .phlebotomyOption{
    h3{
      color: var(--title-color);
    }
  }
  
  #phlebotomyArea .phlebotomyOption.selected, 
  #bespoke_test_container .phlebotomyOption.selected{
    background: var(--bg-color-lighter);
    outline: 2px solid var(--primary-color);
  }

  #phlebotomyArea #phlebotomyOptions.checkout {
    cursor: pointer;
  }
  #phlebotomyArea .phlebotomyOption.selected .optionButton, 
  #bespoke_test_container .phlebotomyOption.selected .optionButton{
    background: url("../img/icon22-blue.svg") no-repeat;
    width: 24px;
    height: 24px;
    background-size: cover;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 10px;
    border: 0px;
  }

  #phlebotomyArea svg{
    font-size: 24px;
    color: var(--primary-color);
    margin: auto;
    top: 0;
    bottom: 0;
    right: 10px;
    border: 0px;
  }

  #phlebotomyArea .phlebotomyOption .optionButton, #bespoke_test_container .phlebotomyOption .optionButton{
    border: 1px solid var(--primary-color);
    width: 24px;
    height: 24px;
    background-size: 20px;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 10px;
    border-radius: 360px;
  }
  .phlebotomyOption:hover{
    outline: 1px solid var(--primary-color) !important
  }

  #phlebotomyArea #phlebotomyOptions.checkout .phlebotomyOption.disabled{
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.4;
  }

  #phlebotomyArea{
    .fw-normal{
       font-size: 15px;
    }
    .fw-bold {
      font-size: 17px;
    }
  }
  .phlebotomyError{
    color: var(--red);
  }

  .phlebotomyNoSelectError{
    border: var(--red) solid 1px !important
  }

  .white-border{
    background: var(--bg-color-lighter);
    border-radius: 10px;
    box-shadow: 0 2px 5px #0000001A;
    padding: 1em;
    margin-bottom: 1em;
    h4{
      color: var(--title-color);
      font-weight: 500;
    }

    input, select{
      color: var(--accent-color);
    }
  }

  .freq-error{
    border: 1px solid red;
    padding: 1em;
    background-color: #fff;
    .custom-select{
      background-color: #fff;
      color: #000;
    }
  }

  .voucherSuccess{
    color: green
  }

  .disabled{
    pointer-events: none;

    /* for "disabled" effect */
    opacity: 0.5;
    background: #CCC;
  }
  .btnVoucherCode{ 
    color: #DF5E5E;
    background: #fff;
    border: 0;
  }
  .btnVoucherCode:hover{
    color: #DF5E5E !important;
    background: #f6f6f6 !important;
  }

  #voucherForm{
    #voucherCode{
      background-color: #fff;
      color: #000;
    }
    #voucherCode::placeholder{
      color: #cac7c7;
    }

    #voucherCodeBtn{
      background: #557479;
      padding: 10px;
      border-radius: 360px;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 13px;
      padding-left: 2rem;
      padding-right: 2rem;
      border: none;
    }
  }
  .voucher-area{
    border-radius: 10px;
  }
}
  
  




.subscription-kit{
  max-width: 35em;
  margin-left: auto;
  margin-right: auto;
  p {
    margin-bottom: 0;
  }

  h2, h3{
    color: var(--title-color);
  }
} 

@media (max-width: 576px) { 
  .subscription-kit{
    width: 90%;
  }
  .edit-subscription{
    .btn{
      padding: 0.70rem 1.4rem
    }
  }

}
@media (min-width: 576px) {
  .collapse.dont-collapse-sm {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

.edit-subscription{
  .btn{
    padding: 0.60rem 1.2rem
  }
}
.unchanged h3{
  margin-bottom: 1em;
} 
.changed h3{
  margin-bottom: 0em;
}

.subscription-kit h4{
  margin-bottom: 0;
  color: var(--title-color);
}

.kit-status{
  display: inline-block;
  font-size: 18px;
  b{
    font-weight: 700;
  }
}

.badge{
  font-weight: 100;
  font-size: 12px;
  padding: 0.5em;
  float: right;
}
.badge-danger{
  color: #dc3545;
  background-color: #fff;
  border: 1px solid #dc3545;
}

.kit-background{
  padding: 1rem 0;
  background-color: var(--accent-color-lighter);
  height: auto;
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
}

.edit-subscription {
  .radio{
    display: block;
    width: 100%;
    background-color: var(--bg-color-lighter);
    margin-bottom: 1em;
    border-radius: 10px;
    filter: drop-shadow(0 2px 5px #0000001A);
    strong {
      font-size: 15px;
    }
  }
  
  .radio-checked{
    outline: 2px solid var(--primary-color);
  }
}

.edit-date .frequency{
  color: black;
  background-color: white;
  width: 300px;
}

.edit-date .react-datepicker__input-container input{
  width: 300px;
  height: 40px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 1rem;
  padding-left: 13px;
}

.collectionType {
  .radio{
    display: block;
  }
}

.edit-subscription {
  .alert-danger{
    color: white;
    background: var(--red);
  }

  .alert-success{
    color: white;
  }
}

.apply-voucher .voucher-input{
  width: 300px
}
.subscription-kit .changed{
  border-bottom: 2px solid var(--primary-color);
}
.subscription-kit .box-content {
  .changed{
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
    margin-right: 1rem;
  }
}

.subscription-kit .box-content{
  .unchanged{
    margin-bottom: 1em;
    margin-right: 1rem;
  }
}


.edit-subscription .cardForm {
  width: 50%
}

.edit-subscription .clinicFinder {
  margin-left: 2rem;
  @media (max-width: 576px) {
    margin-left: 28px;
  }
}

@media (min-width: 576px) {
.d-sm-grid{
    display: grid !important;
  }
}
.edge {
  .edit-subscription{

    .subscription-kit .changed{
      border-bottom: 2px solid #db5a3e !important;
    }

    #phlebotomyArea, .padlock {
      svg{
        color: #db5a3e
      }
    }

    // [type="radio"]:checked + label:after,
    // [type="radio"]:not(:checked) + label:after {
    //   background: #db5a3e;
    // }

    .custom-control-input:checked ~ .custom-control-label::before{
      color: #fff;
      border-color: #db5a3e;
      background-color: #db5a3e;
    }
    
    .card-header{
      background: #f0f0f0;
    } 
    
  }
}
