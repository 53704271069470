.cardPayment {
  position: relative;
  margin: 50px 0px;
  border: 2px solid #dddddd;
  border-radius: 10px;
  background: #f5f5f5;
  form {
    padding-top: 40px!important;
    padding: 15px;
    .cardInfo {
      color: #3b3a3a!important;
      font-weight: 600!important;
      font-size: 0.9em!important;
      line-height: 0.9em!important;
    }
    .price {
      color: #3b3a3a!important;
      font-weight: 600!important;
      font-size: 2em!important; 
      right: 15px;
      top: 40px;
    }
    h3 {
      color:#3b3a3a!important;
    }
    .btn {
      width: 100%;
      font-size: 1.4em;
    }
  }
  .StripeElement {
    background: white;
    padding: 15px;
  }
  label {
    width:100%;
  }
  .padLock {
    color: white;
    text-align: center;
    font-size: 2.2em;
    height: 50px;
    width: 50px!important;
    margin: 0 auto;
    background: #4ab724;
    border-radius: 360px;
    line-height: 1.6em;
    border: 2px solid #dddddd;
    top: -25px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.kitAppointment, .kitOnly {
  display:none;
}

.kitAppointment.active, .kitOnly.active {
  display:block;
}

.hideCard {
  display:none;
}

.showCard {
  display:block;
  flex-shrink: 0;
}

.error {
  color:red;
}