.contentBlock {
  display: flex;
  flex-direction: column;
  height: 100%;
  
  h2 {
  	font-weight:400;
  }

  p {
    margin-bottom: 1rem!important;
  }
 
  .update {
    background:#3E3C3A;
    border-color:#3E3C3A;
  }

  .modalBack {
    background-color: transparent;
    border: none;
  }

  .disabled{
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
  }
}