.spinner{
  width: fit-content;
  aspect-ratio: 1;
  animation: rotate 2s linear infinite;

  svg{
    aspect-ratio: 1;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}