#cartSummary {
  p{
    margin: 0;
  }
  font-weight: 400;
  .priceValue {
  	font-weight: 600!important;
  	font-size: 16px;
  }
  .smallText {
  	font-size:12px;
  }
  .largeText {
  	font-size: 20px;
  }
  .autoCreditAmount {
  	font-size: 12px;
    color: white;
    padding: 5px 10px;
    background: #3E4884;
    //background-image: linear-gradient(to right, #f97d56, #ff6c66, #ff5d7b, #fc5292, #ef4eab);
  }
  .paymentOptions {
    img {
      width:35px!important;
    }
  }
  .payWithKlarna {
    font-size: 10px;
    cursor:pointer;
  }
  .klarnaModal {
    img {
      width:100px!important;
    }
  }
}
