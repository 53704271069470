
 
#bottom {
  z-index:0!important;
}

 

#bespokeTestTabs {
  .nav-tabs {
    border: 0;
  }
  .nav-link { 
    color: #557479;
    padding: 0px;
    padding-bottom: 5px; 
    border: 0!important; 
    margin: 0 auto;
    width: auto;
    margin-bottom: 20px;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border: 0;
  }
  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background: none;
    border: 0;
    color: #47B27A;
    border-bottom: 3px solid!important;
    border-color: #47B27A;
  }
}

.edge {
  #bespoke_test_header {
    background: /* gradient can be an image */ linear-gradient( to left, #fbbc3c 0%, #fbbc3c 40%, #dd373c 60%, #dd373c 100% ) left bottom transparent no-repeat;
    background-size: 100% 5px;
    background-color: #1d1d1d;
    color: white;
    h1 {
      font-weight: 100;
      font-size: 24px;
    }

    a {
      color: #db5a3e;
      font-weight: 400;
    }
    .bespokeTestLogo.mobile {
      height:35px;
    }
    .closeMobile {
      text-align: center; 
      font-size:35px;
    }
  }
  
}



#bottom {
  z-index: 10;
  position: sticky;
  bottom: 0!important;
  background: #FAA240;
  .bespokeTestPrice {
    font-size: 22px;
    span {
      font-size: 30px;
    }
  }
  .addSubscriptionToCartAndGoToCart {
    letter-spacing: 0.1em;
    background:white;
    border:0;
    color:#FAA240!important;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    &:hover {
      background: #f78501!important;
    }
  }
  .saveBespokeTest {
    letter-spacing: 0.1em;
    background:none;
    border:1px solid #FFFFFF;
    color:#FFFFFF;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
  }
}

@media screen and (max-width: 768px){
  .bottom {
    bottom: 57.3px !important;
  }
}

#checkoutArea,
#bespoke_test_container {

  .fixed{
    padding-top: 80px !important;
  }

  .btn {
    letter-spacing: 0.1em;
  }

  button {
    letter-spacing: 0.1em;
  }

  .modal { 
    background: #d6d5d5a6;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight:100;
    letter-spacing: 0.1em;

  }

  .heading4, .heading5 {
    color: #666!important;
    letter-spacing: 0.1em;

  }
  .heading4 {
    font-size: 24px!important;
    text-transform: uppercase;
    letter-spacing: 0.1em;

  }
  .bg-grey {
    background: #F5F5F5;
  }
  
  .selected_test {
    border-radius: 15px;
    img {
      background: #F5F5F5;
      padding: 35px;
    }
    .itemQuantity {
      width: 25px;
      height: 25px;
      background: #557479;
      border-radius: 360px;
      font-size: 12px;
      color: white;
      text-align: center;
      line-height: 25px;
      position: absolute;
      top: -10px;
      right: 15px;
      font-weight: 700;
    }
    .test_title {
      font-size:24px;
      color:black;
      text-transform: uppercase; 
      font-weight: 400; 
      display:inline-block; 
      vertical-align: middle;
      max-width:200px;
    } 

    .test_type {
      display:inline-block; 
      vertical-align: middle;
      margin-left:15px;
      background:#9398A0;
      color:white;
      padding: 5px 10px;
      text-transform: uppercase;
      font-size: 12px;
      &.sale {
        background: #DF5E5E;
      }
    } 

    .test_price {
      color: black;
      font-weight:400;
      font-size: 28px;
      .price_icon {
        font-size: 22px;
      }
      .test_price_small {
        font-size: 12px; 
        margin-top: -5px;
        font-weight:300;
      }
    }

    .card {
      border:0;
      border-radius: 15px!important;
      .card-body {
        border-bottom: 1px solid #eceff4!important;
      }

      ul li {
        margin-bottom:5px;
      }
      .card-header {
        border:0;
        border-top: 1px solid #EDEFF4!important;
        border-bottom: 1px solid #EDEFF4!important;
        border-radius: 0;
        background: none;
        &.border-bottom-0 {
          border-bottom:0!important;
        }
        &.no-borders {
          border:0!important;
          h5 {
            color: #557479;
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
          }
        }
        a {
          letter-spacing: 0.1em;
          color: #557479;
          font-size:14px;
          text-transform: uppercase;
          text-decoration: none;
          svg{
            margin-top: 2px;
          }
        }
      }
    }

  }

  a {
    &:hover,
    &:focus {
      text-decoration: none; 
    }
    .marker_counter {
      font-weight:300;
      color: #47B27A;
      font-size: 10px;
      background: url(../img/biomarkerCounter.svg) bottom no-repeat;
      background-size: contain;
      padding: 12px 8px 5px 8px;
      letter-spacing: 0.1px;
      margin-right: 10px;
    }

  }

   


  .border-bottom-grey {
    border-bottom: 1px solid #EDEFF4;
  }

  .package_title2 {
    color: black;
    font-size: 16px;
    h2 {
      font-weight:400;
      font-size: 16px;
    }
  }

  .plusTestPrice {
    color:black; 
    font-size: 25px;
    font-weight: 400;
    span {
      font-size: 14px;
    }
  }

  .additionalTestsAccordion {
    .card-header {
      border-radius: 360px;
      a {
        letter-spacing: 0.1em;
        color: #557479!important;
        font-weight: 300;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
    .card-body {
      background:none;
    }
    .test_list {
      ul { 

        padding: 0;

        li {
          position: relative;
          vertical-align: top;
          padding: 5px 0; 
          padding-left: 30px;
          margin: 0px;
          color: black;
        }

      }

    }
  }



  .max-height {
    min-height: 180px;
    margin-bottom: 160px;
  }

  .fix-to-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width:100%;
  }

  .btn.add {
    color: #FAA240;
    border: 1px solid #FAA240;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    background:none;
    &:hover {
      background: #FAA240!important;
    }
  }


  .btn.remove {
    color: #557479 !important;
    background: none !important;
    border: 1px solid #557479;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    &:hover {
      background: #557479!important;
      border: 1px solid #557479;
      color: #fff!important;
    }
  }



  .phlebotomyOption {
    border: 1px solid #F6F6F6;
    background: #F6F6F6;
    border-radius: 5px;
    padding: 15px 10px; 
    background-size: 20px;
    position: relative;
    height: 100%;
    
    .font-size-sixteen {
      font-size:16px!important;
    }
    p {
      margin: 0;
    }
    svg{
      color: #FAA240;
      font-size:34px;
    }
    &.guestNoSelectError,
    &.phlebotomyNoSelectError {
      border: 1px solid #E80C0C;
      .optionButton {
        border: 1px solid #E80C0C;
      }
    }
    &:hover {
      cursor:pointer; 
      border: 1px solid #f9a33f;
    }
    &.selected {

      background:none;
      border: 1px solid #f9a33f;
      border-radius: 5px;

      .optionButton { 

        background:url('../img/icon22-yellow.png') no-repeat;
        width: 24px;
        height: 24px;
        background-size: cover;
        margin: auto; 
        top: 0;
        bottom: 0;
        right: 10px;
        border:0px;



      }

    }
    .optionButton {  
      border: 1px solid #f9a33f;
      width: 24px;
      height: 24px;
      background-size: 20px;
      margin: auto; 
      top: 0;
      bottom: 0;
      right: 10px;
      border-radius: 360px;

    }
  }

  .selectedTestItem {
    svg{
      color: #557479;
      font-size: 20px;
      vertical-align: middle;
    }
  }

  

  .phlebotomyError {
    color: #E80C0C;
  }  

  .bespokeTestPrice {
    font-size:24px;
  }

  #bespokeCheckoutArea {
    
    .voucherArea {
      
      #voucherCode {

        width:70%;

      }

      #voucherCodeBtn {
        width:28%;
        background: #557479;
        padding: 10px;
        border-radius: 360px;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 13px;
        margin-left: 2%;
      }

    }
  }
  
}

#bespoke_test_container {
  .testTypeDisplay{
    background-color: #fff;
    text-align: center;
    border: 1px solid #e3e3e3;
  }
  .addSubscriptionToCartAndGoToCart {
    background: #FAA240;
    color:white;
    border: 1px solid #FAA240;
    text-transform: uppercase; 
    font-size: 14px;
    &:hover {
      background: #e6963c!important;
      color: #ffffff!important;
      border: 1px solid #e6963c;
    }
  }
  .font-size-13{
    font-size: 13px;
  }

  .sticky{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    width: 102vw;
    background: #fff;
    left: 0em;
    border-top: 1px solid #DEE2E6;
    margin-left: calc(50% - 50vw);
    margin-bottom: -1.5rem;
    z-index: 99999;
  }

  .saveBespokeTest {
    background: none;
    color:#FAA240;
    border: none!important;
    text-transform: uppercase; 
    font-size: 14px;
    &:hover {
      background: none!important; 
      border: none;
      color: #ff9a26!important;
    }
  }
}

#addCustomTest {
  .cancelAndGoBack {
    color: #fff;
    margin-top:10px;
    font-weight: 400;
    border: 0;
    &:hover {
      color: #2a848c!important;
      font-weight: 400;
      border: 0;
      background: none!important;
    }
  }
}


.edge {
  #addCustomTest {
    .cancelAndGoBack {
      margin-top:10px;
      color: #FF3B00;
      font-weight: 400;
      border: 0;
      &:hover {
        color: #FF3B00!important;
        font-weight: 400;
        border: 0;
        background: none!important;
      }
    }
  }

  #checkoutArea .bespokeTestOverlay {
    background: #F5ECE7 !important;
  }
  #checkoutArea,
  #bespoke_test_container {
    a {
      .marker_counter {
        color: #FF3B00;
        font-size: 10px;
        background: url(../img/biomarkerCounterRed.svg) bottom no-repeat;
        background-size: contain;
      }
    }

    .selected_test .card .card-header a {
      color:#db5a3e;
    }

    .additionalTestsAccordion .card-header {
      background: #f7f7f7;
      a {
        color: #db5a3e !important;
        font-weight: 300;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
    .btn.add {
      color: #FF3B00;
      border: 1px solid #FF3B00;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      &:hover {
        background: #FF3B00!important;
      }
    }
  }

  .test_list ul li.tubeColourId1 { 
    background: url(../img/icon22-yellow.png) left no-repeat;
    background-size: auto;
    background-size: 15px;
  }


  .bespokeTestFooter {
    background: #1d1d1d;
    color:white;
    hr {
      border-top: 1px solid #fff;
    }
    a {
      color: #db5a3e!important;
    }
    .invert {
      filter: brightness(0) invert(1);
    }
  }

  #bespokeCheckoutArea .voucherArea #voucherCodeBtn {
    background: none!important;
    border: 1px solid #ff3c03;
    color: #ff3c03!important;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover {
      color: #fff!important;
      background: #ff3c03!important;
    }
  }

  #checkoutArea .checkoutBar {
    background: none!important;

    .addSubscriptionToCartAndGoToCart {
      background: #FF3B00;
      border: 1px solid #FF3B00;
      &:hover {
        background: #db5a3e!important;
        border: 1px solid #db5a3e;
      }
    }

    .saveBespokeTest {
      color: #FF3B00;
      &:hover {
        color: #db5a3e!important;
      }
    }
  }

  #bespokeCheckoutArea .forthModal {
    background: #1d1d1d;
    background-color: #1d1d1d;
    color:white;
  }

  #bottom .addSubscriptionToCartAndGoToCart {
    color:#FF3B00!important;
  }
}

#bespokeCheckoutArea {
  .forthModal {
    background:url('../img/bespokeModalBg.png') no-repeat;
    background-size: 100%;
    background-position: 0px -180px;
    background-color: white;

    .modal-dialog { 
      max-width: 700px!important;
 
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width:100%;
      h2 {
        text-align: center;
        text-transform: uppercase;
        font-size: 40px;
      }
      h3 { 
        margin-bottom: 30px;
        text-align:center;
      }
      .saveBespokeTest {
        border:1px solid #FAA240!important;
        &:hover {
          color: #FAA240!important;
        }
      }
    }
  }
}

@media (max-width: 576px) { 

  #bespoke_test_container {
    .selected_test {
      .test_title { 

        font-size: 18px;
        margin-bottom: 5px!important;

      }

      .test_price {
        font-size: 20px;
      }
    }

  }

}

#additionalMarkers, .additionalMarkers{
  .modal-header{
    border: 0;

    .close{
      &:after{
        color: var(--closeButtonModal) !important;
      }
    }
  }
  .modal-content{
    background-color: var(--base2) !important;
    color: var(--textColour) !important;
    box-shadow: #0000008a 0 -0.125rem 0.75rem  !important;
  }

  .biomarkerTitle{
    display: flex;
    flex-flow: row wrap;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  
    .circle{
      border-top: 1px solid var(--base2);
      border-bottom: 1px solid var(--base2);
      border-left: 1px solid var(--textColour);
      border-right: 1px solid var(--textColour);
      border-radius: 50%;
      padding: 1rem;
      margin: auto 10px;
      transform: rotate(-45deg) scale(0.7);
      svg{
        width: 30px;
        transform: rotate(45deg);
      }
    }
    
    h3{
      letter-spacing: 4px;
    }
    
    button{
      position: absolute;
      top: 25px;
      right: 30px;
    }
  }

  @media (max-width: 767px){
    .biomarkerTitle{flex-flow: column wrap !important};
  }

  .biomarkersModalList {
    display: flex;
    position: relative;
    flex: 1 1 100%;
    padding: 1rem;
    justify-content: center;

    .biomarkers_item{
      position:relative;
      list-style: none;
      display: flex;
      align-items: center;
      padding: 10px 5px;
      font-size: 14px;
      font-weight: 300;
      line-height: 14px;
      letter-spacing: 1px;
      padding: 10px 5px !important;
      margin: 0 20px;
      text-align: left;

      // &::before{
      //   content: '';
      //   position: absolute;
      //   top: calc(50% - 10px);
      //   left: -20px;
      //   display: inline-block;
      //   height: 20px;
      //   width: 20px;
      //   background-image: url(../img/drop.png);
      //   background-size: contain;
      //   background-repeat: no-repeat;
      // }
    }
  }

  .purchaseBar{
    padding: 1rem;
    background: var(--secondary);

    .price {
      color: black;
      font-weight: 600;
      font-size: 39px;
      text-align: right;
      .pound {
        display: inline;
        font-size: 30px;
      }
      .perTest {
        font-size: 14px;
        font-weight: normal;
        margin-top: -10px;
      }
    }
    .buyTest {
      background: var(--base) !important;
      color: var(--secondary) !important;
      font-weight: 400 !important;
      letter-spacing: 2px !important;
      border: 1px solid var(--base) !important;
      text-transform: uppercase !important;
      font-size: 14px !important;
      &:hover {
        background: var(--secondary-darker) !important;
        color: #ffffff !important;
        border: 1px solid var(--secondary-darker);
      }
    }

    .bespokeTest {
      background: transparent !important;
      color: var(--base) !important;
      letter-spacing: 2px !important;
      font-weight: 400 !important;
      border: 1px solid var(--base) !important;
      text-transform: uppercase !important;
      font-size: 14px !important;
      &:hover {
        background: var(--base) !important;
        color: var(--secondary) !important;
        border: 1px solid var(--base);
      }
    }
  }
}

#phlebotomyOptions {
  .phlebotomyWarning {
    background: #FFEFCC;
    border-radius: 0;
    border: 1px solid #AF722D;
    color: #AF722D;
  }
}
.bespokeTestFooter {
  background: #F6F6F6;
  color:black;
  font-size:12px;
  .footerIcon {
    max-width: 70px;
  }
  .text-uppercase {
    font-weight:normal;
  }
  .footer-base {
    background:none;
    padding:0;
    ul {
      list-style: none;
      display: table;
      table-layout: fixed;
      border-spacing: 15px 0px;
      padding: 0;
      margin: 0 auto;
      margin-bottom: 5px;
      a {
        color:#557479;
      }
    }
  }
  svg{
    font-size: 30px;
    vertical-align: middle;
  }
}



#checkoutArea {
  .bespokeTestOverlay {
    background: #F5ECE7 !important;
    z-index: 6001!important;
    height: auto;
  }

  .btnVoucherCode {
    color: #DF5E5E;
    background: #F6F6F6;
    border: 0;
    svg{
      margin:0 5px;
    }
    &:hover {
      background: #FBFBFB!important;
      color: #DF5E5E!important;
    }
  }

  .text-red {
    color: #DF5E5E!important;
  }
} 

#collapseExample li {
  color:black;
}

.package_header {
  padding: 30px 0 0px;
  .bespokeTestLogo {
    max-width: 250px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
    max-height:29px;
  }

  .kitInformation {
    font-size:16px;
  }
  .heading4 {
    font-size: 31px;
    color:
    #949393;
    text-align: center;
    margin-bottom: 10px;
    text-transform:uppercase;
    font-weight:100;
  }

  .heading5 {
    font-size: 20px;
    color:
    #949393;
    text-align: center;
    margin-bottom: 50px;
    font-weight:300;
  }

  .diagonalLine {
    margin-top: 10em;
  }

  .custom_container2 {
    position:relative;
  }

  .bespokeBox  {

    text-align: center; 
    left: 0;
    margin-top: -10em;

    img {
      max-width: 35em;
    }
  }

  .toggleMarkers.collapsed svg{
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }
  .toggleMarkers svg{
    transform: rotate(180deg);
    transition: transform 0.2s linear;
  }

  .btn-primary.toggleMarkers {
    margin-top: 15px;
    background: none!important;
    width: 100%;
    color: #949393!important;
    border: 0!important;
    span {
      vertical-align: middle;
      color: #949393!important;
    }
    svg{
      background:#f8ac00;
      color: white;
      margin: 0;
      padding: 0;
      padding-left: 0px;
      text-align: center;
      width: 22px;
      height: 22px;
      vertical-align: middle;
      border-radius: 360px;
      font-size: 19px;
      line-height: 22px;
      padding-left: 1px;
      display: inline-block;
    }
  }

  @media (min-width:992px) {
   .collapse.dont-collapse-sm {
    display:block;
    height:auto!important;
    visibility:visible
   }
  }

  

  .bespokeTestDetails {
    color: #fff;
    .btn.btn-primary.addMore {
      background: none;
      border: 1px solid #FF0000;
      color: #FF0000!important;
      font-size: 14px;
      text-transform: uppercase;
      padding: 8px 30px;
      font-weight: 600;
      &:hover {
        background: #FF0000!important;
        color: #fff!important;
      }
    }
    .phlebotomyOption {
      border: 1px solid #000000;
      background: #F6F6F6;
      padding: 15px;
      padding-right: 50px;
      background-size: 20px;
      position: relative;
      p {
        margin: 0;
      }
      &:hover {
        cursor:pointer; 
      }
      &.selected {

        background:none;
        border: 1px solid #37e373;
        border-radius: 5px;

        .optionButton { 

          background:url('../img/icon22-green.png') no-repeat;
          width: 25px;
          height: 25px;
          background-size: cover;
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 10px;
          border:0px;


 
        }

      }
      .optionButton {  
        border: 1px solid #37e373;
        width: 25px;
        height: 25px;
        background-size: 20px;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 10px;
        border-radius: 360px;

      }
    }
    #includedMarkers {
      float: left;
      width: 100%;
      clear: both;
      background: #3f3f3f;
      margin: 20px 17em;
      h4 {
        font-size: 18px;
        font-weight: 500;
        text-align:center;
        text-transform: uppercase;
      }
      .panel-title {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
        color: inherit;
      }
      .panel-heading {
        padding: 20px 10px; 
        color: #8e8e8e;
        a {
          color: #8e8e8e;
        }
      }
      .panel-heading a:after {
        content: "";
        background: url(../img/arrow2.png) center left no-repeat;
        transform: rotate(90deg);
        float: right;
        width: 13px;
        height: 25px;
      }
      .panel-heading a.collapsed:after {
        content: "";
        background: url(../img/arrow1.png) center left no-repeat;
        transform: rotate(0deg);
        float: right;
        width: 13px;
        height: 25px;
      }

      .panel-body {
        padding: 15px;
      }

      .markerList {
        .marker { 
          padding: 15px;
          background: url(../img/icon22-green.png) left no-repeat #000000;
          background-size: 20px;
          background-position: 15px;
          padding-left: 50px;
          margin-bottom: 15px;
          .removeTest {
            cursor: pointer;
            color: #ff3a00;
            text-transform: uppercase;
            font-weight: 600;
          }
        }
      }

      .markar_list {
        margin: 0;
        padding: 0;
        list-style:none;
        li {
          position: relative;
          vertical-align: top;
          padding: 15px 0;
          border-bottom: 1px solid #e3e3e0;
          padding-left: 40px;
          color: #7b7b7b;
          &:before {
            content: "";
            position: absolute;
            background: url(../img/icon21.png) left no-repeat;
            width: 28px;
            height: 27px;
            left: 0;
            top: 0;
            transform: translate(0%,50%);
          }
        }
      }
    }
    .markers {
      margin: 15px 0 0 0;
      padding: 15px 15px;
      -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
      -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
      box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
    }
  }

  .optionMarkersNotice {
    padding:10px 20px;
    margin:10px 0 0 0;
    text-align:center;
    border:1px solid #ffffff;
    background:#22b68f;
    color:#ffffff;
    font-size:15px;
  }

  .package_demo {
    background: url('../img/banner3.png') top center no-repeat;
    background-color: rgba(0, 0, 0, 0);
    width: 100%; 
    background-color:
    #fff;
    min-height: 550px;
    padding: 0 40px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,.31);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,.31);
    box-shadow: 0 0 5px 0
    rgba(0,0,0,.31);
    max-width: 605px;

    p {
      padding: 0;
      color: #949393;
      font-size: 18.5px;
      font-weight: 500;
    }

    .package_title {
      display: flex;
      margin: 30px 0;
      h2 {
        flex-grow: 1;
        float: left;
        color:
        #949393;
        margin-top: 10px;
        font-size: 33px;
      }
      h1 {
        flex-grow: 0;
        float: right;
        font-size: 51px;
        font-weight: 700;
        color:
        #949393;
        margin: 0;
        position: relative;
      }
    }

    .parentBox {
      display: block;
      margin: 0 auto;
      padding: 30px 0;
      max-width: 358px;
      max-height: 276px;
      position: relative;
      .boxLabel {
        position: absolute;
        top: 70%;
        width: 100%;
        text-align: center;
        font-size: 15px;
        font-weight: 100;
        text-transform: uppercase;
        color:
        rgba(0,0,0,.6);
      }
      img {
        max-width:100%;
      }
      .img_title {
        position: absolute;
        background-color:
        #f8ac00;
        border-radius: 10px;
        padding: 5px 14px;
        -webkit-box-shadow: 0 0 5px -3px rgba(0,0,0,.75);
        -moz-box-shadow: 0 0 5px -3px rgba(0,0,0,.75);
        box-shadow: 0 0 5px -3px
        rgba(0,0,0,.75);
        top: 0;
        left: 0;
        z-index: 50;
        top:20px;
        img {
          width: 20px;
          height: 24px;
          display: inline-block !important;
          vertical-align: middle;
          margin-right: 5px;
        }
        h4 {
          font-size: 14px;
          font-weight: 900;
          margin: 0;
          color:
          #fff;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  hr {
    margin:60px 0 0 0;
  }
}

.package_list {
  margin: 20px 0 35px 0;
  .heading4 {
    font-size: 25px;
    color: #949393;
    text-align: center;
    margin-bottom: 50px;
  }
  .testWidth {
    -webkit-transition: width 1s;
    transition: width 1s;
  }
  .showCornerTube {
    position: relative;
  }
  .package_price {
    margin: 15px 0;
    padding: 0;
    -webkit-box-shadow:0 0 5px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 0 5px 0 
    rgba(0,0,0,.12);
    background: #3f3f3f;
    color: #fff;
    &.invalid { 
      opacity: .5;
      pointer-events: none;
      display: none;
    }
    &.remove {
      background:none;
      border:1px solid #36E372;
      h2 {
        background: url(../img/icon22-green.png) right no-repeat;
        background-size: 20px;
      }
    }
     
  } 
  
  .package_price.showCornerTube.KT095:after {
    content: '';
    background: url(../img/corner-yellowtube.png) right top no-repeat;
    background-size: contain;
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .package_price.showCornerTube.KT127:after {
    background: url(../img/corner-purpletube.png) right top no-repeat;
    background-size: contain;
    content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .package_content {
    border-radius: 15px !important;
    margin: 0;
    padding: 20px; 
    .test_list {
      ul {
        padding: 0;
        li {
          padding-left: 30px;
        }
      }
    }
    .plusTestPrice {
      font-weight: 600;
      font-size: 20px;
    }
    .package_title2 {
      display: flex;
      margin: 0;
      h2 { 
        padding-right:30px;
        color: #fff;
        margin: 0 0 15px 0;
        font-size: 22px;
        text-transform: uppercase;
        font-weight:600;
      }
      h3 {
        font-weight: 600;
      }
    }
    .KT092 .package_title2 h2 {
      color: #F9AD00;
    }
    p {
      padding: 0;
      margin: 0;
      color: #fff;
      font-size: 14px;
    }
  }
  .package_details {
    margin: 0;
    padding: 10px;
    .plusTestPrice {
      flex-grow: 0;
      float: left;
      font-size: 35px;
      font-weight: 700;
      color: 
      #949393;
      margin: 5px 0;
      span {
        font-size: 24px;
        font-weight: 700;
        color: 
        #949393;
        vertical-align: super;
        padding: 5px;
      }
    }
    .add {
      font-size: 18px;
    }
    .remove {
      img {
        transform: rotate(45deg);
      }
    }
    .remove, .add {
      padding-top: 15px;
      text-align: center;
      display: block;
      margin-left: auto;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      img {
        margin-right: 10px;
        max-width: 100%;
        width: 15px;
        margin-top: -2px;
 
      }
     
    }

    a.remove, a.add {
      color: #FF3B00!important;
    }
  }

  #includedMarkers {
    float: left;
    width: 100%;
    clear: both;
    background: #3f3f3f;
    h4 {
      font-size: 18px;
      font-weight: 500;
      text-align:center;
      text-transform: uppercase;
    }
    .panel-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      color: inherit;
    }
    .panel-heading {
      padding: 20px 10px; 
      color: #8e8e8e;
      a {
        color: #8e8e8e;
      }
    }
    .panel-heading a:after {
      content: "";
      background: url(../img/arrow2.png) center left no-repeat;
      transform: rotate(90deg);
      float: right;
      width: 13px;
      height: 25px;
    }
    .panel-heading a.collapsed:after {
      content: "";
      background: url(../img/arrow1.png) center left no-repeat;
      transform: rotate(0deg);
      float: right;
      width: 13px;
      height: 25px;
    }

    .panel-body {
      padding: 15px;
    }

    .markerList {
      .marker { 
        padding: 15px;
        background: url(../img/icon22-green.png) left no-repeat #000000;
        background-size: 20px;
        background-position: 15px;
        padding-left: 50px;
        margin-bottom: 15px;
        .removeTest {
          cursor: pointer;
          color: #ff3a00;
          text-transform: uppercase;
          font-weight: 600;
        }
      }
    }

    .markar_list {
      margin: 0;
      padding: 0;
      list-style:none;
      li {
        position: relative;
        vertical-align: top;
        padding: 15px 0;
        border-bottom: 1px solid #e3e3e0;
        padding-left: 40px;
        color: #7b7b7b;
        &:before {
          content: "";
          position: absolute;
          background: url(../img/icon21.png) left no-repeat;
          width: 28px;
          height: 27px;
          left: 0;
          top: 0;
          transform: translate(0%,50%);
        }
      }
    }
  }
  .markers {
    margin: 15px 0 0 0;
    padding: 15px 15px;
    -webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
    box-shadow: 0 0 5px 0 rgba(0,0,0,.12);
  }
}

#checkoutArea {
  .closeChat { 
    position: absolute;
    top: 5px;
    left: 15px;
    border: 1px solid #fe0200;
    z-index: 1000;
    font-size: 15px;
    padding: 6px;
    border-radius: 360px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-top: 20px;


    &:hover {
      cursor:pointer;
    }
    svg{
      color: #fe0200;
      text-align:center;
    }
  }
}

#cartArea {
  .checkoutBar {
    width: 100%;
    padding: 20px 0 20px;
    position: fixed;
    left:0;
    bottom: 0;
    z-index: 99;
    -webkit-box-shadow: 0 -4px 5px 0 rgba(0,0,0,.1);
    -moz-box-shadow: 0 -4px 5px 0 rgba(0,0,0,.1);
    box-shadow: 0 -4px 5px 0 rgba(0,0,0,.1);
    background: #60C2DC;
    background-image: -ms-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: -moz-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: -o-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#60C2DC),color-stop(100,#59BBB2));
    background-image: -webkit-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: linear-gradient(to bottom,#60C2DC 0%,#59BBB2 100%);
  }
  .kitInformation {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: left;
  }
  .kitPrice {
    font-size: 38px;
    line-height: 42px;
    font-weight: 700;
    color: #fff;
    text-align: left;
  }
  .btn {
    border: 0;
    border-radius: 50px;
    max-width: 284px;
    font-size: 13px;
    color: white;
    text-transform: uppercase;
    letter-spacing: .1em;
    padding: 12px 10px;
    width: 100%;
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    background: white;
    color: #ff3a00!important;
    &:hover {
      color:white!important;
    }
  }

  #addCustomTest {
    background-image: -ms-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: -moz-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: -o-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#60C2DC),color-stop(100,#59BBB2));
    background-image: -webkit-linear-gradient(top,#60C2DC 0%,#59BBB2 100%);
    background-image: linear-gradient(to bottom,#60C2DC 0%,#59BBB2 100%);
    .close {
      color: #fff;
      opacity: 1;
      padding: 20px;
    }
    .modal-content {
      text-align:center;
      background: none;
      border: none;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      p {
        margin: 20px 0;
      }
      h3 {
        color: #fff;
        margin-top:50px;
      }
      .form-control {
        padding: 20px 10px;
        text-align: center;
        border: 1px solid #59BBB2;
        font-size: 18px;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
      }
      a {
        color: #fff;
      }
    }
  }
}






#additionalMarkerModal {
  padding: 0;
 
  .close { 
    position: absolute;
    top: 5px;
    left: 15px;
    border: 1px solid #fe0200;
    z-index: 1000;
    font-size: 15px;
    padding: 6px;
    border-radius: 360px;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-top: 20px;
    opacity: 1;


    &:hover {
      cursor:pointer;
    }
    span {
      color: #fe0200;
      text-align: center;
      text-shadow: none;
      font-weight: 900;
    }
  }
  .modal-dialog {
    max-width: 100%;
    margin: 0;
    width: 100%;
    .modal-content {
      background: #1D1D1D;
      color: #fff;
      min-height: 100vh;
      .heading4 {
        color: #fff;
        font-weight: 100;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }
  }
  #addedMarkerTotal {
    background:#FF3B00;
    padding:15px;
    .parentPrice {
      font-size:30px;
      font-weight: 600;
      span {
        font-size: 20px;
      }
    }
    .btn {
      width: 100%;
      background: white;
      color: #ff3a00 !important;
      font-weight: 500;
      font-size: 15px;
      padding: 12px;
      text-transform: uppercase;
      margin-top: 5px;
      &:hover {
        color: white!important;
      }
    }
  }
}


.test_list {
  padding: 10px;

    .chartDrop {
      width: 55px;
      height: 55px;
      display: inline-block;
      position: relative;
      .chartValue {
        position: absolute;
        width: 100%;
        margin-top: 11px;
        font-weight:500;
      }
      svg {
        fill: red;
      }
    }

    h3 {
      font-size: 23px;
      color: #fff;
      margin-bottom: 15px;
      text-align:center;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align:left;
      padding: 0 ;
      li { 
        position: relative;
        vertical-align: top;
        padding: 5px 0;
        padding-left: 0px;
        padding-left: 30px;
        margin:0 15px 0px 0px;
        color: var(--font-color); 
        &.tubeColourId0  {
          background:url('../img/icon22-green.png') left no-repeat;
          background-size: 20px;
          background-position: top 10px left;
        }
        &.tubeColourId1  {
          background: url(../img/icon22-green.png) left no-repeat;
          background-size: auto;
          background-size: 15px;
        }
        &.tubeColourId2  {
          background:url('../img/icon22-green.png') left no-repeat;
          background-size: 20px;
          background-position: top 10px left;
        }
      }
    }
  }


.additionalTestsAccordion {
  /*
  .card-header:after {
    font-family: "Font Awesome 6 Pro";   
    font-weight: 900;
    content: "\f077";
    float: right; 
  }
  .card-header.collapsed:after {
    content: "\f078";
  } */

  .card-header {
    background: #000000;
    border: none;
    padding: 10px;
    color: #FF3B00 !important;
    font-weight: 600;
    a {
      color: #FF3B00 !important;
    }
  }

  .card-body {
    padding: 10px;
    background: black;
    .package_list .package_content .test_list ul li {
      padding-left: 20px;
      font-size: 10px;
    } 
  }

  .card {
    border: none;
  }

}

.groupPlusTest {
  background-color: #F5ECE7!important;
  .card {
    background-color:transparent!important;
  }
  .additionalTestsAccordion .card-header {
    background: #E9E1DC!important;;
  }
  .groupPlusTestMesage {
    text-align: center;
    text-transform: uppercase;
    background: #DF5E5E;
    color: white;
    padding: 5px;
    border-radius: 15px 15px 0px 0px;
    font-weight: 600;
  }
} 

#scrollToBottom {
  color: white;
  border-radius: 360px;
  background: #f9a23f;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -18px;
  text-align: center;
  line-height: 30px;
  left: 15px;
  cursor: pointer;
}
 
@media only screen and (max-width: 992px) {  
  .test_list {
    ul {
      padding: 0 3em;
    }
  }
}

@media only screen and (max-width: 576px) {  
  .test_list {
    .chartDrop {
      .chartValue {
        margin-top:0;
      }
    }  
  }

  #checkoutArea {
    .bespokeTestOverlay {
      .heading4 {
        font-size: 22px!important;
      }
    }
  } 

  .package_header .heading5 {
    margin-bottom:30px;
  } 

  .package_header .bespokeBox img {
     max-width: 20em;
  }
  .package_header .bespokeBox {
    margin-top: -6em;
  }
  .package_header .diagonalLine {
    margin-top: 5em;
  }
  .test_list ul li.tubeColourId0 {
    background: url(../img/icon22-green.png) left no-repeat;
    background-size: 15px;
  } 
  #additionalMarkerModal {
    .modal-body {
      padding: 0;
      padding-bottom: 70px;
    }
  }

  #includedMarkers {
    margin: 20px 1em!important;
  }

  .package_list .package_content .plusTestPrice {
    font-weight: 600;
    font-size: 15px;
  }

  .package_list .package_content .package_title2 h2 {
    font-size: 15px;
  }

  .package_list .package_content p {
    font-size: 12px;
  }

  .package_list .package_price.remove h2 {
    background-size: 16px;
  }

  .package_list .package_price .package_content {
    border-radius: 15px !important;
  }
  
  #addedMarkerTotal {
    margin-top: 0px;
    bottom: 0;
    position: fixed;
    width: 100%;
    left: 0;

  }

  #checkoutArea .selected_test img, #bespoke_test_container .selected_test img {
    padding: 10px;
  }
  #checkoutArea .selected_test .test_price .price_icon, #bespoke_test_container .selected_test .test_price .price_icon {
    font-size: 14px;
  }

  
 
}

@media only screen and (max-width: 550px) {  
  .test_list {
    ul {
      padding: 0 1em;
    }
  }
}

@media only screen and (max-width: 400px) {  
  .test_list {
    ul {
      padding: 0 0em;
      li {
          position: relative;
          vertical-align: top;
          padding: 5px 0;
          padding-left: 0px;
          padding-left: 25px;
          margin: 0 15px 0px 0px;
          color: #000;
          font-size: 12px;
      }
    }
  }
  
}

.modal-body {
  .test_list {
    ul {
      list-style: initial;
      padding-left:30px!important;
      li {
        background: none;
        padding-left: 0!important;
      }
    }
  }
}

.myOrders{
  color: #000000 !important
}


.life {
  .additionalTestsAccordion .card-header {
    background: #f3f3f0;
    color: #62c3bb;
    &:hover {
      background:#e8e8e8;
    }
    a {
      color: #62c3bb;
    }
  }
  .additionalTestsAccordion .card-body { 
    background: #f3f3f0;
  }
 
  .package_header {
    .bespokeTestLogo {
      max-height: 60px;
    }
  }

  .package_list #includedMarkers .markerList .marker {
    background-color: #f0f2f3;
  }

  #additionalMarkerModal .close {
    border: 1px solid #63c3bb; 
    span {
      color:#63c3bb;
    }
  }

  #additionalMarkerModal #addedMarkerTotal { 
    background: #63c3bb;
    color:#fff;
  }

  #additionalMarkerModal #addedMarkerTotal .btn {
    color:#63c3bb!important;
  }

  #cartArea .btn {
    color:#63c3bb!important;
    &:hover {
      color: #fff!important;
    }
  }

  #additionalMarkerModal {

    .modal-dialog .modal-content {
      background: #f3f3f0;
      border-radius:0;
      color: #666;
    }
    .modal-dialog .modal-content .heading4 {
      color: #666;
    }
  }

  .package_header .bespokeTestDetails #includedMarkers .markerList .marker .removeTest {
    color:#63c3bb!important;
  }
  

  .package_header .bespokeTestDetails #includedMarkers {
    background:#fff;
  }

  .package_header .bespokeTestDetails #includedMarkers .markerList .marker {
    background-color: #f0f2f3;
  }

  .package_list {

    .package_list #includedMarkers .markerList .marker {
      background-color: #f0f2f3;
    }

    #includedMarkers {
      background:#fff;
      .markerList .marker { 
        .removeTest {
          color:#63c3bb!important;
        }
      }
  
    }
 
    .package_price {
      background: #fff;
      color: #666;
      border-radius: 15px!important;
      .package_content {
      }
    }

    .package_content p, .package_content .package_title2 h2, .package_content .package_title2 h2, .test_list ul li {
      color: #666!important;
    }

    .package_details a.remove, .package_details a.add {
      color: #63c3bb!important;
    }

  }  

  #checkoutArea {

 
    
    .closeChat {
      border-color:#63c3bb;
      svg{
        color:#63c3bb;
      }
    }
    
    .bespokeTestOverlay {

      .bespokeTestDetails {
        color: #666;

        .diagonalLine {
          background-image: linear-gradient(to bottom right, #ffffff, #ffffff 49%, #f3f3f0 50%, #f3f3f0);
        }
        .diagonalBackground {
          background: #f3f3f0;
        }

        .phlebotomyOption {
          border: 1px solid #fff;
          background: #fff; 
        }

        .btn.btn-primary.addMore {
          border: 1px solid #63c3bb;
          color: #63c3bb !important;
          &:hover {
            background: #63c3bb !important;
            color: #fff!important;
          }
        }

        .test_list {
           
          h3 {
            color: #666;
          }
          
          .chartDrop {
            color: #fff;
            svg {
              fill: #63c3bb;
            }
          }
          ul li {
            color: #666;
          }
        }

      }

    }
  }
}

.partner-test-name{

  .blocking-span{
    display: block;
  }

  .blocking-span input,
  .blocking-span textarea{
    border: 1px solid #B4CCD0;
    border-radius: 5px;
    height: 60px;
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 20px;
    width: 100%;
    font-size: 16px;
    &.form-error{
      border: 1px solid red;
    }
  }
  .blocking-span textarea{
    height: 250px;
    resize: none;
    margin-bottom: 1rem;
  }
  .floating-label{
    display: inline-block;
    color: #70898D;
    font-size: 12px;
    left: 25px;
    line-height: 0px;
    position: absolute;
    top: 18px;
    transition: top 0.3s ease-in-out 0s;
  }
  input{
    margin-bottom: 2rem;
  }

  .dropdown{
    button{
      background: none;
      color: #70898D;
      font-size: 12px;
      font-weight: 300;
      height: 60px;
      border: 1px solid #B4CCD0;
      border-radius: 5px !important;
      width: 100%;
      text-align: left;
      text-transform: none;
      padding-bottom: 50px;
      &.form-error{
        border: 1px solid red;
      }
    }

    span {
      position: absolute;
      top: 33px;
      left: 17px;
      font-size: 16px;
      font-weight: 100;
    }
    .btn:hover{
      background: #B4CCD0 !important;
    }
    .dropdown-item{
      background-color: #fff;
      border-bottom: 1px solid #B4CCD0;
    }
    margin-bottom: 2rem;
  }

  .custom-control{
    margin-top: -1em;
    margin-bottom: 2rem;
  }

  .custom-control-label::before{
    top: -0.2em;
    width: 1.2rem;
    left: -2rem;
    height: 1.2rem;
    border: 2px solid #70898D;

  }

  .custom-control-input:checked ~ .custom-control-label::before{
    font-family: "Font Awesome 6 Pro"; 
    border: 2px solid #70898D;
    background-color: #fff;
    color: #70898D;
    padding-left: 1px;
    padding-top: 1px;
    font-weight: 400;
    content: 	"\f00c";
  }
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    background: none;
  }


  &.options{
    .custom-control-input:checked ~ .custom-control-label::before{
      font-family: "Font Awesome 6 Pro"; 
      border: 2px solid #70898D;
      background-color: #70898D;
      color: #fff;
      padding-left: 1px;
      padding-top: 1px;
      font-weight: 400;
      content: 	"\f00c";
    }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
      background: none;
    }
    h1{
      letter-spacing: 2px;
      font-weight: 100;
    }

  }
}

#bespoke_test_container {
  #phlebotomyOptions {
    .w-75 {
      width:100%!important;
    }
  }
}

#healthCategoriesAccordion {
  .accordion-item {
    border-left: 0;
    border-right: 0;
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23557479'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    }
    .accordion-button::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23557479'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
    .accordion-button:not(.collapsed) {
      box-shadow: none;
    }
    border-radius: 0;
    h2 {
      button {
        background: none;
        color: #557479;
        text-transform: uppercase;
        font-size: 14px;
        span {
          font-weight: 300;
        }
      }
    }
  }
}

  .cancelBespokeTestNaming {
    letter-spacing: 0.1em;
    background:#FAA24044;
    border:0;
    color:#FAA240!important;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    padding: 10px;
    &:hover {
      background: #f78501!important;
    }
  }
.sticky-top-margin{
  top: 100px
}
.package_content {
  border-radius: 15px!important;
}

@media only screen and (max-width: 576px) { 
  .stickyOnDesktop {
    position:relative!important;
    z-index: unset!important;
  }
  #bespokeCheckoutArea .forthModal {
    background-position: 0px -40px;
  }
}


 