#loginForm {
  background: #F5ECE7;
  padding: 15px 10px;
  border-radius: 10px;
}

body:global(.edge) { 
  #loginForm {
    background: #000;
    color: white;
  }
}