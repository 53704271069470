#bespokeTestFooter {
  background: #000000;
  color:  #fff;
  h5 {
    font-size: 16px;
  }
  .certifiedIcon {
    width: 25px;
  }
  .smallText {
    font-size: 10px;

    img {
      width: 50%;
    }
  }

  .listItem {
    background: none;
    a {
      color: white;
    }
  }

  .paymentSection{
    img{
      max-width: 35px;
    }
  }

  .certificateSection{
    .mdCertificate {
      width: 100%;
      max-width: 50px;
    }
    .ceCertificate {
      width: 100%;
      max-width: 31px;
    }
  }

  .certificateISOSection{
    img{
      max-width: 78px;
    }
  }

  .forth-logo{
    max-width: 125px;
  }

  .placeholderLogo{
    max-width: 125px;
    height: 30px;
  }
  
  *[class*="placeholder"]:not([class*="placeholder-"]) {
    opacity: 0.5;
    background-color: #3d3f43;
    color: #3d3f43;
    border-radius: 3px;
  }
}
