#health-overview {
	padding: 50px 0;
	.overviewDate {
		font-size: 12px !important;
	}
	.quoteBlock {
		padding: 0 25%;
		font-size:16px;
		line-height:20px;
	}
	.consultant-profile {
		width: 200px;
		margin: 0 auto;
		padding-top: 15px;
		.profilePicture {
			position: relative;
			border: 3px solid transparent;
			height: 90px;
			width: 90px;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 100px;
			background-size: cover !important;
			background-clip: padding-box;
    	box-sizing: border-box;

			&:before{
				content: '';
				position: absolute;
				top: 0; right: 0; bottom: 0; left: 0;
				margin: -3px;
				border-radius: inherit;
				background: linear-gradient(45deg, var(--profile-gradient-color-1), var(--profile-gradient-color-2));
				z-index: -1;
			}
		}

		.consultantName {
			font-size: 16px;
			margin-bottom: 5px;
		}

		&.modalArea {
			padding-top:0px;
			isolation: isolate;
		}
	}

	.reviewComment {

		background:#ffffff;
		color:#666;
		border:1px solid #F0F2F3;
		margin:10px 0;
		border-radius:5px;

		p {
			color:#666;
			font-size:12px;
			line-height:17px;

		}

	}
}
@media only screen and (max-width: 576px) {
	#health-overview .quoteBlock {
		padding: 0;
	}
}
.health-type {
	h3 {
		font-size: 18px;
	}
	svg{
		font-size: 45px;
		color: #cfd5e1;
		margin-bottom: 10px;
		display: block;
		margin-top: 10px;
	}
}
.tab-content {
	.alert {
		font-size: 9.5px;
		margin-bottom: 10px;
		svg{
			margin-right: 10px;
		}
	}
	.biomarker-count {
		font-size: 11px;
		color: #767676;
		span {
			font-size: 14px;
			color: #3e3e3e;
		}
	}
	h2 {
		margin-top: 20px;
	}
}



@media only screen and (max-width: 991px) {
  .tab-content {
	.chart {
	  margin-top: 70px;
	  padding: 0 10px;
	  padding-bottom:40px;
	}
  }
}