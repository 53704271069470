#my-account-form-container {
  .FileUpload{
    isolation: isolate;
  }

  .profilePicture {
    position: relative;
    height: 200px;
    width: 200px;
    border-radius: 150px;
    background-size: cover !important;
    margin: 30px auto;
    border: 3px solid transparent;
    background-clip: padding-box;
    box-sizing: border-box;

    img{
      max-width: 200px;
      border-radius: inherit;
    }

    &:before{
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      margin: -3px;
      border-radius: inherit;
      background: linear-gradient(45deg, var(--profile-gradient-color-1), var(--profile-gradient-color-2));
      z-index: -1;
    }
  }

  strong {
    margin-bottom: 20px;
    display: block;
  }
}

#accountForm{
  .additionalInformation{
    font-size: 10px;
    line-height: 13px;
  }

  .radio-group {
    border: 1px solid var(--primary-color);
    display: inline-block !important;
    border-radius: 5px !important;
    overflow: hidden !important;

    &.small-radio {
      font-size:10px !important;
      margin-top:-7px !important;

      label {
        padding: 2px 5px !important;
      }
    }

    input[type=radio] {
      position: absolute !important;
      visibility: hidden !important;
      display: none !important;
    }

    label {
      color: var(--font-color) !important;
      display: inline-block !important;
      cursor: pointer !important;
      padding: 5px 20px !important;
      margin:0 !important;
    }

    label.active{
      color: #ffffff !important;
      background: var(--primary-color) !important;
    }
  }

  .form-group label {
    color: var(--title-color);
  }

  .contraceptionOptions{
    column-count: 2;
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  textarea,
  select{
    background-color: var(--bg-color-lighter);
    color: var(--font-color);
    font-weight: 300;

    &::placeholder{
      color: var(--accent-color-lighter)
    }
  }
  
  @media (max-width: 576px){
    .contraceptionOptions{
      column-count: 1;
      width: 100%;
      textarea{width: 90% !important;}
    }
  }

}



#account-management {
  width: 99.8vw;
  background: var(--bg-color);
  padding: 0px;
  position: relative;
  left: 50%;  
  margin-left: -50vw;
  margin-top: -1rem !important;
  // margin-bottom: -1.5rem !important;
  min-height:600px;
  .nav-tabs {
    border: 0;
    width: 100vw;
    background-color: var(--accent-color);
    padding: 10px 0 10px 0;
    position: relative;
    left: 50%;  
    margin-left: -50vw;
    margin-bottom:50px;



    .nav-link, .nav-item.show .nav-link {
      background-color: transparent;
      border: 0;
      border-radius: 10px;
      color: #e7e7e7;
      text-transform: capitalize;
      margin: 0 10px;
      font-weight:300;
      font-size:14px;

      &:hover {
        color: #ffffff;
        background-color: var(--accent-color-lighter);
      }
    }

    .nav-link.active {
      border: 0;
      background-color: var(--accent-color-lighter);
      color: #ffffff;
      font-weight:600;
      margin: 0 10px;
    }
  }

  h2 {
    color: var(--title-color);
    font-weight: 400;
  }

  h3 {
    color:var(--title-color);
    font-weight:500;
  }

  .manage-subscriptions{
    h3 {
      color: var(--title-color);
      font-weight:500;
    }
  }

  .form-control {
    background-color: var(--bg-color-lighter) !important;
    color: var(--font-color);
    font-weight:300;
    font-size:16px;
  }

  .input-group-text {
    background:var(--primary-color);
    border-color:var(--primary-color);
    font-weight:100;
  }


  .radio-group {
    border: 1px solid var(--primary-color-hover);
    display: inline-block;
    border-radius: 5px;
    overflow: hidden;

    &.small-radio {
      font-size:10px;
      margin-top:-7px;

      input[type=radio] + label {
        padding: 2px 5px;
      }
    }

    input[type=radio] {
      position: absolute;
      visibility: hidden;
      display: none;
    }

    input[type=radio] + label {
      color: var(--font-color);
      display: inline-block;
      cursor: pointer;
      padding: 5px 20px;
      margin:0;
    }

    input[type=radio]:checked + label{
      color: #ffffff;
      background: var(--primary-color);
    }

    label + input[type=radio] + label {
      border-left: 0px solid var(--primary-color);
    }
  }



  .saveBtn {
    width:80%;
    max-width:300px;
    margin-top:30px;
  }

  .alert-dismissible {
    .close {
      padding:0px 10px;
      &:after {
          position: relative;
          content: '\F057';
          font-family: "Font Awesome 6 Pro";
          right: 0px;
          color: #fff;
          font-size: 15px;
      }
    }
  }

  .categorySettingsButton {
    display:block;
    width:100%;
    height:120px;
    margin-top:20px;
    border-radius:10px;
    text-transform: uppercase;

    &.active,
    &.active:hover {
      background:var(--primary-color);
      color:#ffffff;
      .fas {
        color:#ffffff;
      }
    }

    &:hover {
      background:#cccccc;
      text-decoration:none !important;
      color:#ffffff;
    }

    .fas {
      font-size:50px;
      padding:20px;
    }

  }

  .orderList {

    
    .kitPhoto {
      height:150px;
      background-color: var(--order-box-bg-color);
      position:relative;
      border-radius: 10px 10px 0 0;

      &.websiteId1 {
        background-image:url('../img/BOX_1_Finger_Prick.png') center no-repeat;
        background-size:contain;
      }

      &.websiteId2 {
        background-image:url('../img/kit-edge.png') center no-repeat;
        background-size:contain;
      }

      img{
        max-width: 250px;
      }

      .kitStatus {
        position: absolute;
        background: var(--order-badge-color);
        font-size: 12px;
        color: #ffffff;
        top: 5px;
        right: 5px;
        padding: 2px 7px;
        border-radius: 10px;
      }
    }

    .orderInformation {
      font-size:15px;

      .date {
        font-size:12px;
        font-style:italic;
      }
    }

    .orderOptions {

      position:relative;

      .btn {
        display: inline-block;
        font-size: 18px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin: 0 5px;
        text-align: center;
        border-radius: 10px;
        -webkit-transition: width 2s ease-in-out;
        transition: width 2s ease-in-out;
        padding:0;

        span {
          font-size: 12px;
          display: none;
          margin-left: 5px;
        }

        .f-phleb {
          background:url('../img/phlebotomyUpgradeIcon.png') center no-repeat;
          background-size: contain;
          width:28px;
          height:28px;
          margin:5px 0 0 0;
        }
      }
    }

    .row {
      padding:15px;

      &:nth-child(even) {
       background:#ececec;
      }

      .orderInformation {
        line-height:20px;
      }

      .btn {
        display:inline-block;
      }
    }
  }

  .table-striped {
    .thead-dark {
      th {
        color: #fff;
        background-color: #3e3e3e;
        border-color: #3e3e3e;
      }

    }
  }
    

  .table-striped {
    tbody {
      tr {
        &:nth-of-type(odd) {
          background:#ffffff;
        }
      }
    }
  }
}

.kitPhoto {
  height:150px;
  background-color: #f5f5f5;
  padding: 1em;
  position:relative;

  &.websiteId1 {
    background:url('../img/BOX_1_Finger_Prick.png') center no-repeat;
    background-size:contain;
  }

  &.websiteId2 {
    background:url('../img/kit-edge.png') center no-repeat;
    background-size:contain;
  }
}

.manage-subscriptions{
  .box-content{
    font-size: 11px;

    h4{
      font-size: 13px;
      color: var(--title-color);
      font-weight: 400;
    }
  }

  .badge-danger{
    color: #dc3545;
    background-color: #fff;
    border: 1px solid #dc3545;
  }

  // .page-header{
  //   letter-spacing: 3px;
  //   text-transform: uppercase;
  //   font-weight: 100 !important;
  //   font-size: 20px;
  //   margin-bottom: 3rem;
  // }
}

#myOrdersFinder {
  .modal-body {
    background: white;
    color: #3E3C3A;
  }

  .modal-header {
    padding: 1rem 1rem; 
    h5 {
      color: #3E3C3A;
    }

    .close {
      top: 15px;
    }
  }

  p {
    color: #3E3C3A;
  }
  
  .spinner-border {
   width: 2rem!important;
  }

}

#myProfileCompleted {
  padding: 10px;
  height: 100%;
  display: flex;
  align-items: center;

  canvas{
    margin: 0 auto !important;
  }

  .profileChartText{
    padding: 0 17px !important;
    p{
      text-align: center !important;
      margin: 0;
    }
  }

  .profileChart{
    font-size: 20px;
    top: 42%;
    left: 41%;
  }

  .profileButton{
    margin-top: -24px;
  }
  
  @media (max-width: 768px) {

    canvas{
      height: 103px !important;
      width: 103px !important;
    }

    .profileChart{
      font-size: 14px;
      left: 41%;
    }

    .profileChartText{
      padding: 0 12px !important;
      font-size: 14px;
    }

    .profileButton{
      margin-top: 16px;
    }
  }
}
