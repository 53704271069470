.biomarkersList {
  container-type: inline-size;
  container-name: biomarkerList;
  columns: 1;

  li{
    list-style: none;
    margin-bottom: 5px;

    &::before{ 
      content: '\f043';
      color: var(--primary);
      font-family: "Font Awesome 6 Pro";
      padding-inline-end: 10px;
    }
  }
}

@media screen and (width > 500px) {
  .biomarkersList {
    columns: 2;
  }
}