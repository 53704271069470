.sidebarModal { 
  background:#0000004a;
  .modalDialog {
    position: fixed;
    margin: auto;
    width: 30vw;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
    --bs-modal-zindex: 9999 !important;
    right:0;
    color: #000;
    animation: slideIn 0.2s forwards;
  }
  
  .modalContent {
  	border: 0;
    border-radius: 0; 
    height: 100%;
    overflow-y: auto;
  }
  .modalBack {
  	background-color: transparent;
    border: none;
  }
}

@media screen and (max-width: 992px){
  .sidebarModal { 
    .modalDialog {
      width: 50vw;
    }
  }
}

@media screen and (max-width: 768px) {
  .sidebarModal { 
    .modalDialog {
      width: 100vw;
      --bs-modal-width: 100vw;
    }
  }
}



body:global(.edge) {

  .sidebarModal { 
    .modalContent {
      background: #fff;
    } 
  }
 
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}