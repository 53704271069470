.minus, .plus {
  width: 40px;
  background: #fff;
  border-radius: 10px;
  padding: 3px 5px 3px 5px;
  border: 1px solid #E1E2E6;
  display: inline-block;
  vertical-align: middle;
  text-align: center; 
  font-size: 13px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
}
.minus {
  border-radius: 10px 0px 0px 10px;
  border-right: 0px;
}
.plus {
  border-radius: 0px 10px 10px 0px;
  border-left: 0px;
}

.cancelRemove {
  background: #fff !important; 
  color: var(--primary-color-hover) !important;
  &:hover { 
    color: #fff !important; 
    background: var(--primary-color-hover) !important;
  }
}

.removeFromCart {
  color: #fff !important; 
  background: var(--primary-color-hover) !important;
  &:hover { 
    background: #fff !important; 
    color: var(--primary-color-hover) !important;
  }
}

.modalContent{
  border:  1px solid black;
}

body:global(.edge) { 
  .modalContent{
    color:  white;
  }

}