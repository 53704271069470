#subscriptionUpseller {
  background:#F5ECE7;
  color: #000;
  padding: 15px;
  border-radius:10px;
  h2{
    font-size: 19px;
  }

  h2, h5 {
  	font-weight:500;
    img {
      width: 50px;
    }
  }

  h5 {
  	font-size: 15px;
  }
   
}

.modifyTest{
  line-height: 15px;
}

body:global(.edge) { 
  #subscriptionUpseller {
    background:#000;
    color: #fff;
  }

}
 