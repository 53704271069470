.title {
  font-weight: 600;
}

.deliveryItem {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  cursor: pointer;
  border: 1px solid #E1E2E6;
  margin-bottom: 15px;

  .optionButton{
    width: 25px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid #ADADAD !important;
  }

  &.selected{
    border-color: var(--primary-color);
    .optionButton{
      position: relative;
      width: 25px;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 2px solid var(--primary-color) !important;
      background: var(--primary-color) !important;
      color: #fff!important;
      font-size: 14px;
    
      &::after{
        content: '\f00c';
        position: absolute;
        width: 14px;
        font-family: "Font Awesome 6 Pro";
        right: 50%;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        color: #fff;
        font-size: 14px;
      }
    }
  }

  &:not(.selected):hover {
    .optionButton{
      position: relative;
      width: 25px;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 2px solid #ADADAD!important;
      background: #ADADAD!important;
      color: #fff!important;
      font-size: 14px;
    
      &::after{
        content: '\f00c';
        position: absolute;
        width: 14px;
        font-family: "Font Awesome 6 Pro";
        right: 50%;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        color: #fff;
        font-size: 14px;
      }
    }
  }

  &.disabled {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.change {
  color: #000;
  text-decoration:underline;
  font-weight:400;
  &:hover{
    color: #000;
  }
}

@media (max-width: 768px) {
  #selectedDeliveryOption{
    border-bottom: 2px solid #e2e2e2;
  }
}