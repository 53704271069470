#hormoneReport {
  max-width:900px;
  margin:0 auto;

  p{
    font-weight: 300;
  }

 .hormoneLineChart {
    height:250px;
    padding:0;
  }


  @media(min-width: 768px) {
    .hormoneLineChart {
      height:350px;
      padding:0;
    }
  }
}

#nickyHeader {
  h2 {
  	letter-spacing: 2px;
  }
}

.reportWhite {
  // background: white;
  // -webkit-box-shadow: 0px 0px 9px -1px rgba(0,0,0,0.2); 
  // box-shadow: 0px 0px 9px -1px rgba(0,0,0,0.2);
  font-size: 14px;
}

.whiteBg {
  background-color: #ffffff;
}

#formReport {
  #userDetails {
    background: #3C5255;
    color: white;
    border-radius: 60px;
    font-size: 14px;
    strong {
      font-size: 16px;
    }
  }
}
 
.largeTablet {
  background: var(--female-report-box-bg-color);
  border-radius: 60px;
  .title {
    display: inline-block;
    width: 60%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 15px;
    font-weight: 700;
    font-size: 14px;
  }
  .score {
    border: 4px solid white;
    background: #756ECD;
    color: white;
    display: inline-block;
    width: 40%;
    text-align: center;
    border-radius: 60px;
    padding: 15px 0px;
    line-height: 15px;
    font-size: 17px;
    font-weight: 700;
  }

}


.smallTablet {
  background: var(--female-report-box-bg-color);
  border-radius: 60px;
  .title {
    display: inline-block;
    width: 60%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 15px;
    font-weight: 700;
    font-size: 12px;
  }
  .score {
    border: 4px solid white;
    background: #756ECD;
    color: white;
    display: inline-block;
    width: 40%;
    text-align: center;
    border-radius: 60px;
    padding: 15px 0px;
    line-height: 15px;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }

}

#controlHormoneTabs,
#ovarianHormoneTabs {
  .contentBorder{
    background-color: white ;
  }

  a {
    text-transform: uppercase;
  }
}

.edge #ovarianHormoneTabs,
.edge #controlHormoneTabs {
  .contentBorder{
    color: black;
    background-color: white ;
  }
}

.edge .smallTablet {
  color: white;
}

#linkingYourHormones, #ProgesteroneLevels {
  .progressTitle {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight:700;
  }
  .progress {
    background: #F0F2F3;
    .progress-bar {
      background: #756ECD;
    }
  }
}

#personalAdvice {
  .sliderBorder{
    padding-bottom: 90px;
    border: 3px solid var(--female-report-border-color);
  }
  .slick-track
  {
    padding: 10px 0px;
    display: flex !important;
  }

  .slick-slide
  {
    height:  300px;
    padding: 0 5px;
    & > div {
      height: 100%;
    }
  }

  .slick-dots {
    bottom: -40px;
    button:before {
      background: #707070;
      border:0;
    }
    .slick-active {
      button:before {
        opacity: .75;
        color: black;
        background: #23B7B7;
        border: 0;
      }
    }
  }
  
  .adviceSlide {
    height: 100%;
    background: var(--nextStep-bg-color);
    // border:1px solid #DFE8EA;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    position: relative;
    overflow: hidden;
    h3 {
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    .dayTab {
      background: #F0F2F3;
      position: absolute;
      text-transform: uppercase;
      font-size: 12px;
      color: #3E3E3E;
      padding: 5px 10px;
      border-radius: 30px;
      font-weight: 700;
      height: auto;
    }

    .score {
      position: absolute;
      top: -35px;
      right: -10px;
      border-radius: 360px; 
      line-height: 60px;
      font-size: 16px;
      width: 88px;
      height: 88px;
      color: white;
      line-height: 120px;
      text-align: center;
      &.low {
        background: #DAB3C1;
      }
      &.high {
        background: #23B7B7;
      }

      strong {
        font-size: 24px;
      }
    }
  }
}


#topTip {
  position:relative;
  .topTab {
    display:inline-block;
    background: #756ECD;
    color: white;
    font-weight: 700;
    border-radius: 30px;
    padding: 5px 10px;
    text-transform: uppercase;
  }
  p {
    margin: 0;
  }
}

#infoArea {
  .icon {
    font-size: 64px;
    color: #23b6b7;
  }
}


#profileTables {
  border-top: 1px solid #cccfcf;
  border-bottom: 1px solid #cccfcf;
  .reportDate {
    color: #557479;
    border-radius: 30px;
  }
  .nav-tabs {
    background: white;
    border: 0;
    border-radius: 30px;
    .nav-link {
      padding: 20px;
      font-size: 14px;
      font-weight: bold;
      color: #23B7B7;
      border-radius: 30px;
      &.active {
        border: 0;
        background: #23B7B7;
        color: white
      }
    }
  }

  .thead-light {
    background: #d7d8d9;
  }
  tbody {
    background: white;
    border-bottom:6px solid #F0F2F3;
  }
  .table th, .table td {
    border: 0;
    .marker {
      letter-spacing: 2px; 
      text-transform: uppercase;
    }
  }
}

#customerFeedback {
  background: #3C5255;
  font-size: 14px;
  color: white;
  padding: 30px;
  .forth {
    fill: url(#linear-gradient);
  }
  svg {
    width: 48px;
  }
  .btn {
    background: #00000040;
    border: 0;
    color: #23B7B7;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 40px;
  }

}
#done-btn {
  .btn {
    color:white!important;
    background: #21B7B7!important;
    &:hover {
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      color:#21B7B7!important;
      background: white!important;
   
    } 
  }
}

.myTests {
  border-radius: 30px;
}

.modal-form-score{
  font-size: 10px;

   @media(min-width: 768px) {
    font-size: 12px;
   }
}


.formScoreChart {
  height: 120px;
  margin: -10px 0;
  padding: 10px;
}

@media(min-width: 768px) {
  .smallCopyAmount {
    max-width:600px;
    margin:0 auto;
  }
}


#consultation {
  
  .nickProfileImg {
    max-width:150px;
  }
}


.hormoneRange {
  padding-top:30px;
  .range {
    height: 15px; 
    display: inline-block;
    position: relative;
    .pin {
      width: 1px;
      position: absolute;
      top: -30px;
      left: 75%;
      text-align: center;
      z-index:10;
      svg { 
        width: 36px;
        transform: rotate(180deg); 
        top: -30px;
        fill: #766bcf; 
        margin-left: -18px;
      }
    } 
    &.range-low {
      width:10%;
      background: #f291df;
      border-radius: 30px 0px 0px 30px;
      border-right: 1px solid white;
    }
    &.range-moderately-low {
      width:20%;
      background: #f8c8ef;
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
    &.range-normal {
      width:40%;
      background: #d8edef;
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
    &.range-moderately-high {
      width:20%;
      background: #a9e2f9;
      border-left: 1px solid white;
      border-right: 1px solid white;
    }
    &.range-high {
      width:10%;
      background: #54c5f4;
      border-radius: 0px 30px 30px 0px;
      border-left: 1px solid white;
    }
  }
}

.hormoneRangeKey { 
  margin-top: 10px;
  .rangeKey { 
    display: inline-block;
    position: relative; 
    text-align: center;
    font-size:12px;
    vertical-align: top;
    &.range-low {
      width: 10%;
      color: #f291df; 
    }
    &.range-moderately-low {
      width:20%;
      color: #f8c8ef; 
    }
    &.range-normal {
      width:40%; 
    }
    &.range-moderately-high {
      width:20%;
      color: #a9e2f9; 
    }
    &.range-high {
      width:10%;
      color: #54c5f4; 
    }
  }
}




@media(max-width: 768px) {
  .hormoneRangeKey { 
    margin-top: 10px;
    .rangeKey { 
      display: inline-block;
      position: relative; 
      text-align: center;
      font-size:10px;
      vertical-align: top;
      &.range-low {
        width: 18%;
        color: #f291df; 
      }
      &.range-moderately-low {
        width:20%;
        color: #f8c8ef; 
      }
      &.range-normal {
        width:24%; 
      }
      &.range-moderately-high {
        width:20%;
        color: #a9e2f9; 
      }
      &.range-high {
        width:18%;
        color: #54c5f4; 
      }
    }
  }
}
#personalSlider{
  .phaseTitle{
    background-color: var(--female-report-border-color);
    width: 75%;
    margin-top: -1.3rem
  }
}
.negative-margin {
  margin-top: -1.3rem
}
@media(min-width: 768px) {
  .contentBorder{
    border: 2px solid var(--female-report-border-color);
  }
}
  .backToTop {
    display: inline-block;
    background-color: #333;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 4px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, 
    opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    color: white ;
    font-size: 30px;
  }
  .backToTop:active {
    background-color: #555;
  }
  .backToTop.show {
    opacity: 1;
    visibility: visible;
  }
  @media(min-width: 768px) {
    .backToTop:hover {
      cursor: pointer;
      background-color: #23b6b7;
    }
  }

  



#nextSteps {
  .nextStepsItem {
    padding: 10px 0px; 
    background: var(--nextStep-bg-color);
    margin-bottom: 5px;
    border-radius: 10px;
    
    svg{
      color: #756ecd;
      font-size: 20px;
    }
    p {
      margin: 0px;
    }
  }

  #consultation {
    background: #fafafa;
  }
}

.ovulation-tablet{
  width: 80%;
  max-width: 22rem;
  margin-right: 20px;
}




#partner-results #about_form_score {
  z-index: 999999999;
  .modal-dialog {
    margin-top:25vh;
  }
}