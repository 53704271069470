.container {
  background:#F5ECE7;
  color: #000;
  padding: 15px;
  border-radius:10px;
  position:relative;
  --arrow-size: 15px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: -29px;
  //   left: 10%;
  //   transform: translateX(-15%);
  //   aspect-ratio: 1;
  //   border-left: var(--arrow-size) solid transparent;
  //   border-right: var(--arrow-size) solid transparent;
  //   border-bottom: var(--arrow-size) solid #F5ECE7;
  // }

  h3{
    font-weight: 500;
  }

  .healthCoachIncluded{
    background-color: rgba(255, 255, 255, 0.65);
    padding: 10px;
    border-radius: 5px;
    font-weight: 400;
  }

  .btn {
    position: relative;
    min-width: 230px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0px;
    border-radius: 5px;
    padding: 15px 10px;
    background-color: #fff;
    font-size: 15px;
    font-weight: 500;

    i[class~="fa-check-circle"] {
      color: var(--primary-color);
    }

    i[class~="fa-circle"]{
      color: #ADADAD;
    }

    &:hover {
      i[class~="fa-circle"]{
        color: #ADADAD;
        position: relative;

        &::before{
          content: "\f058";
          font-family: "Font Awesome 6 Pro";
          font-weight: 500;
          border-radius: 50%;
        }

      }
    }

    .price {
      span {
        font-weight: 300;
      }
    }

    &[data-recommended=true] {
      &::before{
        content: "RECOMMENDED";
        font-size: 11px;
        color: white;
        padding: 5px 15px;
        border-radius: 20px;
        position: absolute;
        top: 0;
        left: 10px;
        background-color: #9D8E87;
        transform: translateY(-70%);
      }
    }

    &:disabled {
      pointer-events: none;
    }
  }
}

.modal {
  h2 {
    font-size: 28px;
    font-weight: 500;
  }
}