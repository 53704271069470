.rating {
  background-color: var(--rating-bg);
  border-radius: 5px;
  p {
    color: var(--title-color) !important;
    font-size: 20px !important;
  }
  textarea{
    font-size: 12px;
    font-weight: 300;

  }
  .alert{
    color: #ffffff !important ;
    font-size: 12px !important;
  }
}

.faceRating{
    border: 1px solid var(--rating-bg);
    background-color: var(--rating-bg);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: -3rem;

    textarea{
      font-size: 12px;
      font-weight: 300;
    }

    .widget-ratings {
      position: relative;
      display: flex !important;
      flex-flow: row nowrap;
      width: 100%;
      justify-content: center;
    }

    .selected svg{
      fill: var(--rating-active-color) !important;
    }

    .widget-container{
      padding: 0 1rem;
      cursor: pointer;
      
      svg {
        width: 50px;
        height: 50px;
        fill-rule: evenodd;
        fill: var(--rating-placeholder-color);
        transition: all 0.1s ease-in;

        &:hover{
          fill: var(--rating-active-color);
        }
      }
    }
    .widget-grad{
      display: none;
    }
}