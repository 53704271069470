.container{ 
  background-color: #fdf3f6;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #d78097;
  color: #D02251;
    

  p, li{
    font-weight: 300;
  }

  input[type="checkbox"]{
    accent-color: var(--primary-color);
  }
}