  
#partnerProductFeature .biomarkersModalList svg{
  height: 34px!important;
  width: 20px!important;
}
#partnerSingleProduct {
  color: #000000;

  .dropdown-menu {
    background: #fff;
    .dropdown-item{
      padding-left: 5px;
      padding-right: 5px;
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--primary);
      &:hover{
        background-color: #e0e0e0;
      }
    }
  }
  .slick-slide img {
    width:100%;
  }
  .slick-slider .slick-slide {
    padding: 5px;
  }
  .slick-dots {
    bottom: -50px;
  }
   

  @media (max-width: 767px){

    .modal {
      padding-right:0px!important;
    }
    .slick-prev, .slick-next{
      top: calc(100% + 50px)!important;
    }

    .modal-open .modal {
      padding:0px!important;
    }

    .modal-dialog {
      padding: 0px;
      margin: 0px;
      width: 100%;
    } 
  }

      top: calc(100% + 50px)!important;
  #mainSlide {
    .slick-slider .slick-slide {
      padding: 0px;
    }
  }
  .cursor-pointer{
    cursor: pointer;
  }

  h2 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 200;
    font-size: 32px;
    margin: 0px;
  }

  .product-info {
    h1 {
      text-transform: uppercase;
      letter-spacing: 4px;
      font-weight: 500;
      font-size: 29px;
      margin: 0px;
    }

    h2 {
      text-transform: uppercase;
      letter-spacing: 4px;
      font-weight: 200;
      font-size: 22px;
      margin: 0px;
    }
    .saveBox {
      display: flex; 
      justify-content: flex-end;
      margin-bottom: 30px;
      .save {
        background: #DF5E5E;
        color: white;
        padding: 5px;
        font-size: 11px;
        text-transform: uppercase
      }

    }
    .priceBox {
      display: flex;
      align-items: baseline;
      flex-direction: row;
      justify-content: flex-end;

      .discountPrice {
        margin-right: 10px;
        color: #484848;
        font-size: 18px;
        text-decoration: line-through;
        text-align: right;
      }
      .totalPrice {
        font-weight: 600;
        font-size: 30px;
        text-align: right;
        .pound {
          display: inline;
          font-size: 30px;
        }
      }
      .perTest {
        color: #484848;
        margin-top: -5px;
        font-size: 12px;
        text-align: right;
      }
    }

    .stockInfo {
      font-weight: 200;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 12px;
      strong {
        font-weight: 500;
      }
    }
    
    .biomarkersIncludedBtn {
      background: none;
      border: 1px solid var(--primary);
      color: var(--primary);
      font-weight: 400;
      letter-spacing: 2px;
      padding: 10px 20px;
      &:hover {
        background: none!important;
        border: 1px solid var(--primary);
        color: var(--primary)!important;
      }
      .marker_counter {
        font-weight: 300;
        color: #47B27A;
        font-size: 10px;
        // background: url(/img/biomarkerCounter.svg) bottom no-repeat;
        background-size: contain;
        padding: 9px 8px 5px 8px;
        letter-spacing: 0.1px;
        margin-right: 10px;
      }

    }

    .test_list ul li {
      color: #000000;
    }

    .includedList {
      padding: 0px;
      text-align: left;
      list-style: none;
      li {
        margin-bottom: 15px;
        display: flex;
      }
      svg{
        color: #47B27A;
        margin-right: 5px;
        
      }
    }

    .buyTest {
      background: var(--secondary);
      color: white;
      font-weight: 400;
      letter-spacing: 2px;
      border: 1px solid var(--secondary);
      text-transform: uppercase;
      font-size: 14px;
      &:hover {
        background: var(--secondary-darker) !important;
        color: #ffffff !important;
        border: 1px solid var(--secondary-darker);
      }
    }

    .bespokeTest {
      background: transparent;
      color: var(--primary);
      letter-spacing: 2px;
      font-weight: 400;
      border: 1px solid var(--primary);
      text-transform: uppercase;
      font-size: 14px;
      &:hover {
        background: var(--primary) !important;
        color: #ffffff !important;
        border: 1px solid var(--primary);
      }
    }
    p {
      margin-bottom: 1em;
    }
    
    @media (max-width: 576px){
      h1{font-size: 27px;}
      .priceBox{
        position: relative;
        .discountPrice{
          position: absolute;
          top: -15px;
          right: -8px;
        }
      }
    }
    
    
  } 
  .howItWorksSection .howItWorksTile {
    img {
      max-width: 105px;
    }
  }

  .dark-bg {
    background: var(--base) !important;
  }

  .light-bg{
    background: var(--base2) !important;
  }

  .howItWorksSection,
  #howItWorks {
    background: #fff;

    h2{
      letter-spacing: 4px;
    }

    .howItWorksTile {
      h3 {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 4px;
      }
      p {
        font-size: 14px;
      }
    }
    .howItWorksList li {
      font-size: 16px;
      margin-bottom: 20px;
    }

    @media (max-width: 576px){
      .mobile-reverse{
        flex-flow: column-reverse !important;
        img{margin-bottom: 20px;}
      }
      ul{ padding-left: 10px;}
    }
  }


  #yourResults {

    .yourResultsTile {
      h3 {
        font-weight: 700;
        letter-spacing: 4px;
        font-size: 18px;
        color: #000;
      }
      .chevrot-icon{
				padding-left: 10px;

				&::after{
					font-size: 12px;
					border: none;
					font-family: "Font Awesome 6 Pro";   
					font-weight: 900;
					content: "\f078";
					transition: all 0.2s;

				}
			}

      .desktopNegativeMargin {
        margin-top:-60px;
      }


      @media (max-width: 992px){
        h3 {
          font-size: 14px;
        }

        .desktopNegativeMargin {
          margin-top:-30px;
        }

      }

  

      @media (max-width: 767px){
        h3 {
          font-size: 14px;
        }

        .desktopNegativeMargin {
          margin-top:0px;
        }

      }
      .collapsed {
        h3 {
          color: var(--primary);
        }
      }

    }
    .btn {
      background: transparent;
      color: var(--primary);
      letter-spacing: 2px;
      font-weight: 400;
      border: 1px solid var(--primary);
      text-transform: uppercase;
      font-size: 12px;
      &:hover {
        background: var(--primary) !important;
        color: #ffffff !important;
        border: 1px solid var(--primary);
      }
    }
  }

  #biomarkersInTest {
    position: relative;
    background: #fff;
    .titleWithIcon {
      img {
        max-width: 100px;
      }
    }
    h2{
      letter-spacing: 4px;
    }
    .selectArea {
      .customSelect {
        margin: auto;
        background-color: var(--primary);
        color: #fff;
        border: 0 none;
        border-radius: 20px;
        padding: 8px 25px 8px 13px;
        border: 1px solid #ddd;
        max-width: 200px;
        width: 100%;
        margin-left: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../img/downArrow.png) var(--primary) no-repeat 98.5% !important; 
        background: url(../img/downArrow.png) var(--primary) no-repeat calc(100% - 10px) !important;
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      @media (max-width: 992px){
        .customSelect { 
          margin: 0;
          margin: auto; 
          display: block;
        }
      }

      @media (max-width: 767px){
        .customSelect { 
          margin: 0;
          margin: auto;
          max-width: 100%;
          display: block;
        }
      }

        
      .dropdown-menu {
        max-height: 280px;
        overflow-y: auto;
      }
      .dropdown-menu a {
        white-space: break-spaces;
      }
    }
    .slick-slide {
      h3 {
        display: inline-flex;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 17px;
        img {
          width: 12px;
          margin-right: 10px;
        }
      }

      @media (max-width: 768px){
        h3 { 
          display:block;
          img {
            display: block;
            margin: auto;
            margin-bottom: 15px;
          }
        } 
      }
    }
    .slick-arrow {
      border: 1px solid var(--primary)!important;
    }
    .slick-prev:before {
      content: "\f053";
    }
    .slick-next:before{
      content: "\f054";
    }
    .slick-prev:before, .slick-next:before {
      font-family: "Font Awesome 6 Pro";
      color: var(--primary)!important;
      font-size: 12px;
    }
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: var(--primary-darker);
      background: var(--primary-darker);
      border-color: var(--primary-darker);
    }

    .slick-dots li button::before {
      border: 2px solid #CCCCCC;
      border-radius: 360px;
      background: #CCCCCC;
      content: "" !important;
      opacity: 1;
      width: 10px !important;
      height: 10px !important;
      left: 5px;
    }

    .paginator{
      position: absolute;
      bottom: -60px;
      font-size: 17px;
      width: 100%;
      left: 0;
      text-align: center;
    }
  }

  #personaliseTest {
    .bespokeTest {
      background: transparent;
      color: var(--primary);
      letter-spacing: 2px;
      font-weight: 400;
      border: 1px solid var(--primary);
      text-transform: uppercase;
      font-size: 12px;
      margin: 0;
      &:hover {
        background: var(--primary) !important;
        color: #ffffff !important;
        border: 1px solid var(--primary);
      }
    }
  }

}

#partnerSingleProduct, .partner{

  h2{
    letter-spacing: 4px;
  }
  
  #puchaseBar  {
    position: fixed;
    bottom: 0;
    background: var(--secondary);
    width: 100%;
    padding: 20px 0px;
    z-index: 998;
    .price {
      color: #000000;
      font-weight: 600;
      font-size: 34px;
      text-align: right;
      .pound {
        display: inline;
        font-size: 30px;
      }
      .perTest {
        font-size: 14px;
        font-weight: normal;
        margin-top: -10px;
        text-align: right;
      }
    }
  
    @media (max-width: 768px){
      .price {  
        font-size: 25px;
        .pound { 
  
          display: inline;
          font-size: 25px;
  
        }
      } 
    }
    .buyTest {
      background: #FFF;
      color: var(--secondary);
      font-weight: 400;
      letter-spacing: 2px;
      border: 1px solid #FFF;
      text-transform: uppercase;
      font-size: 14px;
      &:hover {
        background: var(--secondary-darker) !important;
        color: #ffffff !important;
        border: 1px solid var(--secondary-darker);
      }
    }
  
    .bespokeTest {
      background: transparent;
      color: #FFF;
      letter-spacing: 2px;
      font-weight: 400;
      border: 1px solid #FFF;
      text-transform: uppercase;
      font-size: 14px;
      &:hover {
        background: #FFF !important;
        color: var(--secondary) !important;
        border: 1px solid #FFF;
      }
    }
  
    @media (max-width: 768px){
      .buyTest, .bespokeTest {  
        font-size: 11px;
      } 
    }
  }
  
  #puchaseBar.notFixed {
    position:relative!important;
  }

}


body.dark .partner #partnerSingleProduct { 
  color: white!important;

  .modal-content {
    color: black;
  }

  .dropdown-menu {
   background: #000;
   a {
     color: white;
   }
   .dropdown-item:hover{
     background-color: #212121;
   }
  }

  #partnerProductFeature, #yourResults, #personaliseTest {
    background: #000000;
    color: white!important;
  }



  .product-info {

    color: white;

    .priceBox { 
      .discountPrice { 
        color: #C2C2C2; 
      }
  
      .perTest {
        color: #C2C2C2; 
      }
    }

    .buyTest {
      background: var(--secondary);
      color: #fff;
      font-weight: 400;
      letter-spacing: 2px;
      border: 1px solid var(--secondary);
      text-transform: uppercase;
      font-size: 14px;
      &:hover {
        background: var(--secondary-darker) !important;
        color: #ffffff !important;
        border: 1px solid var(--secondary-darker);
      }
    }
    

    .bespokeTest, .biomarkersIncludedBtn {
      background: transparent;
      color: #C2C2C2;
      letter-spacing: 2px;
      font-weight: 400;
      border: 1px solid #C2C2C2;
      text-transform: uppercase;
      font-size: 14px;
      &:hover {
        background: #C2C2C2 !important;
        color: #000 !important;
        border: 1px solid #C2C2C2;
      }
    }
    
    p {
      margin-bottom: 1em;
    }
  }

  #yourResults .btn,
  #personaliseTest .bespokeTest {
    background: transparent;
    color: #C2C2C2;
    letter-spacing: 2px;
    font-weight: 400;
    border: 1px solid #C2C2C2;
    text-transform: uppercase;
    font-size: 14px;
    &:hover {
      background: #C2C2C2 !important;
      color: #000 !important;
      border: 1px solid #C2C2C2;
    }
  }

  #biomarkersInTest .selectArea .customSelect {
    background-color: #C2C2C2;
    color:#000;
    background: url(../img/downArrowDark.png) #C2C2C2 no-repeat 98.5% !important; 
    background: url(../img/downArrowDark.png) #C2C2C2 no-repeat calc(100% - 10px) !important;
  }
  
  .howItWorksSection,
  #howItWorks,
  #biomarkersInTest {
    background: #212121;
  }

  .dark-bg {
    background: #000 !important;
  }

  .light-bg{
    background: #212121 !important;
  }
  

  #yourResults .yourResultsTile h3 {
    color: #fff;
  }

  #yourResults .yourResultsTile .collapsed h3 {
    color: #C2C2C2;
  }
  
  .yourResultsTile,
  .howItWorksSection,
  #howItWorks,
  .titleWithIcon {
    img {
      filter: brightness(0) invert(1);
    }
    .no-filter {
      filter: unset;
    }

  }
  

  #biomarkersInTest {
    .slick-dots li.slick-active button:before {
      opacity: 1;
      color: #C2C2C2;
      background: #C2C2C2;
      border-color: #C2C2C2;
    }

    .slick-arrow {
      border: 1px solid #C2C2C2!important;
    }
    .slick-prev:before, .slick-next:before { 
      color: #C2C2C2!important; 
    }

    .slick-dots li button::before {
      border: 2px solid #494949;
      border-radius: 360px;
      background: #494949;
      content: "" !important;
      opacity: 1;
      width: 10px !important;
      height: 10px !important;
      left: 5px;
    }
  }

  #yourResults hr {
    border-top: 1px solid #494949;
  }

    

}