.styledCheckbox {
  background:#fff;
  border-radius:10px;
  padding:15px 10px;
  cursor:pointer;
  border: 1px solid #E1E2E6;

  h5 {
    font-size: 14px;
    font-weight: 400;
  } 

  .checkBoxCircle {
    border: 2px solid #ADADAD;
    border-radius: 360px;
    width: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      font-size: 12px;
    }
  }

  &.active {
    border: 1px solid var(--primary-color);
    .checkBoxCircle {
      border: 2px solid var(--primary-color);
      background: var(--primary-color);
      color: #fff;
    }
  }
}

.shieldIcon {
  max-width: 25px !important;
}

.isoIcon {
  width: 100%;
  max-width: 85px !important;
}

.certifiedIcon {
  width: 100%;
  max-width: 100px !important;
}

.trustpilot {
  max-width: 170px !important;
}