.pill {
    background-color: var(--bs-primary);
    color: white;
    border-radius: 15px;
    user-select: none;
    padding: 2px 8px 2px 25px;
    font-weight: 400;
  
    input[type="checkbox"] {
      appearance: none;
    }
  
    &::before {
      font-family: "Font Awesome 6 Pro";
      font-weight: 500;
      content: "\f0c8";
      font-size: 13px;
      color: white;
      position: absolute;
      top: 50%;
      display: block;
      left: 8px;
      transform: translateY(-50%);
    }
  
    &:has(input[type="checkbox"]:checked) {
      color: white;
  
      &::before {
        content: "\f14a";
        font-weight: 900;
      }
    }
  }
  
  :root[data-theme="edge"] {
    .pill {
      background-color: var(--bs-secondary-500);
    }
  }
  