.box {
  --padding-y: 25px;
  --padding-x: 30px;
  --border-size: 8px;

  background-color: #F2EFFF;
  padding: 25px 30px;
  border-radius: 10px;
  border: var(--border-size) solid white;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);

  .badge {
    position: absolute;
    background-color: #987bff;
    padding: 5px 10px;
    border-radius: 15px;
    color: white;
    left: 50%;
    top: calc(var(--padding-y) - var(--padding-y) * 2 - var(--border-size) / 2);
    transform: translate(-50%, 50%);
    
    svg{
      transform: rotate(-10deg);
      color: currentColor
    }

    @media screen and (min-width: 768px) {
      left: 5%;
      transform: translateY(50%);
    }
  }

  .title {
    text-wrap: balance;
  }

  .icon {
    max-width: 100%;
  }

  .codeContainer {
    text-align: start;
    background-color: white;
    padding: 10px 20px;
    position: relative;

    &[data-copied=true]{
      outline: 3px solid var(--green);

      &::before {
        position: absolute;
        content: "Copied!";
        color: var(--green);
        top: 0;
        opacity: 0;
        left: 50%;
        transform: translateX(-50%);
        animation: slideDown .1s linear forwards;
      }
    }

    small{
      color: grey
    }

    .code {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 600;
    }

    svg{
      text-align: center;
      color: currentColor;
      cursor: pointer;
    }
  }
}

@keyframes slideDown {
  to {
    top: 5px;
    opacity: 1;
  }
}