.bs-wizard {margin-top: 20px;}

/*Form Wizard*/
.bs-wizard { padding: 0 0 10px 0;}
.bs-wizard > .bs-wizard-step {padding: 0; position: relative;}
.bs-wizard > .bs-wizard-step + .bs-wizard-step {}
.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {color: #595959; font-size: 14px; margin-bottom: 2px; font-weight: 100;}
.bs-wizard > .bs-wizard-step .bs-wizard-info {color: #999; font-size: 14px;}
.bs-wizard > .bs-wizard-step > .bs-wizard-dot {position: absolute; width: 30px; height: 30px; display: block; background: #bbe2de; top: 45px; left: 50%; margin-top: -15px; margin-left: -15px; border-radius: 50%;} 
.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {content: ' '; width: 14px; height: 14px; background: #59BBB2; border-radius: 50px; position: absolute; top: 8px; left: 8px; } 
.bs-wizard > .bs-wizard-step > .progress {position: relative; border-radius: 0px; height: 8px; box-shadow: none; margin: 23px 0; background:#f5f5f5;}
.bs-wizard > .bs-wizard-step > .progress > .progress-bar {width:0px; box-shadow: none; background: #bbe2de; border-radius:0px;}
.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {width:100%;}
.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {width:50%;}
.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {width:0%;}
.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {width: 100%;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {background-color: #f5f5f5;}
.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {opacity: 0;}
.bs-wizard > .bs-wizard-step:first-child  > .progress {left: 50%; width: 50%;}
.bs-wizard > .bs-wizard-step:last-child  > .progress {width: 50%;}
.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot{ pointer-events: none; }


#checkoutArea {
  
  color: #3E3C3A;
  
  #mobileVoucherAlert,
  #bespokeCheckoutArea,
  .checkoutBar,
  #checkoutBottom { 
    .autoCreditAmount {
      font-size: 12px;
      color: white;
      padding: 5px 10px; 
      background: #3E4884;
      //background-image: linear-gradient(to right, #f97d56, #ff6c66, #ff5d7b, #fc5292, #ef4eab);
      &.autoVoucher{
        background-image: none;
        background-color: #e2e2e2;
        color: #000000;
      }
    }

    .voucherRemovedBecauseOfCredit {
      font-size: 11px;
      color: #595455;
      padding: 10px 10px;
      text-align: center;
      background: #FBE7E8;
      border-radius: 5px;
    }

    .accountInCredit {
      width: 55%;
      text-align: left;
      background: #f6f6f6; 
      font-size: 11px;
      color: #595455;
      padding: 10px 10px; 
      border-radius: 5px;
    }
  }

  .bespokeTestLogo{
    margin-left: 40px;
    max-width: 205px;
    max-height: 60px;
    width: 100%;
    object-fit: contain;

    &.mobile {
      height:35px;
      margin-left: 10px;
    }

    &.partnerMaxHeight {
      max-height: 80px;
    }
  }

  .selected_test .test_type.autoCreditAmount {
    background: #3E4884;
    //background-image: linear-gradient(to right, #f97d56, #ff6c66, #ff5d7b, #fc5292, #ef4eab);
  }

  #manuallyEnterAddress {
    color: #47B27A;
    font-weight:500;
    cursor: pointer;
  }

  #phlebotomyOptions.checkout,
  #guestOptions {
    border-bottom: 1px solid #D2D8E4;
  }

  .trustPilot {
    background: none!important;
    padding: 0!important;
    width: 135px;
    display: block;
    margin: 10px 0px;
  }


  .deleteMarkerModal {
    .removeFromCart {
      background: #47B27A!important;
      border-color: #47B27A!important;
      color: white!important;
    }
    .cancelRemove {
      background: none;
      border: none;
      color: #47B27A;
    }
  }

  #phlebotomyOptions.checkout {
    .phlebotomyOption {
      min-height: 148px;
      a {
        color: #557479;
        font-weight: 500;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      &.disabled {
        filter: grayscale(1);
        opacity: 0.2;
      }

      &:hover {
        cursor:pointer; 
        border: 1px solid #f8ab00;
      }
    }
  }

  .phlebotomyToolTip {
    
    background-color: #DEF4FD;
    color: #fff;
    padding: 20px;
    position: relative; 
    float: left;
    border: 1px solid #5BC5F2;
    color: #408AAA;s

    &.arrow-top {
      margin-top: 40px;
    }

    &.arrow-top:after {
      content: " ";
      position: absolute;
      top: -10px;
      border-top: none;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 10px solid #DEF4FD;
      left: calc(50% - 16px);

    }

    &.arrow-top:before {
      content: " ";
      position: absolute;
      left: calc(50% - 16px);
      top: -11px;
      border-top: none;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
      border-bottom: 10px solid #5bc6f1;
    }
  }

  .selected_test.checkout {
    border-bottom: 1px solid #D2D8E4;
    .card {
      background:none;
    }

    .plusTestList {
      span {
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: 2px;
        font-size: 12px;
      }
    }

    .test_title {
      color:#557479
    }

    img {
      padding: 20px;
      background: #F5F5F5;
    }

    &.notStepOne {
      .test_title {
        color:#3E3C3A;
        font-size: 16px;
      }
      .test_price {
        font-size:20px;
        .price_icon {
          font-size:16px;
        }
      }
    }

    .btn {
      text-transform: uppercase;
      font-size:14px;
      &.customise {
        color: #FAA240;
        border: 1px solid #FAA240;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 14px;
        padding: 8px 25px;
        background:none;
        &:hover {
          background: #FAA240 !important;
        }
      }
    }

    .removeFromCart {
      color: #557479;
      border: 1px solid #557479;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      padding: 8px 25px;
      background:none; 
      &:hover {
        background: #557479!important;
      }
    }

  }
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  background: #FBFBFB; 
  z-index: 6000;
  overflow: scroll;
  overflow-x:hidden;
  height:auto;

  .overlayStyleContainer {
    background: #FBFBFB;
  }

  .not-you {
    font-size:16px;
    font-weight:bold;
  }

  .errorLogOut {
    font-size:18px;
    background: #5ABBB4;
    color: white;
  }
  
  .bespokeTestOverlay {
    float: left;
    margin: 0;
    width: 100%;
    background: #ffffff;
    position: relative;
    height: auto;
    .heading4 {
      font-size: 38px;
      color: #212121;
    } 
    .heading5 {
      color: #212121;
      font-size: 14px;
    }
    .bespokeTestDetails {
      position:relative;
      .diagonalLine {
        height: 25px;
        background-image: linear-gradient(to bottom right, #ffffff, #ffffff 49%, #1D1D1D 50%, #1D1D1D);
      }
      .diagonalBackground {
        background: #1D1D1D;
      }
    }
  }

  .overlayStyleContainer {

   float: left;
    margin: 2%;
    width: 96%;
    background: #ffffff;
    border: 1px solid #e0f3f3;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 24px -5px rgba(0,0,0,0.35);
    -moz-box-shadow: 0px 0px 24px -5px rgba(0,0,0,0.35);
    box-shadow: 0px 0px 24px -5px rgba(0,0,0,0.35);
    position:relative;
    &.partnerActivation {
      margin-top: 7%;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      background: #FBFBFB;
    }
  } 

  .form-control {
    color: #43474F!important;
    background-color: #fff;
    &::-webkit-input-placeholder {
      color: #bbb;
    }
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #3b3a3a;
    vertical-align: middle;
    background: none;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .quantityOptonArea {
      flex: 1;
      padding:5px 0 0px 0;

    .quantityOptonTitle { 
      font-size:10px;
      color: #9398A0; 
      font-weight:100;
    }
    .quantity { 
      margin-top:5px;
      display: inline-block;
      height: calc(2.0rem + 1px);
      vertical-align: middle;
      line-height: 1.5;
      border: 1px solid #ced4da;
      background: none;
      border-radius: 0.25rem;
      width: 100%;
      text-align:right;
      -webkit-appearance: listbox;
      -moz-appearance: listbox;
      appearance: listbox;
      text-align:center;
      color:#47B27A;
      padding-right: 15px;
    }

 
    #billingFrequency {
      background-image: url(../img/selectArrows.png);
      background-position: calc(100% - 0rem), 100% 0;
      background-size:  1.5em 1.5em;
      background-repeat: no-repeat;
      background-color: white;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      text-align: left;
    }
  }


  header {
    &.checkout {
      background: none;
      position: relative;
      padding-bottom: 15px;
      border-top: 1px solid #e7e7e7;

      h1 {
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        padding: 0;
        margin: 0;
      }
    }
    .backCheckout {
      font-size: 30px;
      position: absolute;
      top: 5px;
      left: 15px;
      z-index: 5;
      color: #5f8088;
    }
    .closeCheckout {
      font-size: 30px;
      position: absolute;
      top: 5px;
      right: 15px;
      z-index: 5;
      svg{
        color: #5F8088
      }
    }
  }


  .checkout_address_template {
    .container {
      max-width: 800px;
    }
  }


  .nextBtn {
    background: #5bb9cd;
    color: white;
    border-radius: 100px;
    border-color: inherit;
    font-size: 16px;
    -webkit-transition: .5s;
    transition: .5s;
    width: 50%;
  }

  #pToggle {
    background: #eee;
    color: #555;
  }

  .voucherAdded,
  .voucherCodeApplied {
    float: left;
    width: 100%;
    background: #91c2c1;
    color: #fff;
    margin-bottom: 2px;
    padding: 5px;
    font-size: 16px;
    text-align: center;
    position:relative;
    .removeVoucher {
      position: absolute;
      right: 15px;
      top: 7px;
    }
  }

  .product {
    float: left;
    width: 100%;
    border: 1px solid #e7e7e7;
    margin-bottom: 5px;
    position: relative;
    background: #fff;
    .photo {
      float: left;
      width: 25%;
      padding: 10px 0 10px 20px;
    }
    .information {
      float: left;
      text-align: left;
      width: 75%;
      padding: 10px 20px;
      h4 {
        padding: 0;
        margin: 5px 0 5px 0;
        font-size: 18px;
        font-weight: 400;
        small {
          font-weight: 400;
          line-height: 1;
          color: #777;
        }
        .price {
          color: #50b3b0;
          display: block;
          font-weight: 700;
        }
      }
      .custom-select {
        margin-top:20px;
      }
    }
    .removeFromCart {
      position: absolute;
      top: 5px;
      right: 5px;
    }

    .clinicArea {
      text-align:left;
      float: left;
      width: 100%;
      padding: 10px;
      background: #fbfbfb;
      border: 1px solid #fff;
      .stage1 {
        padding: 10px;
        .alert {
          text-align: left;
          color: #856404;
          background-color: #fff3cd;
          border-color: #ffeeba;
          border-radius: 5px;
          clear: both;
        }
      }
      .phlebotomySelectionBtn {
        float: left;
        width: 100%;
        padding: 2px 0;
        font-size: 15px;
        text-decoration: none;
        margin-bottom: 15px;

        .tickCircle {
          border: 2px solid #50b3b0;
          width: 20px;
          height: 20px;
          margin: 0 5px 0 0;
          border-radius: 50%;
          display: inline-block;
          position: relative;
          vertical-align: sub;
        }
        .tickCircle.checked:before {
          font-family: "Font Awesome 6 Pro";
          left: -2px;
          top: 3px;
          position: absolute;
          content: '\f00c';
          width: 20px;
          height: 20px;
          font-size: 10px;
          text-align: center;
          color: #50b3b0;
        }
      }
    }
  }

  .totalAmount {
    float: left;
    width: 100%;
    background: #50b3b0;
    color: #fff;
    margin-bottom: 20px;
    padding: 5px;
    font-size: 16px;
    text-align: center;
  }

  .voucherArea {
    h4 {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    #voucherCode {
      height: auto;
      font-weight: 300;
      box-shadow: none;
      font-size: 16px;
      display: inline;
      width: 50%;
    }
    #voucherCodeBtn {
      background: var(--primary-color);
      padding: 10px 15px;
      border-radius: 10px;
      color: white;
      text-decoration: none;
      text-transform: capitalize;
      font-size: 13px;
      margin-left: 2%;
      border:0px;
      &:hover {
        background: var(--primary-color-hover);
      }
    }
  }
  .backToShopButton,
  .checkoutButton {
    text-align:center;
    background: #FAA240;
    color: white;
    border-radius: 100px;
    padding: 10px 15px;
    font-size: 16px;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;
    &:hover, :focus {
      background: #c98234;
      color: white;
      cursor: pointer;


    }
  }

  .backToShopButton {
    display:block;
    background: none;
    color:#FAA240;
    text-decoration: none;
    &:hover, :focus {
      background: none;
      color:#c98234;
      text-decoration:none;
    }
  }

  .well-password {
    font-size: 14px;
    line-height: 18px;
    position: relative;
    background: #42bc97;
    border: none;
    margin: 0 0 5px 0;
    padding: 6px;
    text-align: center;
    color: #fff;
    font-weight: 500;
  }

  .well-password:after, .well-password:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .well-password:after {
    border-color: rgba(136,183,213,0);
    border-bottom-color: #42bc97;
    border-width: 10px;
    margin-left: -10px;
  }

  .paymentInfoBlock {
    padding: 20px 20px 20px 20px;
  }

  .cardPayment {
    .btn {
      background: #ec4f32;
      color: white;
      width: 80%;
      font-size: 18px;
      margin-bottom: 20px;
    }

    .error {
      color:red;
    }

    .cardDetails {
      display:none;
    }
  }

  .totalToPay {
    padding: 5px 0;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
  }

  .footerLogo {
    img {
      width: 129px;
      margin-bottom: 15px;
    }
  }

  .appstoreLinks {
    text-align:center;
    img {
      margin: 0 5px;
    }
  }

  .takePayment {
    .col-12 {
      width: 100%!important;
      margin: 0;
      flex: 100%;
      max-width: 100%;
      .cardPayment {
        position: relative;
        margin: 0px;
        border: 0;
        border-radius: 0;
        background: #fff;
        .StripeElement {
          background: white;
          padding: 15px;
          border: 1px solid #d8d8d8;
        }
        .padLock {
          display:none;
        }
        form {
          padding:0;
          h3 {
            display:none;
          }
        }
      }
    }
  }


  #activationKitOption.modal {
    background:rgba(0,0,0,0.9);

    i.fa {
      font-size:60px;
    }
  }

  #quantityCounter {
    span { 
      cursor:pointer; 
      color:#557479; 
    }
    .number {
      display:flex;
      align-items: center;
    }
    .minus, .plus {
      width: 40px;
      background:#fff;
      border-radius:4px;
      padding: 3px 5px 3px 5px;
      border:1px solid #D2D8E4;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 20px;
    }
    .minus svg{
      font-size: 15px;
    }
    .minus {
      border-radius: 4px 0px 0px 4px;
      border-right: 0px;
    } 
    .plus {
      border-radius: 0px 4px 4px 0px;
      border-left: 0px;
    }
    input{
      height:34px;
      flex: 1;
      text-align: center;
      font-size: 15px;
      color: #47B27A;
      border:1px solid #D2D8E4;
      border-radius:0px;
      display: inline-block;
      vertical-align: middle;
      max-width: 75px;
    }
  }

  .testUpseller {
    background:white;
    border: 1px solid #D2D8E4;
    border-radius: 10px;
    padding: 20px;
    h3 {
      text-transform: uppercase;
      color: #557479;
      font-weight: 500;
      font-size: 16px;
    }

    .testItem .modal {
      h5 {
        font-size: 25px;
      }

      .close svg{
 
        color: #47B27A;
        font-size: 30px;
      }
      .close:after {
        content: none;
      }
    }

    .testItem {
      margin-top:10px;
      background: #F6F6F6;
      border: 2px solid #F6F6F6;
      border-radius: 10px;
      padding: 8px;
      &:hover {
        cursor: pointer;
      }
      .modalToggler {
        color: #47B27A;
        font-size:18px;
        &:hover {
          cursor: pointer;
        }
      }
      h5 { 
        text-transform: uppercase;
        color: #557479;
        font-weight: 300;
        font-size: 14px;
        margin:0px;
      }
      .price {
        color: black;
        font-weight: 400;
        font-size: 18px; 
        span {
          font-size: 14px;
        }
      }
      .cross {
        background: white;
        color: #557479;
        border: 1px solid #557479;
        border-radius: 360px;
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        transition: all 0.15s 0.15s;
      }

      &.active {
        background: white;
        border: 2px solid #47B27A;
        .cross {
          border: 1px solid #47B27A;
          color:  #47B27A; 
        }
      }
    }
  }

  #bespokeTestFooter {
    background: #000000;
    color:  #fff;
    h5 {
      font-size: 16px;
    }
    .certifiedIcon {
      width: 25px;
    }
    .smallText {
      font-size: 10px;

      img {
        width: 50%;
      }
    }

    .list-group-item {
      background: none;
      a {
        color: white;
      }
    }

    .paymentSection{
      img{
        max-width: 35px;
      }
    }

    .certificateSection{
      img{
        max-width: 50px;
      }
    }

    .forth-logo{
      max-width: 125px;
    }
  }

}



#partnerClinicFinder {
  .modal-header {
    background: linear-gradient(to left, var(--accent-color) 0%, var(--accent-color) 100%) left top transparent no-repeat;
    background-size: 100% 5px;
    background-color: white;
    h5 {
      font-size: 24px !important;
      text-transform: uppercase;
      letter-spacing: 0.1em;
    }
  }
  .mapContainer {
    position:relative;
    height:465px;
  }

  .partnerClinicSearch {
    h4 {
      font-size: 18px;
      line-height: 25px;
      color: #3E3C3A;
      letter-spacing: initial;
    }
    input {
      border: 1px solid #D2D8E4;
      border-radius: 30px;
      padding: 15px 20px;
      &:focus {
        border: 1px solid #47B27A;
        box-shadow: none;
      }
    }
    .submitClinicSearch {
      padding: 0px;
      margin: 0;
      background: var(--primary-color);
      color: white;
      border: 0;
      border-radius: 360px!important;
      width: 40px;
      height: 40px;
      font-size: 16px;
      &:hover {
        background: var(--primary-color-hover) !important;
      }
    }
    .clinicSearchValue {
      background: #F6F6F6;
      border-color: #F6F6F6;
      color: #9398A0!important;
    }
    .restartClinicSearch {
      color: #47B27A!important;
      border: 0;
      font-size: 24px;
      position: absolute;
      right: 15px;
      top: 0px;
      background: none;
      &:hover {
        background:none!important;
        color: #557479!important;
      }
    }
    .useLocation {
      color:#557479;
      text-transform: uppercase;
      font-weight: 400;
      &:hover {
        background:none!important;
        color: #5474E5!important;
      }
       
    }
  }
}


#partnerClinicSearchResults {
  color: #3E3C3A;
  h3 {
    font-size: 16spx;
    color: #3E3C3A;
    font-weight: 300;
    span {
      color: #47B27A;
    }
  }
  .clinicResults { 
  
    .partnerClinic {
      border-top: 1px solid #EDEFF4;
      h4 {
        text-transform: uppercase;
        font-size: 16px;
        color: #3E3C3A;
        font-weight: 700;
        margin-bottom: 2px;
      }
      .distance {
        color: #9398A0;
      }
      h5 {
        color: #3E3C3A;
        text-transform: uppercase;
        font-size:12px;
        font-weight: 700;
      }
    }
  }
}

#checkoutSubNav {
  font-size:14px;
  li {
    &.complete {
      color: #47B27A;
    }
    &.active {
      color: #557479;
    }
    &.inactive {
      color: #D2D8E4;
    }
    vertical-align: middle;
    svg{
      font-size: 35px;
      vertical-align: middle; 
      padding: 0 30px;
    }
  }
}

#checkoutFormArea {
 
  .form-group input::-webkit-input-placeholder { color: #557479!important; }
  .form-group input::-moz-placeholder { color: #557479!important; }
  .form-group input:-moz-placeholder { color: #557479!important; }
  .form-group input:placeholder { color: #557479!important; }
  .form-control::-webkit-input-placeholder { color: #557479!important; }
  .form-control::-moz-placeholder { color: #557479!important; }
  .form-control:-moz-placeholder { color: #557479!important; }
  .form-control:placeholder { color: #557479!important; }
  .form-control::placeholder {    color: #557479!important; }
  background:#F6F6F6;
  padding:30px;

  #termsBox {
    p {
      padding-bottom: 15px;
    }
    label {
      display: block;
      margin-bottom: 5px; 
      float: left;  
    }

   
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked {
      position: absolute;
      left: -9999px;
    }
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label {
      position: relative;
      padding-left: 3em;
      cursor: pointer;
    }

    /* checkbox aspect */
    [type="checkbox"]:not(:checked) + label:before,
    [type="checkbox"]:checked + label:before {
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 24px; height: 24px;
      border: 1px solid #47B27A;
      background: #fff;
      border-radius: 360px; 
    }

    [type="checkbox"]:checked + label:before {
      background: #47B27A;
    }

    [type="checkbox"]:not(:checked) + label:after,
    [type="checkbox"]:checked + label:after {
      content: "\f00c";
      position: absolute;
      top: 7px;
      left: 5px;
      font-size: 15px;
      line-height: 0.8;
      color: #fff;
      transition: all .2s;
      font-family: "Font Awesome 6 Pro";
      font-weight: 500;
    }
    /* checked mark aspect changes */
    [type="checkbox"]:not(:checked) + label:after {
      opacity: 0;
      transform: scale(0);
    }
    [type="checkbox"]:checked + label:after {
      opacity: 1;
      transform: scale(1);
    }
      
  }
  .haveAccount {
    background: #E5EBEE;
    a {
      color: #47B27A;
      text-transform: uppercase;
      font-weight: 700;
      margin-left: 5px;
    }
  }
  .shareAccessTickBox {
    background: #f9f9f9;
    padding: 20px;
    border: 1px solid #bebebe;
    .tickBoxSection {
      font-weight: bold;
      font-size: 16px;
    }
  }

  .btn {
    color: white;
    background: #47B27A;
    border: none;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1em;
    padding: 10px 30px;
    &:hover {
      background: #3C9767!important;
    }
  }
  .checkoutForm {

    .form-group {
      margin: 5px 0 !important;

      label {
        margin:0; 
        color:#3E3C3A;
        font-weight: 600;
        /* visibility:hidden; */
      }
      input {
        background: none;
        padding: 0px;
        border: 0;
        border-bottom: 1px solid #D2D8E4;
        border-radius: 0;
        font-size: 16px;
        color:#557479;
        &:focus {
          box-shadow:none;
          color: #3E3C3A!important;
          border-bottom: 1px solid #47B27A!important;
        }
        &:focus:required:invalid { 
          border-bottom: 1px solid #DF5E5E!important;
        }
      }
      .form-control {
        background-color:transparent;
        border: none;
        border-bottom: 1px solid #D2D8E4;
        border-radius: 0;
        font-weight: 300;
        &:focus {
          box-shadow:none;
          color: #3E3C3A!important;
          border-bottom: 1px solid #47B27A!important;
        }
      }
      select.form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        text-indent: 1px;
        text-overflow: '';
        color: #557479!important; 
        padding:0;
        option:disabled, option[hidden] {
          color: #557479!important; 
        }
      } 
      

      input::-webkit-input-placeholder, ::-moz-placeholder, :-ms-input-placeholder, ::-webkit-input-placeholder, ::placeholder  {
        color: #557479;
        font-weight:300;
      }

      .StripeElement.StripeElement--focus + label {
        color: #47B27A;
        visibility: visible;
      }
      
      
      &:focus-within {
        label {
          display:block;
          color: #47B27A;
          visibility: visible;
        } 
      }
    }

    &.stripe {
      .form-group{
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        .StripeElement {
          border-bottom: 1px solid #D2D8E4;
          &.StripeElement--focus {
            border-bottom: 1px solid #47B27A!important;
          }
        }
        label {
          order: 1;
        }
        .StripeElement, .form-control {
          order: 2;
        } 
      }
    }
  }

  .checkout-success {
    .lead {
      font-size:20px;
      font-weight:100;
    }
  }

  a {
    color: #47B27A;
    text-decoration: none;
  }

  .checkoutFinished {
    background: none;
    color: #47B27A;
  }
}

#getTheApp {
  background: #F6F6F6;
  margin-top: 60px;
  padding: 30px 80px;
  color: #3E3C3A;
  h2 {
    font-weight: 700;
    font-size: 30px;
    text-transform: uppercase;
  }
}

@media (max-width: 1200px) { 
  #checkoutArea {
   .selected_test.checkout {

    .test_price {
      font-size: 22px;
    }
    
    .marker_counter {
      margin-right: 5px;
    }

    .card {

      .card-header.no-borders h5 {
        font-size:12px;
      }

    }  

   }
  }
  #mobileBasket {
    background: #E5EBEE;
    color: #557479;
    width:100%; 
    padding: 30px 15px;
    margin-top: -1rem !important;
    margin-bottom: 1rem;
    .orderSummary {
      font-size:14px;
      svg{
        margin-right: 10px;
        font-size: 18px;
      }
    }
    .testPrice {
      font-weight: 700;
      font-size: 22px; 
      text-align: right;
      svg{
        font-size: 18px;
        color: #47B27A; 
        line-height: 30px;
        margin-left: 5px;
      }
    }
  }

}

@media (max-width: 992px) {
  .fullWidthMobile {
    width: 75%!important;
  }
  #mobileVoucherAlert,
  #bespokeCheckoutArea,
  .checkoutBar,
  #checkoutBottom { 
    .accountInCredit {
      width: 100%!important; 
    }
  }
}
@media (max-width: 768px) { 
  

  .fullWidthMobile {
    width: 100%!important;
  }
  #checkoutArea {

    .customerMenopausal {
      label {
        color: #3E3C3A!important;
        visibility:visible!important;
      }
    }

    .testUpseller .testItem .modal h5 {
      font-size: 16px;
    }

    .checkoutMainArea {
      .container {
        max-width: 767px;
      }
    }

    .selected_test.checkout {

      .test_title {
        font-size: 14px;
      }

      .test_price {
        font-size:18px;
      }

      .price_icon {
        font-size:14px;
      }

      .test_price_small {
        font-size:12px;
      }

      .removeFromCart {
        width:100%;
      }

      .quantityOptonArea {
        display: flex;
        align-items: center;
        justify-content: center;
        &.disableFlex {
          display:block;
          .quantityOptonTitle, .quantity {
            display: block;
            width: 100%;
          }

        }
        .quantityOptonTitle {
          display: inline-block;
          width: 25%;
          margin: 0;
          vertical-align: middle;
          margin-top: 10px;
        }
        

        .quantity {
          width:75%;
        }

      

      } 

    } 

  }

  #phlebotomyOptions.checkout,
  #guestOptions {

    h3.heading4 {
      font-size: 20px!important;
    }

    .w-75 {
      width:100%!important;
    }

    .phlebotomyToolTip.arrow-top:before,
    .phlebotomyToolTip.arrow-top:after {
      left: calc(50% - 16px);
    }
  } 

  #checkoutBottom {
    .w-75 {
      width:100%!important;
    }

    .voucherArea {
      #voucherCode {
        width: 75%;
        margin-botttom:25px;
      }
    }

  }

  #checkoutFormArea {
    padding:15px;
    padding-bottom: 50px;
    #checkoutSubNav li svg{
      padding: 0 10px;
    }
    .checkoutForm {
      .w-75 {
        width:100%!important;
      }
    }
  }

  #checkoutArea .selected_test .card .card-header.no-borders h5 {
    font-size: 12px;
  }

  
  #getTheApp {
    padding: 30px;
    margin-top: 161px;
    .getTheAppImg {
      margin-top: -111px;
      margin-bottom: 30px;
    }
  }

  #checkoutArea .marker_counter {
    margin-right: 0px;
  }


  #partnerClinicFinder {
    .modal-dialog {
      width: 100%; 
      margin: 0;
      padding: 0;
      max-width: 100%;
    }

    .modal-content {
      height: auto;
      min-height: 100%;
      border-radius: 0;
    }
  }


}

body.edge #checkoutFormArea .checkoutForm .btn {
    background: #ff3b00;
    border: 1px solid #ff3b00;
    color: #fff!important;
}

body.edge #checkoutArea {

  manuallyEnterAddress {
    color: #db5a3e!important;
  }
  #quantityCounter input {
    color: #db5a3e!important;
  }
  .selected_test.checkout .btn.customise {
    border: 1px solid #db5a3e;
    color: #db5a3e;
    &:hover {
      background: #db5a3e!important; 
    }
  }

  .testUpseller .testItem .modal .close:after,
  .testUpseller .testItem .modalToggler {
    color: #db5a3e;
  }
  .testUpseller .testItem h5,
  .testUpseller h3,
  #quantityCounter span {
    color: black;
  }

  .deleteMarkerModal .removeFromCart {
    background: #db5a3e !important;
    border-color: #db5a3e !important;
    color: white !important;
    &:hover {
      background: #db5a3e !important;
    }
  }

}



body.edge {
  #checkoutArea {
    .selected_test.checkout {
      .test_title {
        color:black!important;
      }
    }

    #phlebotomyOptions.checkout {
      .phlebotomyOption {
        a {
          color: #FF3B00;
        }
      }
    }

    .selected_test .card .card-header.no-borders h5 {
      color: black;
    } 

    .selected_test .itemQuantity {
      background: #ff3c06;
    }
    .quantityOptonArea {
      .quantity {
        color: #FF3B00;
      }
    } 



    .voucherArea {
      #voucherCodeBtn {
        background: none !important;
        border: 1px solid var(--primary-color);
        color: var(--primary-color) !important;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      }
    } 

    .checkoutFinished {
      background:none!important; 
      color:#ff3c03!important;
    }
    a {
      color: #ff3c03;
    }
  } 

  #checkoutSubNav {
    li.complete {  
      color: #ff3c03;
    }

    li.active {
      color: #e57b3d;
    }
  }

  #checkoutFormArea {
    .btn {
      background: #FF3B00;
      border: 1px solid #FF3B00;
      color: white !important;
      &:hover {
        background: #db5a3e !important;
        border: 1px solid #db5a3e;
      }
    }
    .haveAccount {
      background: #f9e9c4;
      a {
        color: #ff3c03;
      }
    }
    #termsBox [type="checkbox"]:not(:checked) + label:before, #termsBox [type="checkbox"]:checked + label:before {
      border: 1px solid #f8ab00;
    }
    #termsBox [type="checkbox"]:checked + label:before {
      background: #f8ab00;
    }

    .checkoutForm {
      input {
        &:focus { 
          border-bottom: 1px solid #f9a33f!important;
        }
      }
      .form-group .form-control:focus {
        border-bottom: 1px solid #f9a33f!important;
      }
      .form-group:focus-within label {
        color: #f9a33f!important;
      }
      .form-group select.form-control {
        color: #5f6c73 !important;
      }
      .StripeElement.StripeElement--focus + label {
        color: #f9a33f;
        visibility: visible;
      }
      &.stripe {
        .form-group{ 
          .StripeElement {
            border-bottom: 1px solid #D2D8E4;
            &.StripeElement--focus {
              border-bottom: 1px solid #f9a33f!important;
            }
          }
          label {
            order: 1;
          }
          .StripeElement, .form-control {
            order: 2;
          } 
        }
      }
    }

  } 

  #mobileBasket {
    background: #f9e2b3c2;
    color: black;
    .testPrice svg{
      color: #ff3c03;
    }
  }

  #partnerClinicFinder {
    .modal-header {
      background: linear-gradient(to left, var(--profile-gradient-color-1) 0%, #fbbc3c 40%, #dd373c 60%, var(--profile-gradient-color-2) 100%) left top transparent no-repeat;
      background-size: 100% 5px;
    }
    .partnerClinicSearch  {
      .submitClinicSearch {
        background: #FF3B00;
        &:hover {
          background: #db5a3e !important;
        }
      }
      .useLocation {
        color: #FF3B00;
        &:hover {
          color: #db5a3e !important;
        }
      }
      input:focus {
        border: 1px solid #f9a33f;
      }
      .restartClinicSearch {
        color: #f9a33f;
      }
    }
  }
  #partnerClinicSearchResults {
    h3 span {
      color: #f9a33f;
    }
  }  
} 

.partnerActivation{
  .searchGroup-backdrop{
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  .searchGroup{
    position: relative;
    z-index: 2;

    ul.dataList{
      position:absolute;
      top: 45px;
      left: 0;
      display: block;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      width: 100%;
      max-height: 130px;
      overflow-y: scroll;
      padding: 0px;

      li{
        list-style: none;
        text-align: start;
        padding: 10px 5px;
        cursor: pointer;

        &:not(:last-of-type){
          border-bottom: 1px solid #ccc;
        }

        &:hover{
          background-color: #ccc;
        }
      }
    } 
  }

  datalist {
    position: absolute;
    max-height: 20em;
    border: 0 none;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  datalist option {
    font-size: 0.8em;
    padding: 0.3em 1em;
    background-color: #ccc;
    cursor: pointer;
  }
  
  datalist option:hover, datalist option:focus {
    color: #fff;
    background-color: #036;
    outline: 0 none;
  }
}


