#selectedPhlebotomyOption {
  h4 {
  	font-weight:600;
  }
  a {
  	color: #000;
  	text-decoration:underline;
  	font-weight:400;
  }

  @media (max-width: 768px) {
    border-top: 2px solid #e2e2e2;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}