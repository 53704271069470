#loaderScreen {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content:center;
  background-color: var(--loader-bg-color);
  z-index: 9999;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
  min-width: 100vw;
  min-height: 100vh;

  --hue: 223;
  --bg: hsl(var(--hue),10%,90%);
  --fg: hsl(var(--hue),10%,10%);

  
  .pl {
    display: block;
    width: 80px;
    height: 80px;
  }
  
  .pl__ring, .pl__ball {
    animation: ring 2.5s ease-out infinite;
  }
  
  .pl__ball {
    animation-name: ball;
  }

  p{
    margin-top: 15px;
  }

  .version{
    font-size: 12px;
    padding: 15px 25px;
    align-self: flex-end;
    justify-self: flex-end;
  }
  
  /* Dark theme  */
  @media (prefers-color-scheme: dark) {
    :root {
      --bg: hsl(var(--hue),10%,10%);
      --fg: hsl(var(--hue),10%,90%);
    }
  }
  
  /* Animation */
  @keyframes ring {
    from {
      stroke-dasharray: 0 257 0 0 1 0 0 258;
    }
    25% {
      stroke-dasharray: 0 0 0 0 257 0 258 0;
    }
    50%, to {
      stroke-dasharray: 0 0 0 0 0 715 0 0;
    }
  }
  
  @keyframes ball {
    from, 50% {
      animation-timing-function: ease-in;
      stroke-dashoffset: 1;
    }
    64% {
      animation-timing-function: ease-in;
      stroke-dashoffset: -109;
    }
    78% {
      animation-timing-function: ease-in;
      stroke-dashoffset: -145;
    }
    92% {
      animation-timing-function: ease-in;
      stroke-dashoffset: -157;
    }
    57%, 71%, 85%, 99%, to {
      animation-timing-function: ease-out;
      stroke-dashoffset: -163;
    }
  }
}