.card {
  border-radius:10px;
  .cardHeader {
  	background: #fff;
    border-radius: 10px; 
    padding:0;
    font-size: 16px;
    border-bottom: none;

    h5 {
      font-size: inherit;
    }
    
  	a {
  	  font-weight:400;
  	  color: #000;
  	  padding: 20px;
  	}
  }
}