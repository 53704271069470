
.container{
  color: var(--font-color);
  
  input{
    border-radius: 10px;

    &::placeholder{
      color: gray;
      font-weight: 300;
    }

    &:focus::placeholder{
      color: inherit
    }
  }
}

.box {
  background-color: var(--checkout-box-bg-color);
  border-radius: 10px;
  padding: 15px;
}