
.container {
  min-height: 100vh;
  height: fit-content;
  background: #ffffff;
  
  *[class*="placeholder"]:not([class*="placeholder-"]) {
    opacity: 0.5;
    background-color: #D2D8E4;
    color: #D2D8E4;
    border-radius: 3px;
  }

  header{
    border-bottom: 2px solid #D2D8E4;

    &[data-variant="edge"] {
      background: #000;
      color: #fff;

      .logo img{
        max-height: 30px;
      }
    }

    &[data-variant="forth"]{
      .logo img{
        max-height: 50px;
      }
    }

    div.logo {
      height: 25.38px;
      width: 186.16px;
    }
  }
  

  main {
    hr {
      background: #E1E2E6;
      opacity: 1;
    }
    
    button, a {
      pointer-events: none;
      color: transparent !important;
    }

    button {
      border: 1px solid #E1E2E6;
    }

    padding-inline: 1rem;

    .productImage {
      width: clamp(7rem, 4.1154rem + 6.0096vw, 10.125rem);
      height: clamp(6rem, 4.3269rem + 3.4856vw, 7.8125rem);
    }

    .productInfo {
      h3,p {
        width: fit-content;
      }
    }

    .qtyBtn{ 

      button{
        padding: 3px 5px 3px 5px;
        vertical-align: middle;
        text-align: center;
        font-size: 20px;
        width: 40px;
        border: 1px solid #E1E2E6;
        cursor: progress !important;
        pointer-events: none;
        color: transparent;

        svg{
          color: transparent;
        }
      }

      input {
        max-width: 45px;
        width: 100%;
        border: 1px solid #E1E2E6;
        pointer-events: none;
      }
    }

    .pill {
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #E1E2E6;

      .pillCircle {
        width: 20px;
        aspect-ratio: 1;
        border-radius: 20px;
      }

      .pillText {
        width: fit-content;
      }
    }

    .voucherContainer {
      padding-bottom: 10px;
      border-bottom: 1px solid #E1E2E6;

      input{
        border: 1px solid #E1E2E6;
      }

      button{
        padding-inline: 15px;
      }
    }

    .paymentIcon {
      width: 35px;
      height: 19.20px;
    }
  }
}