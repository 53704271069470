#partnerHeader {
	z-index: 7000;
	.white-mode {
	  text-decoration: none;
	  padding: 17px 40px;
	  background-color: yellow;
	  border-radius: 3px;
	  color: black;
	  transition: .35s ease-in-out;
	  position: fixed;
	  left: 15px;
	  bottom: 15px;
	}

	.badge{
    display: block;
  }
  
  .badge-green{
    border-radius: 5px;
    border: 1px solid #70898D;
    color: #70898D;
  }

  .badge-yellow, .badge-orange{
    border-radius: 5px;
    border: 1px solid #FAA240;
    color: #FAA240;
  }

  .badge-red{
    border-radius: 5px;
    border: 1px solid #D51D2E;
    color: #D51D2E;
  }

	.btn-shop{
		background-color: #273436;
		border: 1px solid #273436;
		&:hover{
			background-color: #6A7D81 !important;
			border-color: #6A7D81;
		}
	}

	.notification-backdrop{
		background-color: transparent;
		position: fixed;
		inset: 0px 0px 0px 0px;
	}

	.notification-ui {
	  vertical-align: middle;
	}

	.notification-ui a:after {
	  display: none;
	}

	.notification-ui_icon {
	  position: relative;

	  &.show {

	    .notifications {
	      button {
	        background: #6A7D81;
	        border-color: #6A7D81;
	        color: white;
	      }
	    }
	  }

	  .unread-notification {
	    display: inline-block;
	    height: 7px;
	    width: 7px;
	    border-radius: 7px;
	    background-color: #66BB6A;
	    position: absolute;
	    top: 7px;
	    left: 12px;
	  }

	  .notif-num {
	    position: absolute;
	    top: -1px;
	    right: -10px;
	    border-radius: 360px;
	    border: 2px solid #445C60;
	    background: red;
	    color: white;
	    width: 10px;
	    height: 10px;
	  }
	}

	@media (min-width: 900px) {
	  .notification-ui_icon .unread-notification {
	    left: 20px;
	  }
	}

	.notification-ui_dd-filter {
	  cursor: pointer;
	}

	.notification-ui_dd-filter, .filterToggle {
	  background: #2B3C3E;
	  padding: 15px;
	  color: #B4CCD0;
	  text-align:center;
	}

	.notification-ui_dd {
	  padding: 0; 
	  -webkit-box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
	  box-shadow: 0 5px 20px -3px rgba(0, 0, 0, 0.16);
	  border: 0;
	  max-width: 400px;
	  background: #364a4d;

	  &:after {
	    content: "";
	    position: absolute;
	    top: -20px;
	    left: calc(93% - 9px);
	    border-top: 10px solid transparent;
	    border-right: 6px solid transparent;
	    border-bottom: 10px solid #364B4E;
	    border-left: 6px solid transparent;
	  }

	  .notification-ui_dd-header { 
	    padding: 15px 25px;
	    color: #B4CCD0;

	    h3 {
	      margin-bottom: 0;
	      text-align: left;
	    }

	    svg{
	      font-size: 22px;
	    }
	  }

	  .notification-ui_dd-content {
	    max-height: calc(100vh - 170px);
	    overflow-y: auto;
			overflow-x: hidden;

			.loadMore {
		    background: #2c3c3e;
		    cursor: pointer;
		    padding: 10px;
		    font-size: 12px;
		  }
	  }
	}

	@media (min-width: 900px) {
	  .notification-ui_dd {
	    min-width: 400px;
	    position: absolute;
	    left: -350px;
	    top: calc(100% + 15px);
	  }
	} 

	@media (min-width: 720px) {
	  .notification-ui_dd {
	    min-width: 400px;
	    position: absolute;
	    left: -350px;
	    top: calc(100% + 15px);
	  }
	} 

	@media (max-width: 576px) {
	  .notification-ui_dd {
	    min-width: 300px;
	    position: absolute;
	    left: -257px;
	    top: calc(100% + 15px);
	  }
	} 


	.notification-list {
	  text-transform: initial;
	  font-size: 16px;
	  display: -webkit-box;
	  display: -ms-flexbox;
	  display: flex;
	  -webkit-box-pack: justify;
	  -ms-flex-pack: justify;
	  justify-content: space-between;
	  padding: 20px 25px;
	  border-bottom: 1px solid #445C60;
	  overflow:hidden;
    position:relative;
	  &.unread {
	    background: #455a5c;
	    &:before {
	      content:'';
			  position: absolute;
			  background-color: #47b27a;
		    width: 4px;
		    height: 100%;
			  top: 0;
			  left:0;
	    }
	  }

	  .orderRef {
	    border: 1px solid;
	    border-radius: 5px;
	    font-size: 12px;
	    padding: 5px;
	    line-height: 12px;
	    display: inline-block;
	    color: #B4CCD0;
	  }

	  svg{
	    font-size: 18px;
	    color: #B4CCD0;
	    line-height: 25px;
	  }

	  a {
	    color: #ffffff;
		font-size: initial;
		font-weight: initial;
		text-transform: initial;
		padding: initial;
		text-decoration: underline;
		color: #B4CCD0;
		&:hover {
		  background: none;
		}
	  }

	  p {
	    font-size: 12px;
	    font-weight: 100;
	    margin: 0px;
	    margin-top:10px;
	  }

	  .notificationTime {
	    font-size: 10px;
	    color: #B4CCD0;
	  }

	  .adImagePlaceholder {
	    width: 100%;
	    height: 100%;
	    background-size: 150%;
	    background-position: 75%;
	    background-repeat: no-repeat;
	    border-radius: 0 360px 360px 0px;
	  }

	  .adPadding {
	    padding: 20px 25px 25px 0px;
	  }

	  &.red {
	    .orderRef {
	      background: #D51D2E;
	      color: white;
	      border-color: #D51D2E;
	    }
        svg{
          color: #D51D2E;
        }
	  }

	  &.green {
	    .orderRef { 
	      color: #47B27A;
	      border-color: #47B27A;
	    }
        svg{
          color: #47B27A;
        }
	  }

	  &.blue {
	    .orderRef { 
	      color: white;
	      border-color: #0793BF;
	      background: #0793BF;
	    }
        svg{
          color: #0793BF;
        }
	  }

	  &.white {
	    background: white;
	    color: #000000;
	    padding: 0px;
	    .orderRef { 
	      color: white;
	      border-color: #0793BF;
	      background: #0793BF;
	    }
        svg{
          color: #0793BF;
        }
        .notificationTime {
          color: #000000;
        }
	  }
	}

	nav{
		position: fixed;
		top: 0;
		width: 100%;
		min-height: 66px;
		background-color: #445C60;
		color: #B4CCD0;
		font-size: 12px;
		font-weight: 500;
		z-index: 999;
		font-size: 14px;
		font-weight: 500;
		text-transform: uppercase;

		@media(max-width: 768px) {
			background: #2f3f42;
		}

		.navbar-brand{
			max-height: 66px;
			min-height: 66px;
			background-color: #2f3f42;

			img{
				max-width: 50px;
				max-height: 50px;
			}

			.text-brand{
				font-size: 16px;
				@media(max-width: 768px) {
					font-size: 12px;
				}
				font-weight: 300 !important;
				text-transform: uppercase;
				white-space: break-spaces;
				svg{
					font-size: 12px;
				}
			}
		}

		.dropdownHeader {
			position:relative;
			color: white; 
			
			.chevrot-icon{
				padding-left: 10px;

				&::after{ 
					font-size: 12px;
					top: 3px;
					border: none;
					font-family: "Font Awesome 6 Pro";   
					font-weight: 900;
					content: "\f078";
					transition: all 0.2s;
					display: block;

				}
			}

			@media screen and (max-width: 1024px) {
				
				.top-15::after{
					top: 15px;
				}
	
				.top-25::after{
					top: 25px;
				}
			}

			.show .chevrot-icon::after{
				transform: rotate(180deg);
				transform-origin: center;
			}

			

			.dropdown-menu{
				width: 80% !important;
				
				&[data-bs-popper]{
					top: 46px;
					right: 0;
					left: auto;
					border-radius: 5px;
					min-width: 225px !important;
					background-color: #364B4E;
				}
	
				a{
					text-align: start;
					text-transform: capitalize;
					color: #B4CCD0;
					padding-left: 20px;
					&:hover{
						background-color: #2B3C3E;
					}
				}
	
				.active{
					background-color: #2B3C3E;
					font-weight: 500;
					color: white;
				}
			}

			@media(max-width: 576px){
				.dropdown-menu{ 
					&[data-bs-popper]{
						width: 100vw !important;
						left: -27vw !important;
						top: 100% !important;
						margin-top: 15px!important;
					}
				}
			}


			.dropdown-toggle::after{
				display: none;
			}
		}

		.nav-link{
			color: #B4CCD0;
			padding: 0;
			letter-spacing: 1px;
			&.active{
				color: #fff
			}
			&:hover{
				color: #fff
			}
		}

		.navbar-nav .notif-num {
			position: absolute;
	    top: -1px;
	    right: -10px;
	    border-radius: 360px;
	    border: 2px solid #445C60;
	    background: red;
	    color: white;
	    width: 10px;
	    height: 10px;
		}
		
		@media(max-width: 576px){
			.navbar-brand{
				position: relative;
				width: auto;
			}
		}
	}

	.notifications{
	  position: relative;
    .btn {
      width: 37px;
      height: 37px;
    }
	
		svg{
		  margin-left: -5px;
			font-size: 17px;
		}

		.notif-num{
			top: 7px;
      right: 5px;
      border: 1px solid #273436;

		}
	}

	button{
		background: #273436;
		border: 1px solid #273436;
		color: #B4CCD0;
		&:hover{
			background: #6A7D81 !important;
			border-color: #6A7D81;
		}
	}

	.newUpdatesButton{
		color: #B4CCD0;
		background-color: #273436;
		border-radius: 50px;
		padding: 10px;
		position: relative;
		cursor: pointer;

		&.showRedDot {
			svg{
				-webkit-animation: wobble-hor-bottom 3s both infinite;
	  		animation: wobble-hor-bottom 3s both infinite ;
			}

			&:after{
				content: '';
				position: absolute;
				top: 11px;
				right: 8px;
				width: 4px;
				aspect-ratio: 1;
				border-radius: 5px;
				background-color: red;
			}
		}

		@-webkit-keyframes wobble-hor-bottom {
			0%,
			100% {
				-webkit-transform: translateX(0%);
								transform: translateX(0%);
			}
			15% {
				-webkit-transform: translateX(-2px) rotate(-6deg);
								transform: translateX(-2px) rotate(-6deg);
			}
			30% {
				-webkit-transform: translateX(2px) rotate(6deg);
								transform: translateX(2px) rotate(6deg);
			}
			45% {
				-webkit-transform: translateX(-2px) rotate(-3.6deg);
								transform: translateX(-2px) rotate(-3.6deg);
			}
			60% {
				-webkit-transform: translateX(2px) rotate(2.4deg);
								transform: translateX(2px) rotate(2.4deg);
			}
			75% {
				-webkit-transform: translateX(0%);
								transform: translateX(0%);
			}
		}
	
		@keyframes wobble-hor-bottom {
			0%,
			100% {
				-webkit-transform: translateX(0%);
								transform: translateX(0%);
			}
			15% {
				-webkit-transform: translateX(-2px) rotate(-6deg);
								transform: translateX(-2px) rotate(-6deg);
			}
			30% {
				-webkit-transform: translateX(2px) rotate(6deg);
								transform: translateX(2px) rotate(6deg);
			}
			45% {
				-webkit-transform: translateX(-2px) rotate(-3.6deg);
								transform: translateX(-2px) rotate(-3.6deg);
			}
			60% {
				-webkit-transform: translateX(2px) rotate(2.4deg);
								transform: translateX(2px) rotate(2.4deg);
			}
			75% {
				-webkit-transform: translateX(0%);
								transform: translateX(0%);
			}
		}
	}
}

.newUpdates{
	.newUpdates-backdrop{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		animation: bgFadeIn 0.1s linear forwards;
		z-index: 7002;

		@keyframes bgFadeIn {
			100%{
				background-color: #0000007a;
			}
		}
	}
	
	.newUpdates-container{
		position: fixed;
		top: 0;
		right: 0;
		background-color: #eeeeee;
		width: 30vw;
		min-width: 500px;
		height: 100vh;
		scroll-behavior: smooth;
		overflow-y: scroll;
		z-index: 7003;
		animation: slideIn 0.2s linear forwards;

		@keyframes slideIn {
			0%{
				transform: translate(100%);
			}
			100%{
				transform: translate(0%);
			}
		}

		.newUpdates-header{
			background-color: #445C60;
			height: 80px;
			color: white;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px;
			position: sticky;
			top: 0;
	
			svg{
				cursor: pointer;
				transition: color 0.2s ease-in;
				&:hover {
					color: #dddddd;
				}
			}
		}
	
		.newUpdates-content{
			padding: 15px;

			.newUpdates-item{
				background: white;
				padding: 20px;
				margin-bottom: 10px;

				ul li:last-of-type p{
					margin-bottom: 0px;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		.newUpdates-container{
			width: 100vw;
			min-width: auto;
		}
	}
}

#modalEditTag{
	z-index: 7001;
}

#mobileMenu{
	display: none;
}

@media(max-width: 991px) {
	#mobileMenu{
		position: fixed;
		display: block;
		bottom: 0;
		width: 100%;
		background-color: #445C60;
		color: #B4CCD0;
		font-weight: 500;
		z-index: 6001;
		cursor: pointer;
		
		.notif-num {
			position: absolute;
			top: 4px;
			right: 20px;
			padding: 1px 5px;
			border-radius: 50%;
			background: red;
			color: white;
			font-size: 12px;
			width: 10px;
			height: 10px;
		}

		.mobile-nav{
			color: #B4CCD0;
			font-size: 11px;
			padding: 10px 0px 5px 0px;
			p{
				margin: 0;
			}
			svg{
				font-size: 20px;
			}
			
			&.active{
				color: #fff
			}
		}
	}
}