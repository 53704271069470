#checkoutHeader {
  background: #fff;
  border-bottom: 2px solid #D2D8E4;
  .close {
    font-weight: 600;
    align-items: center;
    display: flex;
    justify-content: end;
    color: #000;
  }

  .logo { 
    max-height: 50px;
  }
}

body:global(.edge) {
  #checkoutHeader {
    background: #000;
    color: #fff;
    .close { 
      color: #fff;
    }
    .logo { 
      max-height: 30px;
    }
  }
}