
.approvalModal {
	.btn {
		border: none;
	}
	.btn-success {
		background-color: #198754

	}

	.btn-danger {
		background-color: #dc3545
	}
}

.partnerLayout {
	.btn.btn-primary {
		background: transparent;
    border: 1px solid #70898D;
    color: #70898D !important;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 30px !important;
    &:hover {
      background: #70898D !important;
      color: #fff !important;
      border: 1px solid #70898D !important;
    }
	}
}

.helpAndSupport,
#shopSignUp {
	.btn.btn-primary {
		background: #70898D;
    border: 1px solid #70898D;
    color: #fff !important;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 30px !important;
    &:hover {
      background: #273436 !important;
      color: #fff !important;
      border: 1px solid #273436 !important;
    }
	}
	.btn.btn-secondary {
		background: transparent;
    border: 1px solid #70898D;
    color: #70898D !important;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 30px !important;
    &:hover {
      background: #70898D !important;
      color: #fff !important;
      border: 1px solid #70898D !important;
    }
	}
}

body, #partnerProgram {
	overflow-x: unset;
}

#partnerContainer{
	.partner-container{
		max-width: 1920px;
		margin: 0 auto;
	}

	button:focus{
		box-shadow: none;
	}
	.statBlock{
		background-color: #F6F6F6;
		padding-left: 1em;
		.upper {
			font-size: 12px;
			letter-spacing: 1.5px;
			color: #445C60;
		}

		svg{
			font-size: 30px;
			color: #B4CCD0;
		}
		.stat-num{
			font-size: 28px;
			font-weight: 400;
			color: #000;
		}
		&:hover{
			background-color: #e6e6e6;
			cursor: pointer;	
		}
	}
}

#partnerProgram, #partnerSignIn {



	.bg-gray{
    background-color: #F0F2F3;
  }
	#sizer {
		height: 100%;

		overflow: hidden;
		// width: 100%;
		width: 100%;
		margin: 0 auto;
		
		position: relative;
		&::before {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 20px;
			text-align: center;

			z-index: 2;
		}
	}
	
	#square {
		width: 100%;
		padding-bottom: 100%;
		position: relative;
	}
	
	#circle {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background-image: url(../img/about_forth.png);
		background-size: contain;
		border-radius: 50%;
		z-index: 500;

		img{
			width: 100%;
			height: 100%;
			z-index: 1;
			transform: translate(2rem, 2rem);
		}
	}

	background-color: #fff;
	.heading {
		font-size: 30px;
		font-weight: 100;
		color: black;
		letter-spacing: 4px;
		text-transform: uppercase;
		b{
			font-weight: 500;
		}
	}

	.header {
		min-height: 75vh;
		background-image: url(../img/partnerHeader.png);
		// background-size: 100% auto;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		position: relative;
		overflow: hidden;

		.glow-1{
			position: absolute;
			background: radial-gradient(#0AF7F7 20%, transparent 70%);
			
			width: 800px;
			height: 400px;
			top: -200px;
			left: -250px;
			z-index: 2;
		}

		.glow-2{
			position: absolute;
			background: radial-gradient(#36D4AE 20%, transparent 70%);
			width: 800px;
			height: 400px;
			z-index: 1;
			top: 0;
			left: -500px;
		}

		.logo{
			z-index: 99;
		}

		p{
			font-size: 30px;
			font-weight: 100;
			color: black;
			letter-spacing: 5px;
			b{
				font-weight: 500;
			}
		}
		@media (min-width:720px) {
			.logo {
				margin-left: 3rem;
			}
			.quotingLogo{
				margin-top: 0rem;
			}
			.glow-1{
				top: -250px;
				left: -250px;
			}
			.glow-2{
				top: -50px;
				left: -600px;
			}
		}
		@media (min-width:992px) {
			.logo {
				margin-left: 10rem;
			}
			.quotingLogo{
				margin-top: 0rem;
			}
		}
		.quotingLogo{
			margin-top: 10rem;
		}

		@media (max-width:720px){
			.glow-1, .glow-2{
				display: none;
			}
		}
	}

	@media (max-width:720px){
		.header{
			background: #F6F6F6 !important;
			clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
			min-height: 0px !important;
			padding-bottom: 2rem;
			.quotingLogo{
				margin-top: 0px !important;
			}
		}
	}

	.intro{
		p{
			font-size: 19px !important;
			font-weight: 200;
		}
	}

	.about-forth, .benefits{
		p, h6{
			margin-bottom: 1rem;
		}

		p{
			font-size: 14px !important;
		}
		h6{
			font-size: 16px !important;
		}
		h4{
			font-size: 30px !important;
		}

		h5{
			font-weight: 500;
			font-size: 15px;
			letter-spacing: 1px;
			margin-bottom: 1em;

		}

		.benefits-list{
			list-style-type: disc;
			list-style-position: inside;
			text-indent: -2em;
			padding-left: 2em;
			li{
				list-style-type: none;
				margin-bottom: 1.5em;
				font-size: 14px;
			}

			li::before {
				font-family: "Font Awesome 6 Pro";
				content: "\f00c";
				color: green;
				margin-right: 1em;
			}
		}

		.founder-statement{
			.half-bg{
				background: linear-gradient(0deg, #F6F6F6 50%, #ffffff 50%);
			}
			.full-bg{
				background: #F6F6F6;
				border-radius: 0 0 0 50px;
			}
			p{
				font-size: 16px !important;
				font-weight: 300;
			}
			.divider-green{
				height: 5px;
				width: 50px;
				background-color: #47B27A;
				margin: auto;
			}
			h5{
				font-weight: 600;
				letter-spacing: 2px;
				font-size: 18px;
			}
			.title{
				font-size: 15px;
			}
		}
	}

	.pricing-modal {
		h1 {
			font-size: 37px;
			font-weight: 100;
			letter-spacing: 1px;

			@media (max-width: 768px){
			font-size: 22px;				
			}
		}

		h3{
			letter-spacing: 2px;
			font-weight: 400;
		}
		.modal-header {
			border-bottom: none;
		}
		.close{
			top: 20px
		}


	}

	.how-it-works{
		background-image: url(../img/how_it_works.png);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		color: #fff;
		overflow: hidden;

		.carousel-indicators [data-bs-target] {
			background-color: #fff;
			height: 10px;
			width: 10px;
			border-radius: 5em;
			border-top: none;
			border-bottom: none;
		}
		
		.carousel-indicators .active {
			background-color: #81FFE3;

		}

		.heading {
			color: #fff;
			font-size: 48px;
		}

		h3 {
			font-size: 40px !important;
		}

		h4{
			font-size: 15px;
			font-weight: 500;
			text-transform: uppercase;
		}
		p{
			font-size: 14px;
			font-weight: 100;
		}
		.transparent-background{
			background-color: rgba(109, 145, 149, 0.6);
			height: 100%;
		}
		.nav-pills {
			a{
				text-transform: uppercase;
				color: #fff;
				font-weight: 300;
			}
			.active{
				background-color: transparent;
				text-decoration: underline;
				text-underline-offset: 3px;
			}
		}
		.carousel{
			height: 20em;
		}

		svg{
			font-size: 7em;
		}
		span{
			vertical-align: top;
			margin-left: 10px;
			font-size: 12px;
			font-weight: 200;
		}
		.arc1{
			border: 1px solid #81FFE3;
			border-radius: 50%;
			height: 100px;
			width: 100px;
			border-top-color: transparent;
			transform: rotate(60deg)
		}
	}

	.white-bg{
		background-color: #ffffff;
	}

	.gray-bg{
		background-color: #F6F6F6;
	}

	.pricing{
		background-color: #F6F6F6;
		
		.table-headers{
			color: #000000;
			font-weight: 500;
		}

		.price-table{
			a{
				color: #70898D;
				text-decoration: underline; 
			}
			p{
				margin: 0;
			}
			.price{
				color: #000;
			}
			.price-15{
				font-size: 15px;
				font-weight: 500;
			}

			@media screen and (max-width: 768px){
				.price {
					font-size: 12px !important;
				}
			}
		}
		
		.row-underline{
			border-bottom: 1px solid #E6E6E6;
			padding-bottom: 1em;
		}

		button.price-list{
			background: #fff;
			color: #70898D;
			border: 1px solid #70898D;
		}

		button.price-list:hover{
			background: #70898D !important;
			color: #fff !important;
			border: 1px solid #70898D !important;
		}
	}

	@media only screen and (max-width: 768px) {
		.pricing{
			font-size: 12px !important;
		}
	}

	.get-started{
		background: #47B27A;
		button{
			background: #fff;
			color: #47B27A;
			border: 1px solid #47B27A;
		}

		button:hover{
			background: #47B27A !important;
			color: #fff !important;
			border: 1px solid #47B27A !important;
		}
	}

	footer{
		color: #3E3C3A;
		background: #fff;
		.row-underline{
			border-bottom: 1px solid #ADADAD;
		}
		.privacy-policy{
			color: #6C757D;
		}
	}
	.progress{
		border-radius: 0px;
		.progress-bar{
			padding: 5px 0;
			background-color: #47B27A;
			border-radius: 0;
		}
	}

	.partner-form, .signin-form, .forgotten-form{

		.blocking-span{
			display: block;
		}
		.blocking-span input{
			border: 1px solid #B4CCD0;
			border-radius: 5px;
			height: 60px;
			padding-top: 30px;
			padding-left: 10px;
			padding-right: 20px;
			width: 100%;
			font-size: 16px;
			font-weight: 100;
			&.form-error{
				border: 1px solid red;
			}
		}
		.floating-label{
			display: inline-block;
			color: #70898D;
			font-size: 12px;
			left: 25px;
			line-height: 0px;
			position: absolute;
			top: 18px;
			transition: top 0.3s ease-in-out 0s;
		}
		label.floating-label{
			display: inline-block;
			color: #70898D;
			font-size: 12px;
			left: 10px;
			line-height: 0px;
			position: absolute;
			top: 13px;
			transition: top 0.3s ease-in-out 0s;
		}
		input{
			margin-bottom: 2rem;
		}

		.dropdown{
			button{
				background: #fff;
				color: #70898D;
				font-size: 12px;
				font-weight: 300;
				height: 60px;
				border: 1px solid #B4CCD0;
				border-radius: 5px !important;
				width: 100%;
				text-align: left;
				text-transform: none;
				padding-bottom: 50px;
				&.form-error{
					border: 1px solid red;
				}
			}

			span {
				position: absolute;
				top: 33px;
				left: 17px;
				font-size: 16px;
				font-weight: 100;
			}
			.btn:hover{
				background: #B4CCD0 !important;
			}
			.dropdown-item{
				background-color: #fff;
				border-bottom: 1px solid #B4CCD0;
			}
			margin-bottom: 2rem;
		}

		.custom-control{
			margin-top: -1em;
			margin-bottom: 2rem;
		}

		.custom-control-label::before{
			top: -0.2em;
			width: 1.2rem;
			left: -2rem;
			height: 1.2rem;
			border: 2px solid #70898D;

		}

		.custom-control-input:checked ~ .custom-control-label::before{
			font-family: "Font Awesome 6 Pro"; 
			border: 2px solid #70898D;
			background-color: #fff;
			color: #70898D;
			padding-left: 1px;
			padding-top: 1px;
			font-weight: 400;
			content: 	"\f00c";
		}
		.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
			background: none;
		}

		.form-check-input{
			font-size: 25px;
			border-color: #70898D;
			&:checked{
				background-color: #70898D;
				border-color: #70898D;
			}
		}


		&.options{
			.custom-control-input:checked ~ .custom-control-label::before{
				font-family: "Font Awesome 6 Pro"; 
				border: 2px solid #70898D;
				background-color: #70898D;
				color: #fff;
				padding-left: 1px;
				padding-top: 1px;
				font-weight: 400;
				content: 	"\f00c";
			}
			.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
				background: none;
			}
			h1{
				letter-spacing: 2px;
				font-weight: 100;
			}

		}
	}

	.signUp-nav-buttons{
		background: #47B27A !important;
		padding-top: 2rem;
		padding-bottom: 2rem;
		button{
			border: none;
		}
		.back{
			background-color: #5fca93;
		}
		.back:hover{
			background-color: #fff !important;
			color: #5fca93 !important;
		}
		.next{
			background-color: #fff;
			color: #47B27A;
		}
		.next:hover{
			background-color: #5fca93 !important;
			color: #fff !important;
		}
	}
	.logo, .themes, .default-colours{

		input[type="file"] {
			display: none;
		}
		.logo-upload {
			border: 1px solid #70898D;
			color: #70898D;
			display: inline-block;
			border-radius: 360px;
			padding: 6px 3rem;
			cursor: pointer;
		}
		h6{
			font-size: 13px;
			font-weight: 500;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #000;
		}
		h5{
			font-size: 15px;
			font-weight: 500;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #000;
		}

		.logo-hover, .image-hover{
			position: absolute;
			display: flex;
			flex-flow: column wrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			background-color: black;
			z-index: 9;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: 0.2s all;

			&:hover{
				opacity: 0.8;
			}

			button{
				width: 30%;
			}
		}
		
		@media (max-width: 768px){
			.image-hover button{
				width: 80%;
			}
		}

		.sample-colours{
			border: 1px solid #dddddd;
			border-radius: 1px;
		}
		
	}

	#LogoDisplay{
		border-radius: 5px;
		max-width: 330px;
		max-height: 330px;
	}

	#headerImageDisplay{
		max-height: 370px;
		max-width: 1020px;
	}

	.modern-radio-container {
		display: flex;
		cursor: pointer;
		user-select: none;
	}
	
	.radio-outer-circle {
		width: 18px;
		height: 18px;
		min-width: 18px;
		min-height: 18px;
		border: 2px solid #70898D;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 12px;
		margin-right: 12px;
		transition: all 0.1s linear;
	}

	.selected .radio-outer-circle{
		background-color: #70898D;
	}

	
	.radio-inner-circle {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: #B4CCD0;
		transition: all 0.1s linear;
	}
	.selected{
		background-color: #B4CCD0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.unselected {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
	}
	.unselected-circle {
		width: 0;
		height: 0;
	}
	.helper-text {
		color: #000;
		padding-right: 8px;
	}

	.customCol{
		margin-top: 1.5rem;
		border: 1px solid #70898D;
		color: #70898D !important;
		background-color: #fff;
		font-weight: 300;
		letter-spacing: 1px;
		padding-left: 2em;
		padding-right: 2em;
	}
	.customCol:hover{
		color: #fff !important;
		background-color: #70898D !important;
	}

	.sample-col{
		height: 20px;
	}

	.modal{	
		.modal-lg{
			width: 90%;
			max-width: 1200px;
		}

		.modal-header{
			font-size: 15px;
			font-weight: 500;
			letter-spacing: 2px;
			text-transform: uppercase;
			color: #000;
			.btn-primary{
				border: none;
				color: #fff !important;
				background-color: #70898D;
				font-weight: 300;
				letter-spacing: 1px;
				padding-left: 2em;
				padding-right: 2em;
			}
			.btn-primary:hover{
				color: #70898D !important;
				background-color: #fff !important;
				border: 1px solid #70898D !important;
			}

			.btn-secondary{
				background-color: #fff;
				color: #70898D !important;
				border: none;
				text-transform: uppercase;
				font-weight: 300;
				letter-spacing: 1px;
			}
			.btn-secondary:hover{
				color: #fff !important;
				background-color: #70898D !important;
			}
		}
		.colour-selector{
			border: 1px solid #E6E6E6;
			padding: 1em;
			width: 12rem;
			max-width: 90%;
			cursor: pointer;
			&.highlighted{
				box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			}
			h6{
				font-size: 10px;
				font-weight: 500;
				letter-spacing: 2px;
				text-transform: uppercase;
				color: #000;
			}
			.main-col{
				height: 80px;
			}
			.secondary-col{
				height: 40px;
			}

			.partner-form{
				input{
					height: 50px;
					color: #445C60;
					margin-bottom: 0;
				}
			}
		}
		.color-slider {
			.w-color-saturation{
				width: 100% !important;
			}
		}
		
		.w-color-interactive{
			margin: auto;
		}


	
	}

	.bespoke-partner-modal{
		a{
			color: #70898D
		}
	}

	.example-container{
		height: 100%;
		max-width: 300px;
		padding-bottom: 2rem;
		background-color: #E6E6E6;
		min-height: 400px;
		.example-header{
			height: 30%;
		}

		.example-products{
			height: 70%;
		}
		.example-product{
			height: 90%;
			margin-left: 2px;
			margin-right: 2px;
			.col-12{
				border-bottom: 1px solid #E6E6E6;
			}
			.example-banner{
				height: 25%;
			}
			.example-price{
				height: 20%;	
				padding: 0
			}
			.example-info{
				height: 50%;	
				
			}
			.example-buttons{
				button{
					border: none;
				}
			}
		}
	}

	.baseTests{
		&.small {
			background: transparent!important;
			box-shadow: none!important;
			border: 0px!important;
			padding-top:0px!important;
		}
		&.bespoke-test{
			background: #f3f3f3;
			.border-bottom-black{
				border-bottom: 2px solid #DEE2E6;
			}
		}

		.add-bespoke{
			color: #FAA240
		}

		border: 1px solid #E6E6E6;
    background: #fff ;
		color: black;
		height: 100%;
    h2{
      font-weight: 400;
      font-size: 13px;
    }
    .price {
      font-size: 25px;
      font-weight: 400;
      span{
        font-size: 15px;
      }
      .per-test{
        color: #484848;
        font-size: 12px;
        float: right;
        font-weight: 100;

      }
    }
    .btn {
      color: #FAA240;
      border: 1px solid #FAA240;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 14px;
      background:none;
			border-radius: 30px !important;
      &:hover {
        background: #FAA240!important;
      }
		}

		.btn.added {
			background: #FAA240!important;
      border: 1px solid #FAA240;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 12px;
      background:none;
			color: #fff;
		}

		.btn-outline{
			cursor: pointer;
			font-weight: 700;
			border: 1px solid #557479;
			border-radius: 30px !important;
			margin: 0 auto;
			font-size: 12px;
			color: #557479;
			background-color: transparent;
			text-transform: uppercase;
			padding: 0.48rem 0.95rem;
			transition: 0.2s all ease;

			&:hover{
				color: #47B27A;
				border: 1px solid #47B27A;
				background-color: none !important;
			}
		}

		.btn-delete{
			font-weight: 400;
			border: 1px solid red;
			border-radius: 30px !important;
			font-size: 12px;
			color: red;
			background-color: none;
			text-transform: uppercase;
			padding: 0.48rem 0.95rem;
			cursor: pointer;
			letter-spacing: 1px;
			transition: 0.2s all ease;

			&:hover{
				color: white;
				border: 1px solid red;
				background-color: red !important;
			}

			svg{
				width: 15px;
			}
		}

    .fix-to-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .test-info{
			margin-bottom: 200px;
			position: relative;
			overflow: hidden;
    }
		.card-header {
			border-radius: 25px !important;
			border-bottom: none;
			a {
				font-size: 10px;
				letter-spacing: 0.1em;
				color: #000!important;
				font-weight: 500;
				text-transform: uppercase;
			}
		}
		.card-body{
			background: none !important;
			.tubeColourId1{
				color: black;
			}
		}
		
		.border-bottom-grey{
			border-bottom: 1px solid #EDEFF4;
		}
		.card-body {
			background:none;
		}
		
		.marker_counter {
			font-weight:300;
			color: #47B27A;
			font-size: 10px;
			background: url(/img/biomarkerCounter.svg) bottom no-repeat;
			background-size: contain;
			padding: 12px 8px 5px 8px;
			letter-spacing: 0.1px;
			margin-right: 10px;
			vertical-align: sub;
		}

		.top-banner {
			color: #fff;
			font-size: 10px;
			font-weight: 500;
			letter-spacing: 1px;
			background-color: #9398A0; 
			&.phlebotomyOnly{
			background-color: #C33131; 
			}
		}
		
		.notCurrentTest{
			margin-top: 21px;
		}
		.w-90{
			width: 90% !important;
		}
		&.selected {
			/* border: 1px solid #db5a3e; */
			box-shadow: 0 0 10px #e2e2e2;
			padding-bottom: 0;
			.btn{
				color: #fff !important;
				background: #FAA240!important;
			}
			
		}
	}
	.searchForm { 
		input{
			border-radius: 33px;
			background: transparent;
			border: 1px solid #70898D;
			padding-left: 30px;
			color: #666; 
		}
		svg{
			position: absolute;
			color: #70898D;
	
		}
	
		svg.search-lg{
			top: 29px;
			left: 27px;
			
		}
	}
		.searchForm input::placeholder{
			color: #70898D;
			font-size: 15px;
			font-weight: 200;
		}
		
		.test-list{

			.btn {
				color: #FAA240;
				border: 1px solid #FAA240;
				text-transform: uppercase;
				font-weight: 400;
				font-size: 14px;
				background:none;
				margin-right: 0;
				letter-spacing: 1px;
				padding-left: 2em;
				padding-right: 2em;
				&:hover {
					background: #FAA240!important;
				}
			}
			.price{
				font-size: 17px;
			}

			@media only screen and (max-width: 768px) {
				.price{
					font-size: 12px !important;
				}
			}

		}
		.old-price {
			font-size: 12px;
			color: red;
			text-decoration: line-through;
		}

		.page-error{
			color: red;
			text-align: center;
		}
		.setup-complete{

			.setup-icon{
				height: 50%;
				margin-top: 3em;
				margin-left: 15px;
			}

			.p-relative{
				position: relative;
			}

			.fix-to-bottom{
				position: absolute;
				bottom: -35px;
				left: 60px;
			}

			svg{
				color: #47B27A;
				font-size: 30px;
			}
			h1{
				letter-spacing: 2px;
			}

			.box{
				position: relative;
				width:  100%;
				padding-bottom: 100%;
				margin: auto;
				background-size: cover;
				background-repeat: no-repeat;
				

				&.glow-one{
					background-image: url(../img/glow1.png);
					background-position: right -3em bottom -1em;
				}
				&.glow-two{
					background-image: url(../img/glow2.png);
					background-position: right -7.3em bottom 0em;
				}
				&.glow-three{
					background-image: url(../img/glow3.png);
					background-position: right 0em bottom -3.5em;
				}
				
				
				.white-circle{
					position: absolute;
					height: 55%;
					width: 55%;
					border-radius: 50%;
					top: 26%;
					left: 26%;
					background-color: #fff;
					box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

				}
			}

			.btn{
				width: 70%;
				background-color: #70898D;
				border: #70898D;
			}
		}

		.box-disabled{
			width: 100%;
			height: 250%;
			position: absolute;
			z-index: 9;
			top: 120px;
			opacity: 0;
		}

		@media (max-width: 768px){
			.box-disabled{
				height: 315%;
			}
		}
	}

	.termsAndConditions{
		margin-top: -65px !important;
	}
	
	.bespokeNormalPrice{
		color: red;
		text-decoration: line-through;
		text-align: end;
	}
	
	body #partnerSignIn{
		background-color: #fff
	}

	#partnerSignIn{
		background: url(../img/how_it_works.png) no-repeat center center fixed; 
		-webkit-background-size: cover;
		-moz-background-size: cover;
		-o-background-size: cover;
		background-size: cover;
		min-height: 100vh;
	}
	.signIn-header{
		font-size: 40px;
		font-weight: 100;
		letter-spacing: 2px;
		color: #3E3C3A !important;
	}

	.signin-form{
		background-color: #fff;
		border-radius: 30px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

		h6{
			font-weight: 500;
		}
		
		.signin-form-footer{
			a, span{
				color: #70898D;
				font-size: 15px;
				&:hover{
					color: #536669 !important;
				}
			}
			button{
				background-color: #70898D;
				color: white !important;
				letter-spacing: 1px;
				font-weight: 500;
				border: none;
				border-radius: 30px !important;
				&:hover{
					background-color: #536669 !important;
				}
			}
		}
	}

	.signIn-footer{
		text-align: center;
		img{
			max-width: 278px;
		}
	}

	.forgotten-form{
		background-color: #fff;
		border-radius: 30px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		color:#777777;

		h1{
			color:#777777;
		}

		.floating-label{
			left: 12px !important;
		}

		input{
			margin-bottom: 15px !important;
			padding-bottom: 10px;
		}

		button{
			background-color: #70898D !important;
			color: white !important;
			letter-spacing: 1px;
			font-weight: 500;
			border: none;
			text-transform: uppercase;
			font-size: 15px;
			line-height: 1.5;
			width: 100%;
			height: 50px;
			border-radius: 25px;
			justify-content: center;
			align-items: center;
			padding: 0 25px;
		}
	}
	.dashboard-header{
		background-color: #F6F6F6;
		border-bottom: 1px solid #D8D8D9;
	}

.dashboard-overview{
	.month-dropdown{
		background: none;
		color: #70898D;
		border: 1px solid #70898D;
		border-radius: 8px !important;
		font-weight: 400;
	}

	.month-dropdown:hover{
		color: #E3EBEF !important;
		background: #70898D !important;
	}

	.month-dropdown:after{
		content: none 
	}

	.dropdown-menu{
		background-color: #fff;
	}

	.dropdown-item{
		text-align: start;
		background-color: #fff;
		color: #707070 !important;
		font-weight: 500;
		margin-top: 0.5em;
		margin-bottom: 0.5em;
		padding-left: 1em;

		&:hover{
			background: #B4CCD048 !important;
		}
	}
}

.partner-dashboard{
	padding-bottom: 50px;

	.client-list{
		max-height: 400px;
	}

	a{
		color: #707070 !important;
	}

	.commission-tabs{
		li{
			text-align: center;
			width: 50%;
			.nav-link{
				width: 100%;
				color: #70898D;
				font-weight: 500;
				font-size: 15px;
				text-transform: uppercase;
			}
		}
	}

	.commission-costs{
		border-radius: 5px !important;
	}
	
	.white-block{
		padding: 1rem;
		background-color: #fff;
		border: 1px solid #D8D8D9;
		border-radius: 10px;
		height: 100%;
		.section-header{
			text-transform: uppercase;
			font-weight: 500;
			font-size: 14px;
			color: #000;
		}
		
		.secondary-header{
			text-transform: uppercase;
			font-weight: 400;
			font-size: 12px;
			color: #707070;
			text-align: right;
		}
	}
	.percentage-display{
		background-color: #F6F6F6;
		margin-left: 0;
		margin-right: 0;
		.orders-total{
			font-family: 'Helvetica';
			color: #000;
			font-size: 35px;
			font-weight: 500;
		}
		.orders-change{
			font-size: 30px;
			color: #D51D2E;
			&.percentage-up{
				color: #53B881
			}
			&.percentage-zero{
				color: #777777
			}
		}
		p{
			font-size: 8px;
			font-weight: 500;
			text-transform: uppercase;
		}
	}
	

	.test-results-overview{
		.filter, .resultFilter{
			border: 1px solid #70898D;
			background-color: #F6F6F6;
			color: #70898D;
			border-radius: 10px !important;
			text-transform: none;
			float: left;
			font-weight: 400;
			&:hover{
				border: 1px solid #F6F6F6;
				background-color: #70898D !important;
				color: #F6F6F6;
			}
		}

		.latestResultsArea{
			border: none;
			box-shadow: none;
			.chart-element{
				cursor: pointer;
				height: 200px;
				transition: background-color 0.2s ease;
				&:hover{
					background-color: #70898d25;
				}
			}
		}

		.resultFilter{
			background: none;
			color: #70898D;
			border: 1px solid #70898D;
			border-radius: 8px !important;
			font-weight: 400;
		}

	}

	#resultsModal{
		.modal-content{
			background-color: #F6F6F6;
		}

		.modal-header{
			border-bottom: none;
			svg{
				width: 70px;
				height: 70px;

				&.drop-red{
					fill: red
				}
				&.drop-orange{
					fill: #FC8E1F;
				}
				&.drop-green{
					fill: #4ab724;
				}
			}

			path{
				transform: translateY(30px) rotate(-45deg)
			}

			text::after{
				content: "%";
				font-size: 10px;
			}

			foreignObject{
				color: white;
				x: 25%;
				y: 38%;
				text-align: center;
				width: 32px;
				height: 15px;
			}

			h5{
				letter-spacing: 1px;
			}
		}

		.modal-body{
			padding-top: 0px;

			table{
				min-width: 1106px;
				border-radius: 10px;
				border-collapse: separate;
				border-spacing: 0px;
				thead{
					tr:first-child{
						border-bottom: 1px solid transparent;
					}

					tr:last-child{
						td{
							text-transform: uppercase;
							color: #445C60;
							font-weight: 500;
							padding-bottom: 5px;
							vertical-align: bottom;
							letter-spacing: 1px;
						}

						td:first-child{
							width: 135px;
						}
					}
				}

				tbody{
					td{
						vertical-align: top;
					}

					tr:first-child:hover td{
						background-color: red !important;
					}

					td:not(:first-child){
						background-color: #F6F6F6;
						background-clip: content-box, padding-box;
						padding: 7px 1px;

					}

					td > div{
						padding: 10px
					}

					a{
						color: #70898D !important;
						text-decoration: underline !important;
					}

					.order-ref{
						border-radius: 5px;
						border: 1px solid #9FB9BD;
						color: #70898D;
						display: flex;
						width: fit-content;
					}

					.biomarkers-list, .biomarkers-results{
						padding: 0px !important;
						div.biomarker-box, div.biomarker-result-box{
							padding: 10px;
							margin-bottom: 0px;
							&:not(:last-child){
								border-bottom: 2px solid white;
							}
						}

						div.biomarker-box{
							padding: 20.4px 10px;
						}
					}

					.biomarkers-results{
						.dot{
							position: relative;
							width: 15px;
							height: 15px;
							border-radius: 15px;
							margin-top: 2px;
							&.red{
								background: red;
							}
							&.orange{
								background: #FC8E1F;
							}
							&.green{
								background: #4ab724;
							}
						}

						.biomarker-value{
							padding-left: 10px;
							font-size: 16px;
							font-weight: 500;
						}

						small{
							font-weight: 500;
						}
					}

					td:last-child{
						padding-right: 17px;
					}
				}
			}
		}
	}
	.clientsLastTested{
		overflow-y: scroll;
	}

	.topProducts, .clientsLastTested{
		.percentage{
			white-space: nowrap;
			position:relative;
			color: #D51D2E;
			font-size: 17px;
			&::before{
				position:absolute;
				text-align: start;
				top: -2px;
				left: -13px;
				font-family: "Font Awesome 6 Pro";
				color: #D51D2E;
				font-weight: 400;
				content: "\f309"
			}

			&.percentage-up{
				color:#53B881;
				&::before{
					position:absolute;
					text-align: start;
					top: -2px;
					left: -13px;
					font-family: "Font Awesome 6 Pro";
					color: #53B881;
					font-weight: 400;
					content: "\f30c"
				}
			}
			&.percentage-zero{
				color: #666666;
				&::before{
					content: ""
				}
			}
		}
		.noOfOrders{
			white-space: nowrap;
			margin: 0px 10px 0px 0px;
			span{
				font-size: 20px;
				font-weight: 500;
			}
		}
		.daysFromLastTest{
			color: #D51D2E;
			font-size: 15px;
			text-align: right;
			&.percentage-up{
				color:#53B881;
			}
			span{
				font-size: 18px;
				font-weight: 500;
			}
		}

		.cursor-auto{
			cursor: auto !important;
		}
	}
	.customerSplit{
		.nav-link{
			text-align: center;
			text-transform: uppercase;
			color: #70898D;
			&.active{
				border: none;
				border-bottom: 3px solid #70898D;
				color: #000;

			}
		}
		.nav-tabs .nav-link{
			border-top: none;
			border-left: none;
			border-right: none;
		}
	}

	.helpAndSupport {
		background-color: #B4CCD0;
		border-radius: 10px;
	}

	.analytics{
		background-color: #445C60;
		border-radius: 10px;
		height: 100%;
		max-height: 550px;
		color: #B4CCD0;
		text-align: center;
		.vertical-row{
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		
		.vertical-row div{
			flex: 1;
			width: 100%;
		}
		
		.vertical-row div:last-of-type{
			margin-bottom: 0;
		}

		.carousel-inner{
			border-radius: 10px;
		}

		h1{
			font-size: 90px;
			font-weight: 700;
			span{
				font-weight: 400;
				font-size: 50px;
				vertical-align: 30px;
			}
		}
		p{
			font-size: 15px;
			// color: #fff;
			text-align: center;
		}
		.slick-slider{
			height: 100%;
		}
		.slick-track {
			display: flex;
		}
		.slick-track .slick-slide {
			display: flex;
			height: auto;
		}
		ol li::before{
			content: none;
		}
		.carousel-control-prev, .carousel-control-next{
			align-items: flex-end;
			bottom: 10px;
			color: #B4CCD0;
			svg{
				font-size: 20px;
				border: 1px solid #B4CCD0;
				border-radius: 50%;
				padding: 5px 10px;
			}
			span{
				background: white;
				border-radius: 20px;
			}
		}
		.carousel-indicators li{
			width: 5px;
			height: 5px;
			border-radius: 50%;
		}
	}
}

#partnerFooter{
	margin-bottom: -5px;
	margin-left: 0px;
	margin-right: 0px;
	width: 100%;
	background-color: #F0F2F3;
	color: #000;
	font-size: 12px;
	font-weight: 500;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	border-top: 1px solid #D8D8D9;

	p{
		margin: 0;
		text-align: center;
		padding-bottom: 30px;
	}

	@media (max-width: 768px){
		p:last-of-type{
			margin-bottom: 20px;
		}
	}

	img{
		padding: 40px 0;
		width: 200px;
		height: 100%;
	}
}
	
#imageslibrary{
	z-index: 9999999;
	.modal-dialog {
    z-index: 9999999;
	}
	

  .libraryImages{
    display: flex;
    flex-flow: row wrap;
    text-align: center;

    .form-check-input{
      border-color: #70898D;
			&:checked{
				background-color: #70898D;
			}
    }

		.box{
			border: 1px solid #e2e2e2;
			border-radius: 5px;

			div{
				border-bottom-left-radius: 3px;
				border-bottom-right-radius: 3px;
			}
		}

		.selected{
			box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
		}
  }
}

#partnerSettings{
	.modal-content{
		background-color: #f6f6f6;
	}

	.modal-header{
		border-bottom: none;
	}

	.modal-footer{
		background-color: white;
	}
}

.partnership{
	--blue: #23B7B7;
	--white: #ffffff;
  --grey: #333333;
  --greyLight: #e0e0e0;
	
	margin-bottom: 3rem;
	
	h1{
		font-size: 40px;
		font-weight: bold;
	}

	h2{
		font-size: 30px;
	}

	h3{
		font-size: 20px;
	}

	p{
		font-size: 14px;
		line-height: 24px;
	}

	input{
		color: #666;
	}

	input:disabled{
		color: #797979;
	}

	input::placeholder{
		color: #797979;
	}

	.bg-blue{
		background-color: var(--blue);
	}

	section{
		position: relative;

		.geometricSVGLeft{
			position: absolute;
			left: -300px
		}

		.geometricSVGRight{
			position: absolute;
			right: -300px
		}

		.table{
			font-size: 14px;
			color: #666;

			th, td{
				vertical-align: middle;
				a{
					font-size: 12px;
				}
			}
		}
	}

	.btn-outline{
		background: transparent;
		color: var(--blue);
	}

	.progress{
		background-color: white;

		.progress-bar{
			background-color: var(--blue);
		}
	}

	.header{
		display: flex;
		flex: 1 1 auto;
		flex-flow: column wrap;
		align-items: center;
		p{
			text-align: center;
		}
	}

	.body{
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		flex: 1 1 auto;

		.vertical-align-top{
			vertical-align: top;
		}

		.vertical-align-middle{
			vertical-align: middle;
		}

		.partnerPassword{
			position: relative;

			svg{
				cursor: pointer;
				padding: 1rem;
				position: absolute;
				top: 23px;
				right: 20px;
				z-index: 1;
			}
		}

		.storeWebAddress {
			display: flex;
			justify-content: start;
			align-items: center;
			position: relative;

			span{
				font-size: 2rem;
			}

			button{
				position: absolute;
				padding: 1rem;
				right: -10px;
			}
		}

		.logoUpload{
			display: flex;
			justify-content: center;
			padding: 10px 0;
			
			.logo{
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				padding: 10px 0;
				border: 1px solid var(--blue);
				border-radius: 3px;
				svg{
					width: 100%;
					height: 150px;
				}
			}
			.info{
				display: flex;
				justify-content: center;
				align-items: center;
				flex-flow: column;
				font-size: 10px;
			}
			.btn{
				margin-top: 10px;
			}
		}

		.storePreview{
			display: flex;
			flex-flow: column nowrap;
			border-radius: 30px;
			padding: 10px;
			border: 2px dashed var(--blue);
			z-index: 0;

			&::after{
				content: '';
				position: absolute;
				bottom: 70px;
				left: calc(50% - 30px);
				border-bottom: 2px dashed var(--blue);
				border-right: 2px dashed var(--blue);
				width: 60px;
				height: 60px;
				transform: rotate(45deg);
				z-index: -1;
				background-color: #F0F2F3;
			}

			&::before{
				content: '';
				position: absolute;
				bottom: 73px;
				left: calc(50% - 30px);
				width: 60px;
				height: 60px;
				transform: rotate(45deg);
				z-index: 1;
				background-color: #F0F2F3;
			}

			.storeTheme{
				position: relative;
				display: flex;
				width: 100%;
				flex-flow: row wrap;

				input[type='radio']{
					transform: scale(1.6); 
					padding: 10px;
				}				
			}

			.colour-scheme{
				display: flex;
				width: 100%;
				justify-content: center;
				align-items: center;
				flex-flow: row wrap;

				.colour-scheme-options{
					display: flex;
					flex-flow: row wrap;
					justify-content: center;
					width: 100%;
					border: 1px solid #666;

					div{
						flex: 1 1 auto;
						height: 25px;
					}
				}

				input[type='radio']{
					margin: 5px auto;
					transform: scale(1.6); 
				}

			}
		}

		

		.optionsTests{
			background-color: var(--greyLight);
			padding: 10px;
			margin: 10px 0;
			.opt-card{
				width: 100%;
				background-color: white;
				margin: 5px 0;
			} 
		}
	}

	.card{
		display: flex;
		width: 100%;
		height: 100%;
		flex-flow: column;
		flex: 1 1 auto;
		padding: 0;
		margin: 0.5rem 0;

		.card__subscription{
			background-color: var(--grey);
			font-size: 12px;
			letter-spacing: 0.3px;
			color: var(--white);
			padding: 3px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.card__header{
			display: flex;
			align-items: center;
			flex-flow: row nowrap;
			padding: 0.3rem 1rem 0.8rem 1rem;
			border-bottom: 1px solid var(--greyLight);

			.card__header-title{
				flex: 1 1 auto;
				font-size: 1rem;
				font-weight: 700;
			}

			.card__header-price{
				position: relative;
				font-size: 2rem;

				span:nth-child(1){ 
					font-size: 1rem;
				}

				span:nth-child(2){ 
					position: absolute;
					font-size: 0.7rem;
					font-weight: normal;
					width: 100%;
					bottom: -10px;
					left: 0;
					text-align: right;
				}
			}
		}

		.card__body{
			display: flex;
			flex-flow: row wrap;
			flex: 1 1 auto;
			padding: 1rem;
			text-align: center;
			border-bottom: 1px solid var(--greyLight);

			.card__body-biomarkers{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 1rem 0;
				font-weight: 700;

				span{
					padding-left: 5px;
				}
			}

			.card__body-intro{
				padding: 1rem 0;
			}

			.card__body-info{
				display: flex;
				font-size: 10px;
				font-weight: 700;
				padding: 1rem 0 0 0;

				div{
					padding-bottom: 5px;
					justify-content: center;
					align-items: center;
					width: 100%;
				}
			}
		}

		.card__footer{
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-around;
			padding: 1rem;

			.btn-primary{
				background: var(--orange);
				border: 1px solid var(--orange);
				&:hover{
					color: #666 !important;
				}
			}

			.btn-secondary{
				color: var(--black);
				background: var(--white);
				border: 0;
			}
		}
	}

	.listTestsSelected{
		background-color: var(--greyLight);
		padding: 10px;
		margin: 10px 0;

		.testSelected{
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			background-color: white;
			padding: 5px;

			.title{
				flex: 1;
				a{font-size: 12px;}
			}

			.price{
				text-align: right;
				span{
					font-size: 10px;
					text-decoration:line-through;
				}
			}

			.options{
				display: flex;
				flex-flow: column nowrap;
				justify-content: center;
				align-items: center;
				button{
					background-color: transparent;
					border: 0;
					padding: 5px;
					margin: 3px auto;
					i.fa-edit{
						color: green;
					}
					i.delete{
						color: red;
					}
				}
			}
		}

		.totalBespoke{
			line-height: normal;
			font-size: 10px;
			text-align: center;
			margin: 0;
		}
	}

	.footer{
		display: flex;
		justify-content: center;
	}

	.step-1{
		display: flex;
		height: 100%;
		flex-flow: column nowrap;
	}
}

#page-content-wrapper.partnerLayout{
	padding-top: 66px;
	background-color: white;
}

.partnerLayout{
  display: flex !important;
  flex-direction: column;
  min-height: 100vh;
  main{
    flex-grow: 1;
  }
}

.cursor-pointer{
	cursor: pointer !important;
}

.doctorComment-header {
	.doctorImg{
		border-radius: 50%;
		max-width: 80px;
		max-height: 80px;
	}
}

#filterModal{
	.btn{
    font-weight: 500;
    letter-spacing: 1px;
  }

  .btn-primary{
    background: transparent;
    border: 1px solid #70898D;
    color: #70898D !important;

    &:hover{
      background: #70898D !important;
      color: #fff !important;
			border: 1px solid #70898D !important;
    }
  }

  .btn-secondary{
    background: #70898D;
    border: 1px solid #70898D !important;
    color: #fff !important;

    &:hover{
      background: transparent !important;
      color: #70898D !important;
    }
  }

  .btn-add{
    width: 15%;
  }

  .btn-simple{
    border: none !important;
  }

	.form-check-input{
		border-color: #445C60 !important;

		&:checked {
			border-color: #445C60 !important;
			background-color: #445C60 !important;
		}
	}
}

.floating-buttons{
	display: flex;
	padding: 20px 0px;
	background-color: #F0F2F3;
	margin: 0px;
	z-index: 99;
}

.floating-buttons-top{
	position: sticky;
	top: 66px;
}

.floating-buttons-bottom{
	position: sticky;
	bottom: 57.3px;
}

.floating-buttons.border-bottom{
	border-bottom: 1px solid #D8D8D9 !important;
}

.forth-partner-tooltip.tooltip > .tooltip-inner{
	background-color: #445C60;
}

.forth-partner-tooltip .tooltip-arrow::before{
	border-top-color: #445C60 !important;
}

.clipboard-alert{
	position: fixed;
	top: -130px;
	background-color: rgba(175, 223, 135, 0.671);
	z-index: 1;

	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
	color: var(--bs-green);
	font-weight: 400;
	letter-spacing: 1px;
	text-align: center;
	padding: 10px 20px;
	border-radius: 5px;
	box-shadow: 0px 0px 10px 0px rgba(46, 46, 46, 0.61);
	transition: top 0.3s;
}

.clipboard-alert.show{
	top: 130px;
}

.btn-simple{
	border: none !important;
	text-decoration: underline;
	text-transform: uppercase;
	letter-spacing: 0.5px;
	font-weight: 400;
	background-color: transparent;
	color: #70898D !important;

	&:hover{
		background-color: transparent !important;
		color: #445C60 !important;
	}
}

	.link-simple{
		border: none !important;
		letter-spacing: 0.5px;
		font-weight: 400;
		background-color: transparent;
		color: #70898D !important;
		font-size: 12px;
	
		&:hover{
			background-color: transparent !important;
			color: #445C60 !important;
			text-decoration: underline;
		}
		&.bespoke-link {
			font-size: 13px;
			font-weight: 500;
		}
	}

	.modalStepOne{

		.border-right{
			@media (min-width:720px) {
				border-right: 1px solid #e2e2e2;
				border-bottom: none;
			}
			@media (max-width:720px) {
				border-bottom: 1px solid #e2e2e2;
				border-right: none;
				padding-bottom: 2em;
			}
		}

		.base-section {
			.markers{
				max-height: 25em;
				overflow-y: auto;
			}
			.marker{
				padding: 0.5em;
				background: #e2e2e2;
				border-radius: 25px;
				font-size: 11px;
			}
			.test-price{
				font-weight: 500;
				font-size: 30px;
			}
			h5 {
				font-weight: 200;
				font-size: 15px;
				margin: 0;
			}
			.biomarkers-header{
				font-weight: 500;
				font-size: 15px;
				margin: 0;
			}
		}

		.bespoke-section {
			.bespoke-test-icon{
				height: 6em;
			}
			h2 {
				font-weight: 500;
				font-size: 25px;
				margin: 0;
			}
			h5 {
				font-weight: 200;
				font-size: 15px;
				margin: 0;
			}
		}


	}
	.buy-partner-test{
		.voucher-error {
			color: red;
			font-weight: 500;
		}
	}

	.client-success{
		svg{
			font-size: 30px;
			margin-top: 1em;
			margin-bottom: 1em;
		}
	}