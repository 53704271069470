/**
 * Boostrap Variables / Mixins
 */

$primary: #3E3E3E;
$secondary: #222731;
$tertiary: #CF4520;

$font-family-base: 'Lucida Grande', Arial, sans-serif;

$headings-font-family: 'Lucida Grande', Arial, sans-serif;

$btn-border-radius: 0px;

$input-color: #FFFFFF;
$input-placeholder-color: #FFFFFF;
$input-bg: #43474F;
$input-border-radius: 5px;

// $component-active-color: #43474F;
$component-active-bg: #43474F;

// AGP color
$apg-padding-lr: 5em;

$theme-colors: (
  "brown-100": #faf5f3,
  "brown-200": #f4ebe7,
  "brown-300": #f5ece7,
  "brown-400": #eee4df,
  "brown-500": #e7ddd7,
  "brown-600": #dbcdc6,
  "brown-700": #d1c3bc,
  "brown-800": #af9e95,
  "brown-900": #8a7f78,
  "font-accent-color": #7a6e68,
);
$custom-colors: (
  "red": #d02251,
  "red-100": #ffedf6,
  "red-300": #f1bcca,
  "red-800": #a2386c,
  "red-900": #980029,
  "pink-100": #fdf3f6,
  "pink-200": #f0e6e9,
  "pink-300": #d6c8cd,
  "pink-400": #a38c94,
  "pink-500": #f78bc1,
  "green": #51ddbb,
  "green-100": #d4f4f2,
  "green-400": #b4df6f,
  "green-500": #4ba78c,
  "aquamarine": #36f9fc,
  "yellow": #fdc135,
  "yellow-100": #fee6ac,
  "yellow-700": #f4954e,
  "warning": #fdc135,
  "success": #51ddbb,
  "success-darker": #287360,
  "danger": #d02251,
  "purple": #756dcd,
  "purple-100": #f9f7ff,
  "purple-200": #f2efff,
  "purple-300": #c3bffa,
  "purple-500": #987bff,
  "purple-800": #5e5c7e,
  "purple-900": #53506e,
  "plum": #76739d,
  "blue": #1a48e9,
  "grey": #3e3c3a,
  "grey-100": #edeff4,
  "grey-200": #d8dadf,
  "grey-300": #c9cbd0,
  "gold-300": #c6b07a,
  "gold-400": #aa9667,
  "gold-500": #c69100,
  "gold-900": #897851,
  "brown-500": #726267,
  "login-link-color": #3e3c3a,
  "input-placeholder-color": #8c7f77,
  "input-icon-color": #616466,
  "input-disabled-color": #e9ecef,
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary
);
// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

@mixin underline() {
  position: relative;

  &:after {
    height: 5px;
    width: 75px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #FFFFFF;
    content: '';
  }
}

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1141px,
  // xxl: 1920px,
);

// Blue                                   rgba(0, 20, 137, 1)     #001489
// Yellow                                 rgba(255, 209, 0, 1)    #FFD100
// Orange                                 rgba(207, 69, 32, 1)    #CF4520
// Papillon Purple                        rgba(160, 94, 181, 1)   #A05EB5
// InsuLinx (US)
// Navigator
// Precision Blue
// Optium Blue
// Precision/Optium Ketone
// Precision H/Optium H
// Precision Pro
// Precision Neo H / Optium Neo H
// Precision / Optium Keytone (Hospital)
