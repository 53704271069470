.container {
  h1, h2 {
    font-weight: 500;
  }
}

.logo {
  max-width: 94px !important;
}

.nurseBg{
  position: absolute;
  bottom: 0;
  right: 50%;
  max-width: 330px;
  width: 100%;
  transform: translateX(50%);
  aspect-ratio: 1;
  background: linear-gradient(45deg, var(--profile-gradient-color-2), var(--profile-gradient-color-1) 70%);
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  animation: fadeIn 0.2s linear forwards;
}

.isolate {
  isolation: isolate;
}

@keyframes fadeIn {
  100%{
    opacity: 1;
  }
}