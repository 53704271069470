.container {
  color: var(--font-color);
  width: 100%;
  justify-content: center;
  align-items: center;
  h1, h2, h3, h4, h5, h6{
    font-weight: 500;
  }

  p{
    font-weight: 400;
  }
}

.header {
  padding: 30px 30px;
  color: white;
  background-image: linear-gradient(var(--primary-color) 70%, #FFFFFF 30%);
  img{
    max-width: 140px;
  }
  &.failure {
    background: var(--primary-color);
    background-image:none;
  }
}

.blueArea {
  color: black;
  background: white;
  svg{
    color: var(--primary-color);
  }
}

@media screen and (max-width: 768px){
  .blueArea {
    background-color: var(--primary-color);
    color: white;
    border-radius: 10px;
    svg{
      color: white;
    }
  }
}



.imgLargeScreen {
  max-width: 280px;
}

.box {
  background-color: var(--checkout-box-bg-color);
  color: var(--font-color);
  border-radius: 10px;
  padding: 15px;
}

.appSection {
  p {
    color: var(--black)
  }
}

.android {
  max-width: 132px;
}

.ios{
  max-width: 120px;
}