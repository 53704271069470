.markersIncluded {
  background: #F5ECE7;
  border-radius:10px;
  padding: 30px;
  padding-top: 170px;
  margin-top: 80px;
}

body:global(.edge) {
  .markersIncluded {
    background: #f6f6f6;
  }
}
.image{
  position: absolute;
  margin-top: 60px;

  h3{
    margin-top: 10px;
  }

  p{
    margin-bottom: 5px;
  }
}