.phlebotomyPill {
  background:#fff;
  border-radius:10px;
  padding:20px;
  cursor:pointer;
  border: 1px solid #E1E2E6;

  h2, h5 {
  	font-weight:500;
  }

  h5 {
  	font-size: 15px;
  }

  .checkBoxCircle {
  	border: 2px solid #ADADAD;
  	border-radius: 360px;
  	width: 20px;
  	aspect-ratio: 1;
  	display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  .price {
  	font-size:16px;
  	font-weight:300;
  }

  .modalToggler {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    font-weight:400;
  }

  &.active {
  	.checkBoxCircle {
  	  border: 2px solid var(--primary-color);
  	  background: var(--primary-color);
  	  color: #fff;
    }
  }

  &:not(.active):not(.disabled):hover {
  	.checkBoxCircle {
      position: relative;
  	  border: 2px solid #ADADAD!important;
      background: #ADADAD!important;
      color: #fff!important;
      font-size: 14px;

      &::after{
        content: '\f00c';
        position: absolute;
        width: 14px;
        font-family: "Font Awesome 6 Pro";
        right: 50%;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        color: #fff;
        font-size: 14px;
      }
    }
  }

  &.disabled {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.2;
    cursor: not-allowed;
    pointer-events: none;
  }
}
 