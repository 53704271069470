//@extend-elements
//original selectors
//.tabbable .nav-tabs .nav-item.show .nav-link, .tabbable .nav-tabs .nav-link.active
%extend_1 {
	background: var(--result-nav-bg-color-active);
	border: 0;
	color: $white;
	margin: 10px;
	position: relative;
	border-radius: 10px;
}

//original selectors
//[class^="icon-"]::before, [class*=" icon-"]::before
%extend_2 {
	width: auto;
}

//original selectors
//.tabbable .nav-tabs .nav-link:focus, .tabbable .nav-tabs .nav-link:hover
%extend_3 {
	background: var(--result-nav-bg-color-active);
	border: 0;
	color: $white;
	border-radius: 10px;
}


.tabbable {
    
	background: var(--result-nav-bg-color);
	margin-top: -16px;
	text-align: center;
	img {
	  width: 50px;
      padding: 5px;
	}
	a {
		color: $white;
	}
	.nav {
		display: block;
	}
	.nav-tabs {
		border:none;
		overflow: auto;
		white-space: nowrap;
		overflow-y: hidden;
		.nav-link {
			display: inline-block;
			width: 135px;
			height: 125px !important;
			white-space: normal;
			padding-top: 15px;
			transition: background-color 0.15s ease-in-out, border-radius 0.15s ease-in-out;

			&.active {
				@extend %extend_1;
			}

			// &.active::after {
			// 	bottom: 0px;
			// 	height: 4px !important;
			// 	width: 50%;
			// 	left: 0;
			// 	background-color: $white;
			// 	content: '';
			// 	position: absolute;
			// 	margin: 0 auto;
			// 	left: 25%;
			// }

			&:focus {
				@extend %extend_3;
			}

			&:hover {
				@extend %extend_3;
			}
		}
		svg{
			font-size: 30px;
			display: block;
		}
		.jumbotron {
			margin: 0;
			background: none;
			height: 40px;
			padding: 0;
			display: table;
			margin-top: 5px;
			span {
				display: table-cell;
				vertical-align: middle;
			}
		}
		.nav-item.show .nav-link {
			@extend %extend_1;
		}
	}

}
[class^="icon-"]::before {
	@extend %extend_2;
}
[class*=" icon-"]::before {
	@extend %extend_2;
}