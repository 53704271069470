#certificateExport,
#resultExport {
  display:none;
  font-size:2.5mm;
  padding-bottom:50mm;
  div, span {
    animation-delay:initial!important;
    animation: initial!important;
    opacity: 1!important;
    page-break-inside:avoid;
  }
  .animate, .valuePosition {
    animation-delay:initial!important;
    animation: initial!important;
    opacity: 1!important;
  }
  .untested-biomarker .btn {
    display:inline-block;
    float:left;
    font-size:2mm!important;
  } 
  .valuePosition {
   margin-left:-1.6mm;
  }
  .chartValue {
    display:none;
    width:35px!important;
    height:35px;
    padding-top:0px!important;
    svg{
      width: 35px;
      height: 35px;
      line-height: 0;
      font-size: 3mm;
      margin-top: 18px;
    }
  }
  .test-name {
    font-size: 3.2mm;
  }
  .chartContainer {
    .valuePosition:before {
      left:25%;
    }
  } 
  .marker-result, .marker-value {
    font-size:3mm!important;
  }
  .arrow:after {
    display:none;
  }
  .chartKey {
    .orange {
      color: #fc8e0f;
    }
    .green {
      color: #4ab724;
    }
    .red {
      color: #ff0000;
    }
  }

  .categoryPageTitle {
    padding-top: 25px;
    padding-bottom: 25px;
    img {
      font-size:40px;
      color: #5abbb4;
      filter: invert(61%) sepia(75%) saturate(254%) hue-rotate(127deg) brightness(130%) contrast(89%);
      width: 80px;
    }
  }

  #pdfHeader {
    img {
      width:5cm;
    }
    .userDetails {
      margin-top:30px;
    }
    .pdf-name {
      font-size:20px;
    }
  }

}

#certificateExport {
  padding-bottom:5mm;
  margin-bottom: 20mm;
  font-size:4.5mm;
  color: #242021;
  #pdfHeader {
    img {
      margin-top: -20px!important;
    }
  }
  .pdf-name {
    font-size: 16px!important;

    span {
      font-weight:700;
      font-size:20px;
    }

  }

  .label {
    display: flex;
    align-items: center;
    font-size: 10px;
    .value {
      font-size: 14px;
      margin-left: 15px;
      background: #eff2f3;
      padding: 10px 20px;
      border-radius: 360px;
    }
  } 

  .testResult {
    font-size: 40px;
  } 
  .ukasText {
    font-size: 12px;
  }
  .ukasLogo {
    width: 50px;
  }
 
}