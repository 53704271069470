.form-control {
  color: black;
  background-color: white;
}

ol {
  max-width: 500px;
  display: inline-block;
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 40px;
}
ol li {
  margin: 0 0 0.5rem 0;
  counter-increment: my-awesome-counter;
  position: relative;
  margin-top: 2rem;
}
ol li::before {
  content: counter(my-awesome-counter);
  color: var(--primary-color);
  font-size: 0.8rem;
  font-weight: bold;
  position: absolute;
  --size: 25px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 0;
  border-radius: 50%;
  border: 1px solid var(--primary-color-hover);
  text-align: center;
}

.getStarted-questions {
  label {
    display: block;
    // padding: 15px;
    padding-bottom: 0;
    position: relative;
    padding-left: 40px;
  }
  label input {
    display: none;
  }
  label span {
    border: 2px solid #ccc;
    width: 45px;
    height: 45px;
    position: absolute;
    overflow: hidden;
    line-height: 2;
    text-align: center;
    border-radius: 100%;
    font-size: 15pt;
    left: 0;
    top: 50%;
    margin-top: -7.5px;
  }
  input:checked + span {
    background: #23b7b7;
    border-color: #23b7b7;
    color: white;
  }
  input {
    padding: 10px;
    text-align: center;
    background: none;
    border: 1px solid var(--primary-color-hover);
    color: var(--font-color);
  }
  .textInput{
    background: white;
  }

  .scaleInfo {
    font-size: 10px;
    width: 65px;
  }

  .option{
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 2px solid #ccc;
    width: 45px;
    height: 45px;
    padding: 0px !important;
    border-radius: 50%;
    font-size: 25px;
  }

  label.active.option{
    background: var(--primary-color-hover);
    border-color: var(--primary-color-hover);
    color: white;
  }

}


.support {
  background-color: #d8dadb;
}

.test-img {
  width: 100%;
  max-width: 400px;
}

.modal-icon {
  font-size: 20px;
}


strong{
  font-weight: 600;
}


.productImage {
  max-width:150px;
}

.healthDatePick {
  
  input {
    text-align:center;
  }

}
#top{
  position: absolute;

  top: -1000px;
  
}

.radio {
  display: flex ;
}

.radioLabel {
  width: 100%;
}

.radioButton{
 margin-left: 10px;
 margin-right: -20px;
 justify-content: center;
}

.radioOption{
  display: flex !important;
  padding-left: 15px !important;

  input{
    display: inline-block !important;
    margin-right: 10px;
    flex: 0 0 auto;
  }
}
