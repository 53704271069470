body.edge {

	background: var(--bg-color);
	color: var(--font-color);

	#login-page {
		// background-image: -ms-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: -moz-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: -o-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#efb85d),color-stop(100,#da6552));
	  // background-image: -webkit-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: linear-gradient(to bottom,#efb85d 0%,#da6552 100%);
	  height: 100vh;

	  .loginIcon {
			color: var(--primary-color);
		}
	}

	// a:not([href]):not([tabIndex]), a:not([href]):not([tabIndex]), a {
	// 	color: #db5a3e !important;
	// 	transition: color 0.15s ease-in-out;
	// 	&:hover{
	// 		color: white !important;
	// 	}
	// }

	.dropdown-menu a {
	    color: #ffffff;
	}

	.login-page .forgotLink a:hover {
		color: #ffffff;
	}

	.footer-base a {
		color: #ffffff;
	}

	.btn.btn-burger, .btn.btn-burger:hover, .btn.btn-burger:focus {
		border:none !important;
		background:none !important;
	}

	.fullscreenloader {
	  background:url('../img/loadingAnimationEdge.gif') center no-repeat #F0F2F3;
  		background-size: 160px 160px;
	 }


	 .slick-prev:before, .slick-next:before {
	    color: var(--order-box-icon-color) !important;
	}

	.lifeHub h2 span {
		color:#db5a3e;
	}

	.badge-primary {
		background:#000000;
	}


	#next-test-counter svg .heart-path {
		stroke: #f19c42;
	}

	.chart-title h3 {
		color:#db5a3e;
	}

	#tabbed-results .nav-tabs .nav-link, #tabbed-results .nav-tabs .nav-item.show .nav-link {
	    color: #f19c42;
	}

	#tabbed-results .nav-tabs .nav-link.active {
	    color: #db5a3e;
	    border-bottom: 2px solid #db5a3e;
	}

	#tabbed-results .tab-title {
	    color: #db5a3e;
	}

	#your-result-progress .activeResult .chartDate {
		border-radius: 10px;
		background: var(--primary-color);
	}

	#your-result-progress .activeResult li {
	    border: 1px solid transparent !important;
	}

	.icon-line-break svg{
	    border: 1px solid #f19c42;
	    color: #f19c42;
	}

	.icon-line-break:before, .icon-line-break:after {
	    background-color: #f19c42;
	}

	#about-marker h4 svg{
	    color: #f19c42;
	}

	.forthModal {
	 	background: #3c3c3c;

	 	.close {
		  color: #ffffff;
			text-shadow: none;
		}

	 	// .modal-footer .btn {
		//     background: #f19c42;

		//     &:hover {
		//     	color: #db5a3e !important;
		//     }
		// }
	}

	.exportReultsModal label.exportOption {
	    background: #4a4a4a;
	}

	#account-management {

		.nav-tabs {
			background: var(--primary-color);
			
			.nav-link.active,
			.nav-link:hover{
				background: var(--primary-color-hover);
			}
			
			.categorySettingsButton.active, 
			.categorySettingsButton.active:hover {
				background: #f19c42;
				color: #ffffff;
			}
	
			.input-group-append {
				background: #db5a3e;
				border-color: #db5a3e;
			 }
	
			// .radio-group {
			// 		border: 1px solid #f19c45;
			// }
		
			.radio-group input[type=radio]:checked + label {
					background: #f19c45;
			}
		}

		.box{
			background-color: var(--bg-color-lighter);
		}

	}

	.decorated-divider span{
		color: white;

		&::before,
		&::after{
			background-color: white;
		}
	}

	.forthModal .modal-content .samplePosted .react-datepicker__day--selected, 
	.forthModal .modal-content .samplePosted .react-datepicker__day--in-selecting-range, 
	.forthModal .modal-content .samplePosted .react-datepicker__day--in-range {
		background: #db5a3e;
	}

	.modal-content {
		background-color: var(--bg-color);
		.modal-header {
			border-color: var(--footer-hr-color);
		}
	}

	.forthModal .modal-footer .btn.btn-secondary {
	    background: #Ffffff!important;
	    color: #db5a3e !important;
	}

	// .tabbable,
	// .tabbable .nav-tabs .nav-link.active, .tabbable .nav-tabs .nav-item.show .nav-link {
	// 	background:#252525;

	// }

	.tabbable .nav-tabs .nav-link {
		color:#ffffff;
	}

	.tabbable .nav-tabs .nav-link.active {
		color:#db5a3e;
	}

	#category-details .arrow:after, #tabbed-results .arrow:after {

		color:#db5a3e;
	}

	
	#sidebar-wrapper {
		// background-image: -ms-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: -moz-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: -o-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#efb85d),color-stop(100,#da6552));
	  // background-image: -webkit-linear-gradient(top,#efb85d 0%,#da6552 100%);
	  // background-image: linear-gradient(to bottom,#efb85d 0%,#da6552 100%);
		svg{
			fill: var(--primary-color);
		}
	}

	.tabbable .active img,
	.categoryPageTitle img {
	    color: #db5a3e;
	    -webkit-filter: invert(48%) sepia(97%) saturate(2986%) hue-rotate(340deg) brightness(94%) contrast(93%);
	    filter: invert(48%) sepia(97%) saturate(2986%) hue-rotate(340deg) brightness(94%) contrast(93%);;

	}

	#exclusiveOffers {
      .discountCode { 
        color: #db5a3e;
      }
    }

    #category-details .test-name, #tabbed-results .test-name {
	    color: #3b3a3a;
	}

	#category-details .untested-biomarker .btn, #tabbed-results .untested-biomarker .btn {
	    color: #db5a3e;
	}

	#tabbed-results .untested-biomarker p{
		color: black;
	}

	#tabbed-results{
		--grey: #E3ECEF;
	}


	#account-management .orderList .orderOptions .btn {
    	background: #f19c42;
	}

	#account-management .orderList .kitPhoto .kitStatus {
		background:#db5a3e;
	}



	/* CHECKOUT */


	#checkoutArea {
 
    
    .nextBtn, .checkoutButton {
      background: #ec4f32; 
      color:white!important;
    }

	.overlayStyleContainer {

	} 

		header .closeCheckout svg{
			color:#434343;
		}

		.product {

			.information .price {
				color:#db5a3e;
			}


			.clinicArea .phlebotomySelectionBtn .tickCircle {
				border-color:#ec4f32;
			}

			.clinicArea .phlebotomySelectionBtn .tickCircle:before {
				color:#ec4f32;
			}
		}

		.voucherAdded,
		.voucherCodeApplied {
			background:#f06c54;
		}
		.totalAmount {
			background:#434343;
		}


		.bs-wizard > .bs-wizard-step > .bs-wizard-dot,
		.bs-wizard > .bs-wizard-step.disabled .bs-wizard-dot{
			background:#f5f5f5;
		}
		.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
			background:#db5a3e
		}
		.bs-wizard > .bs-wizard-step > .progress > .progress-bar,
		.bs-wizard > .bs-wizard-step .bs-wizard-dot {
			background:#fcb2a2;
		}


		.checkoutBar {
			background:#FF3B00;
		}

	}

	#cartArea #addCustomTest.modal {
		background:#222222;
	}




	/* chat bot */

	#chatArea {
  		
  		background-image: -webkit-gradient(linear,left top,left bottom,color-stop(0,#efb85d),color-stop(100,#da6552));
	    background-image: -webkit-linear-gradient(top,#efb85d,#da6552);
	    background-image: linear-gradient(180deg,#efb85d 0,#da6552);
	    height: 100vh;

	   .overlayStyleContainer {


	  } 


	}


	/* LOGIN PAGE */

	.page.login.cameFromCheckout {

      .btn {
        background: #ec4f32;
        color: white !important;
        
        &:hover {
          background: #db3010;
        }
      }

      .forgotLink a {
        color: #7b7b7b;
      }

    } 
		.close:after {
			color: #db5a3e;
		}
		.progressSlider .tab-title{
			color: #db5a3e;
		}

		thead.thead-forth + tbody{
			color: var(--font-color);
		}

		#accountForm {
			.box{
				background-color: var(--bg-color-lighter);
			}

			.text-muted{
				color: var(--font-color) !important;
			}

			input[type="text"],
			input[type="number"],
  		input[type="password"],
			textarea,
			select{
				background-color: #474747 !important;
				color: var(--font-color);
				font-weight: 300;
				border-color: var(--bg-color);
			}

			.form-select{
				background-blend-mode: color-burn;
			}
		}

		.marker.completedTests .marker-title{
			color: currentColor;
		}		

	.tooltip > .tooltip-inner:not(.forth-partner-tooltip) {
		background-color: var(--accent-color-lighter);
	}
	
	.tooltip.bs-tooltip-top .tooltip-arrow:not(.forth-partner-tooltip)::before {
		border-top-color: var(--accent-color-lighter);
	}

	.box-bg-accent{
		--grey: #4b4a4a;
	}

	#welcome-area .profileNotUpdated{
		background-color: var(--profile-bg-color);
	}
}

