.basketItem {
  prosition:relative;
  .productImage {
    padding: 25px 15px;
    background: #EDEFF4;
  }
  .productImage.productImageSmall {
    padding: 20px 10px;
  }
  .testTitle {
    font-size: 18px;
    font-weight: 500;
  }
  .itemQuantity {
    position: absolute;
    background: #797979;
    color: white;
    font-weight: bold;
    border-radius: 360px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    top: -10px;
    right: 5px;
  }
  .testType {
    display: inline-block;
    vertical-align: middle;
    margin-left: 15px;
    background: #9398A0;
    color: white;
    padding: 5px 10px;
    text-transform: uppercase;
    font-size: 12px;
    &.autoCreditAmount {
      background: #3E4884;
      //background-image: linear-gradient(to right, #f97d56, #ff6c66, #ff5d7b, #fc5292, #ef4eab);
    }
    &.sale {
      background: #DF5E5E;
    }
    &.danger {
      background: var(--red);
    }
  } 
  .testPrice {
    font-size: 28px;
    font-weight: 500;
    .priceIcon {
      font-size: 17px;
    }
    .perTest {
      font-size: 14px;
      color: #9B9B9B;
      margin-left: 10px;
      font-weight: normal;
    }
  }
}