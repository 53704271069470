#partnerOrders, #partnerResults, #partnerClients{
  
  .btn{
    font-weight: 500;
    letter-spacing: 1px;
  }

  .btn-primary{
    background: transparent;
    border: 1px solid #70898D;
    color: #70898D !important;

    &:hover{
      background: #70898D !important;
      color: #fff !important;
      border: 1px solid #70898D !important;
    }
  }

  .btn-secondary{
    background: #70898D;
    border: 1px solid #70898D !important;
    color: #fff !important;

    &:hover{
      background: #445C60 !important;
      border: 1px solid #000 !important;
      color: #fff !important;
    }
  }

  .border-rounded{
    border-radius: 10px !important;
  }

  .orderHeader h2{
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 1px;
  }

}