#filter-wrapper {
  margin-left: 0;
  display: inline-block;
  float: left;
  width: 25vw;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  margin: 0;
  background:white;
  margin-left: -25vw;
  &.toggled {
    margin-left: 0vw;
    height:100%;
  }
}


#basket-wrapper {
  margin-left: 0;
  display: inline-block;
  float: left;
  width: 25vw;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  margin: 0;
  background:white;
  margin-right: -25vw;
  padding-bottom:10vh;
  &.toggled {
    margin-right: 0vw;
    height:100%;
  }

  .container {
    position: relative;
  }

  .product {
    width: 100%;
    border: 1px solid #e7e7e7;
    margin-bottom: 5px;
    position: relative;
    background: #fff;

    .photo {
      width: 100px;
      text-align: center;
      margin: 0 auto;
    }

    .information {
      width: 100%;
      padding: 10px 20px;

      h4 {
        padding: 0;
        margin: 5px 0 5px 0;
      }

      p {
        font-size: 14px;
        line-height: 17px;
        padding: 0;
        margin: 10px 0;
      }

      .price {
        color: #50b3b0;
        display: block;
        font-weight: 700;
      }
    }
  }
  .custom-select {
    border: 1px solid #c3c3c3;
    padding: 8px 8px;
    height: auto;
    font-weight: 300;
    box-shadow: none;
    margin-top:10px;
    font-size: 16px;
  }

  .removeFromCart {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .clinicArea {
    width: 100%;
    padding: 10px;
    background: #fbfbfb;
    border: 1px solid #fff;

    .stage1 {
      padding: 10px;
    }

    .phlebotomySelectionBtn {
      width: 100%;
      display: block;
      padding: 2px 0;
      font-size: 12px;
      text-decoration: none;
      margin-bottom: 15px;
      text-align: left;
     
      .tickCircle {
        border: 2px solid #50b3b0;
        width: 20px;
        height: 20px;
        margin: 0 5px 0 0;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        vertical-align: sub;
        &.checked:before {
          font-family: FontAwesome;
          left: -2px;
          top: 1px;
          position: absolute;
          content: '\f00c';
          width: 20px;
          height: 20px;
          font-size: 10px;
          text-align: center;
          color: #50b3b0;
        }
      }
    }
  }

  .totalContainer {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: center;

    .totalAmount {
      position: fixed;
      bottom: 35px;
      background: #5bbbb7;
      padding: 10px 5vw;
      color: white;
      font-size: 15px;
      width: 25vw;
      height: 35px;
    }
    .checkoutButton { 
      bottom: 0;
      background: #db5a3e;
      padding: 10px 5vw;
      color: white;
      font-size: 15px;
      width: 25vw;
      height: 35px;
      right: 0vw;
    }
  }
}