#guestOptions {
  h3 {
  	font-weight: 500;
  }

  .phlebotomyOption {
  	border: 2px solid #E1E2E6;
  	border-radius: 10px;
  	cursor: pointer;

  	.checkboxContainer {
  	  width: 70px;
  	}

  	.checkBoxCircle {
      border: 2px solid #ADADAD;
      border-radius: 360px;
      width: 20px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
		}
		&:not(.selected):hover .checkBoxCircle{
			position: relative;
  	  border: 2px solid #ADADAD;
      background: #ADADAD;
      color: #fff;
      font-size: 14px;

			&::after{
        content: '\f00c';
        position: absolute;
        width: 14px;
        font-family: "Font Awesome 6 Pro";
        right: 50%;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        color: #fff;
        font-size: 14px;
      }
		}
		.phlebotomyTitle {
				svg {
					font-size: 22px;
				}
		}

		h4 {
			font-weight: 700;
			font-size: 18px;
		}

		&.selected {
  	  border-color: var(--primary-color);
  	  .checkBoxCircle {
        border: 2px solid var(--primary-color);
        background: var(--primary-color);
        color: white; 
	  	}
      svg[class*="fa-user"],
      svg[class*="fa-gift"]{
        color: var(--primary-color);
      }
  	}
  }
}

.modalText{
	font-weight: 400;
}
