.form {
  label {
    font-size: 12px;
    width: 100%;
    display: block;
    font-weight: 500;
    
    &[helpercolor="red"] {
      color: var(--red);
    }
    
    &[helpercolor="green"] {
      color: var(--green);
    }
  }

  .checkboxContainer{
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #e1e2e6;

    &:has(.checkboxInput:checked){
      border-color: var(--primary-color);
    }

    p {
      user-select: none;
    }

    .checkboxInput {
      appearance: none;
      width: 20px;
      aspect-ratio: 1;
      border-radius: 50%;
      border: 1px solid #e1e2e6;
      position: relative;

      &:checked {
        background-color: var(--primary-color);
        border-color: var(--primary-color);

        &::before{
          content: "\f00c";
          font-size: 12px;
          line-height: 0.8;
          color: #fff;
          font-family: "Font Awesome 6 Pro";
          font-weight: 500;

          position: absolute;
          top: 50%;
          right: 50%;
          transform: translate(50%, -50%);
        }
      }
    }
  }
}