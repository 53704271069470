#welcome-area {
	h2 {
		font-size: 20px;
		text-align: center;
		margin: 5px 0;
	}

	.profileArea{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		height: 100%;
		isolation: isolate;
	}

	.profilePicture {
		position: relative;
		border: 3px solid transparent;
		height: 100px;
		width: 100px;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 50%;
		background-size: cover!important;
		margin: 0 auto;
		background-clip: padding-box;
    box-sizing: border-box;
		
		&.Male {
			background-image:url('../img/dpm.jpg');
		}
		&.Female {
			background-image:url('../img/dpf.jpg');
		}

		&:before{
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      margin: -3px;
      border-radius: inherit;
      background: linear-gradient(45deg, var(--profile-gradient-color-1), var(--profile-gradient-color-2));
      z-index: -1;
    }
	}

	.welcomeMessage {
		text-align: center;
		font-size: 18px;
		margin: 5px 0;
	}
	
	.yourGoal {
		text-align: center;
		color: var(--title-color);
		font-size: 14px;
		font-weight: 300;
		
		.goal {
			font-weight: 400;
			display: inline;
			color: var(--font-color)
		}
	}

	.profileDetails {
		margin: 15px 0;
		text-align: center;

		.profileTitle {
			color: var(--title-color);
			font-size: 12px;
			font-weight: 400;
		}

		.profileValue {
			font-size: 16px;
			font-weight: 500;
			span {
				font-weight: 300;
				font-size: 12px;
			}
		}
	}


	.bmiCalculator {
		margin: 15px 0;
		margin-bottom: 5px;
		h4 {
			font-size: 14px;
			position: relative;
			margin-bottom: 15px;
			span {
				background-color: white;
				padding-right: 10px;
			}
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 50px;
				right: 0;
				height: 0.5em;
				border-top: 1px solid #f0f2f3;
			}
		}
		svg{
			font-size: 16px;
		}
	}
	.modal-header{
		align-items: flex-end;
		justify-content: center;
	}
	.bmiLabels {
		color: var(--title-color);
		display: flex;
		overflow: hidden;
		font-size: .75rem;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: .25rem;
		text-align: center;
		font-style: 10px;
		margin-bottom: 30px;
		.bmiValue {
			font-size: 12px;
			color: var(--font-color);
		}
	}

	.orderStatusBox {
		text-align: center;
		padding: 5% 0;
		background-color: var(--order-box-bg-color);
		border-radius: 10px;
		margin: 30px 0;
	}

	.orderMessage {
		font-size: 18px; 
		margin: 0 auto; 
		padding:0 30px;

		svg {
			width: 40px;
			display: block;
			margin: 0 auto;
			fill: none;
			stroke: var(--order-box-icon-color);
			strokeWidth: 4px;
			margin-bottom: 15px;
		}

		svg{
		    width: 40px;
		    display: block;
		    margin: 0 auto;
		    fill: none;
		    color: var(--order-box-icon-color);
		    strokeWidth: 4px;
		    margin-bottom: 15px;
		    font-size: 28px;
		}

		.slick-prev, .slick-next {
		  background:#ffffff!important;
		  &:before {
		    font-size: 30px;
		    line-height: 35px;
		  }
		}
		.slick-prev {
			left:-45px !important;
		}
		.slick-next {
			right:-45px !important;
		}
		p {
			padding-bottom:0;
			margin-bottom:0;
			font-weight: 300;
		}

		.hormoneTestInformation {
			font-size:15px
		}
	}
	.consulationArea {
		margin-top: 30px;
		h3 {
			text-align: center;
			font-size: 15px;
			margin-bottom: 30px;
		}
		.consultantImage {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 360px;
			height: 100px;
			width: 100px;
			background-size: cover!important;
		}
	}
	.row > .border {
		padding-top: 1.75rem;
		padding-bottom: 1.75rem;
		background-color: white;
		border: 1px solid #f0f2f3;
	}

	.kitPhoto {
      height:150px;
      background: var(--order-box-bg-color);
      position:relative;

      &.websiteId1 {
        background:url('../img/BOX_1_Finger_Prick.png') center no-repeat;
        background-size:contain;
      }

      &.websiteId2 {
        background:url('../img/kit-edge.png') center no-repeat;
        background-size:contain;
      }

      .kitStatus {
        position:absolute;
        background: #5abbb4;
        font-size:12px;
        color:#ffffff;
        top:0px;
        right:0px;
        padding:2px 4px;
      }
		}

		.profileNotUpdated{
			border-radius: 10px;
			background-color: var(--bg-color);
		}
}
@media only screen and (max-width: 991px) {
	.chart span {
		margin: 0 36%;
	}
	#category-details .chart {
		padding: 0 0%;
		padding-top: 50px;
		margin-bottom: 60px;
	}
	#latestResults .chart {
		padding: 0 0%;
		margin-top: 60px;
	}
}
@media only screen and (max-width: 767px) {
	.ipadding {
		margin: 0;
	}
	.dropdown-container .dropdown-menu {
		left: -125px;
	}
	.chart span {
		margin: 0 40%;
	}
	#category-details .chart {
		padding-top: 80px;
	}
	#latestResults .chart {
		padding: 0;
		padding-top:40px;
	}
}

.bmiValue {
	&.orange {
		color: var(--yellow);
	}
	&.green {
		color: var(--green);
	}
	&.red {
		color: var(--red);
	}
}

.jumbotron {
	width: 100%;
	background: #fff2f2;
	padding: 15px;
	margin: 30px 0;
	text-align: center;
	.warning {
		color: #ff0000;
		text-transform: uppercase;
	}
	p {
		margin: 5px 0;
		font-size: 12px;
	}
}

.nextTest {
	h2 {
		color: var(--title-color);
		font-weight: 400;
	}
}

.chart {
    margin-top: 30px;
		display: table;
    table-layout: fixed;
    width: 100%;
    max-width: 340px;
    height: auto;
    margin: 0 auto;
    padding: 30px 0 0 0;
    padding-bottom: 0px;
    margin-top: 40px;
	li {
		position: relative;
		display: table-cell;
		vertical-align: bottom;
		height: 170px;
		padding-top:45px
	}
	span {
		margin: 0 auto;
		width:15px;
		display: block;
		background: rgba(209, 236, 250, 0.75);
		animation: draw 1s ease-in-out;
		position: relative;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 360px;
		&.red {
			fill: var(--red);
			background: var(--red);
		}
		&.orange {
			fill: var(--yellow);
			background: var(--yellow);
		}
		&.green {
			fill: var(--green);
			background: var(--green);
		}
	}
	.chartValue {
		position: absolute;
		text-align: center;
		padding-top: 18px;
		font-size: 12px;
		top: -50px;
		left: -17px;
		width: 50px;
		&.green {
			color: var(--green);
		}
		&.orange {
			color: var(--yellow);
		}
		&.red {
			color: var(--red);
		}
		.thinText {
			font-size:10px;
		}
	}
	.chartTitle { 
	  margin-top:10px;
		bottom: -45px;
		width: 100%;
		text-align: center;
		font-size: 10px;
		height: 35px;
		padding: 0 5px;
	}
}

.chart {
	.chartDrop {
		position: absolute;
		top: -57px;
		width: 50px;
		left: -10px;
		margin-left: -50%;

		.chartValue {
			position: absolute!important;
			width: 100%!important;
			text-align: center!important;
			padding-top: 18px!important;
			color: white!important;
			font-size: 12px!important;
			line-height:12px!important;
			top: 0;
			left: 0;
		}
	}
}

@media only screen and (max-width: 576px) {

	
	.markersOutOfRangeSlider {
		.slick-prev {
			bottom: -5px!important;
		}
		.slick-next {
			bottom: -5px!important;
		}
		.slick-dots {
			bottom: -10px!important;
		}
	}
	.improvedMarkersSlider {
		.slick-prev {
			bottom: -5px;
		}
		.slick-next {
			bottom: -5px;
		}
		.slick-dots {
			bottom: 10px !important;
		}
	}
	.chartDrop .chartValue {
		padding-top: 13px!important;
	}
	.chart span {
		margin: 0 auto;
		width:15px;
	}

}

.quoteBlock {
	margin: 30px 0 15px 0;
	.fas {
		color: #cfd5e1;
		font-size: 18px;
	}
}

#next-test-counter {
	width: 150px;
	height: 165px;
	margin: 15px auto;
	position: relative;
	&.overdue {
		height: auto;
		svg {
			fill: #ff0000;
			color: white;
		}
		.dayCounter {
			color: white;
		}
		.small-text {
			font-size: 14px;
			line-height: 10px;
		}
	}
	.dayCounter {
		height: 100%;
		position: absolute;
		width: 100%;
		.counterInner {
			position: relative;
			top: 55%;
			//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
			transform: translateY(-50%);
			text-align: center;
			font-size: 25px;
			line-height: 31px;
			.days {
				font-weight: bold;
				font-size: 45px;

				&.text {
					font-size:18px;
				}
			}
		}
	}
	svg {
		width: 150px;
	}
	svg .heart-path {
	  transition: stroke-dashoffset 850ms;
	  stroke-dasharray: 300;
      stroke-dashoffset: 300;
	  animation: dash  850ms linear forwards;
	}
}

.dueTest {
	h3 {
		font-size: 16px!important;
	}
	.btn {
		margin-top: 20px;
	}
}

@keyframes draw {
	0% {
		height: 0;
	}
}

.progress {
	height: auto;
	background: none;
}

.progress-bar {
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 40px;
	padding: 5px 0;
	font-size: 14px;
	flex-direction: row;

	&.bg-warning {
		background-color: var(--yellow) !important;
		opacity: 0.2;
	}

	&.bg-success {
		background-color: var(--green) !important;
		opacity: 0.2;
	}

	&.bg-danger {
		background-color: var(--red)!important;
		opacity: 0.2;
	}

	&.active {
	  opacity: 1;
	}
}

@media only screen and (min-width: 769px) {
	.bmiModal {
		top: 25%;
		width: 50%;
		max-width: 90%;
	}
	.BMI-modal-body{
		width: 90%;
	}
}

.BMIValue{
	flex: auto;
	line-height: 17px;
}

.slick-prev {
	border: 1px solid #5ABBB44D!important;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 360px!important;
	width: 30px!important;
	height: 30px!important;
	z-index: 99!important;
	left: 5px!important;
	background: none!important;
	border: none !important;

	&:before {
		color: var(--order-box-icon-color)!important;
	}

	&:hover::before {
	  opacity: 1!important;
	}
}

.slick-next {
	border: 1px solid #5ABBB44D!important;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 360px!important;
	width: 30px!important;
	height: 30px!important;
	z-index: 99!important;
	right: 5px!important;
	background:none!important;
	border:none !important;

	&:before {
		color: var(--order-box-icon-color)!important;
	}

	&:hover::before {
	  opacity: 1!important;
	}
}

.improvedMarkersSlider {
	.slick-prev {
		top: initial;
		bottom:  -5px;
	}
	.slick-next {
		top: initial;
		bottom:  -5px;
	}
	.slick-dots {
		bottom: 15px;
	}
}
.markersOutOfRangeSlider {
	.slick-prev {
		top: initial;
		bottom: -5px;
	}
	.slick-next {
		top: initial;
		bottom:  -5px;
	}
	.slick-dots {
	  bottom:  15px;
	}
 }
.improvedMarkerArea, .markersOutOfRangeArea {
	padding-bottom:10px;

	&.noSlider, &.count1 {
	  	padding-bottom:10px;
	}
}

.markerProgressSlider{
	position: relative;

	.guide{
		pointer-events: none;
		position: absolute;
		width: 100%;
		height: 215px;
		padding: 0px 3px 0px 11px;
		z-index: 41;

		div.guide-line{
			width: 100%;
			border-top: 1px dashed var(--guide-line-color);
		}
	}
}

#bioMarkers .improved-markers-chart{
	padding-bottom: 80px;
}

.improved-markers-chart {
	display: table;
	table-layout: fixed;
	width: 100%;
	height: auto;
	margin: 0 auto;
	padding:0;
	padding-bottom: 50px;
	margin-top: 20px;
	overflow-x:hidden;

	li {
		position: relative;
		display: table-cell;
		vertical-align: bottom;
		height: 180px;
		z-index:2;
	}

	span {
		margin: 0 1px;
		height: 100%;
		display: block;
		background: rgba(209, 236, 250, 0.75);
		animation: draw 1s ease-in-out;
		position: relative;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		&.red {
			// background-image: linear-gradient(var(--red-lighter), var(--));
			background-color: var(--improve-markers-bg-color);
			border-top: 2px solid var(--red);
		}
		&.orange {
			// background-image: linear-gradient(var(--yellow-lighter), #ffffff00);
			background-color: var(--improve-markers-bg-color);
			border-top: 2px solid var(--yellow);
		}
		&.green {
			// background-image: linear-gradient(var(--green-lighter), #ffffffe6);
			background-color: var(--improve-markers-bg-color);
			border-top: 2px solid var(--green);
		}
	}
	.yAxis {
		z-index:3;
	}
	// .yAxis:after {
	// 	position:absolute;
	// 	content:'';
	// 	width:300px;
	// 	border-top:1px solid #F0F2F3;
	// 	top:0px;
	// 	left:2px;
	// 	z-index:1;
	// }

	.chartValue {
		font-size: 11px;
		text-align: center;
		color: white !important;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 360px;
		height: 20px;
		width: 50px;
		vertical-align: middle;
		padding-top: 3px;
		margin-top: -12px;
		margin-inline: auto;
		&.green {
			background: var(--green);
		}
		&.orange {
			background: var(--yellow);
		}
		&.red {
			background: var(--red);
		}
	}
	.chartTitle {
		position: absolute;
		bottom: -45px;
		width: 100%;
		text-align: center;
		font-size: 12px;
		height: 35px;
		padding: 0 5px;
	}
	.chartDate {
		position: absolute;
		bottom: -48px;
		text-align: center;
		width: 100%;
		padding: 10px 0;
		font-size:12px;
	}
}
.chart-y-axis {
	width: 10px;
	// padding-left: 35px;
	span {
		width: inherit;
		background-image: none !important;
		border-radius: 7px;
		border-top: 0 !important;
		margin: 0;
		animation: 0;
		&.red {
			background: var(--red);
			color: var(--red);
		}
		&.orange {
			background: var(--yellow);
			color: var(--yellow);
		}
		&.green {
			background: var(--green);
			color: var(--green);
		}
	}
	.yLabel {
		left: -50px;
		position: absolute;
		text-align: center;
		width: 50px;
		top: 50%;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: translateY(-50%);
		font-size: 12px;
	}
}
.chart-title {
	h3 {
		text-align: center;
		font-size: 16px;
		font-weight: 400;
		margin: 10px 0 5px;
		color: var(--font-color);
	}

	.chartDate {
		font-style: 12px;
		text-align: center;
	}
	.alert {
		margin: 30px 15%;
	}
	.alert-danger .fa-check {
		display:none;
	}
	.alert-warning .fa-check {
		display:none;
	}
}
.markersOutOfRangeArea .chartContainer {
	margin-left:10px;
	margin-right:10px;
}
.chartContainer {
	margin-bottom:40px;
}
.mean-platelet-volume-chart {
	display: table;
	table-layout: fixed;
	width: 100%;
	height: auto;
	margin: 0 auto;
	background-size: 100% 50px;
	background-position: left top;
	padding: 0;
	padding: 40px 0px;

	li {
		position: relative;
		display: table-cell;
		vertical-align: bottom;
		height: 12px;
		border-radius: 7px;

		// &.single {
		// 	height:108px;
		// }
	}

	span {
		margin: 0 1px;
		display: block;
		// background: rgba(209, 236, 250, 0.75);
		position: relative;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: inherit;    
		opacity: 0;
		animation: fadeIn ease-in;
		animation-fill-mode: forwards;
	    -webkit-animation-duration: 0.5s;
	    animation-duration: 0.5s;
		&.red {
			background-color: var(--red);
			// border-top: 2px solid #ff0000;
		    -webkit-animation-delay: 0.5s;
		    animation-delay: 0.5s;
		}
		&.orange {
			background-color: var(--yellow);
			// border-top: 2px solid #fc8e0f;
		    -webkit-animation-delay: 0.3s;
		    animation-delay: 0.3s;
		}
		&.green {
			background-color: var(--green);
			// border-top: 2px solid #4ab724;
		    -webkit-animation-delay: 0.1s;
		    animation-delay: 0.1s;
		}

		&.grey{
			background-color: var(--grey);
			// border-top: 2px solid #4ab724;
			-webkit-animation-delay: 0.1s;
			animation-delay: 0.1s;
		}
	}
	.chartValue {
		position: relative;
		font-size: 14px;
		text-align: center;
		color: white !important;
		margin: 0 auto;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 360px;
		height: 40px;
		width: 40px;
		vertical-align: middle;
		padding-top: 10px;
		margin-top: -20px;
		z-index:20;
		&.green {
			background: var(--green);
		}
		&.orange {
			background: var(--orange);
		}
		&.red {
			background: var(--red);
		}
	}
	.chartTitle {
		position: absolute;
		bottom: -45px;
		width: 100%;
		text-align: center;
		font-size: 12px;
		height: 35px;
		padding: 0 5px;
	}
}

.box-bg-accent{
	--grey: var(--box-bg-accent-color-lighter);
}

.box-bg-accent .xAxis .chartTitle {
	p,
	span{
		color: white;
	}
}

.xAxis {
	display: table;
	table-layout: fixed;
	width: 100%;

	.chartTitle {
		display: table-cell;
		text-align: center;
		font-size: 9px;
		font-weight: 400;
		position: relative;
		color: var(--title-color);

		p{
			position: relative;
			font-weight: 400;
			color: var(--title-color);
			&::before{
				content: "";
				position: absolute;
				top: -10px;
				left: 50%;
				height: 5px;
				border-radius: 10px;
				width: 95%;
				transform: translateX(-50%);
			}

			&.red::before{
				background-color: var(--red);
			}
	
			&.orange::before{
				background-color: var(--yellow);
			}
	
			&.green::before{
				background-color: var(--green);
			}
			
		}

		.fs-normal{
			font-size: 11px;
		}
	}

	.orange {
		color: var(--yellow);
		font-weight:500;
	}

	.green {
		color: var(--green);
		font-weight:500;
	}

	.red {
		color: var(--red);
		font-weight:500;
	}
}
.chartContainer {
	position: relative;
	.valuePosition {
		width: 20px;
		position: absolute;
		left: 0;
		top: 40px;
		height: 30px;
		z-index: 999;
		margin-left: -10px;
		&.red  {
			/*border-top: 2px solid #ff0000;*/
			.chartDrop {
				fill: var(--red);
			}
		}
		&.orange  {
			/*border-top: 2px solid #fc8e0f;*/
			.chartDrop {
				fill: var(--yellow);
			}
		}
		&.green {
			/*border-top: 2px solid #4ab724;*/
			.chartDrop {
				fill: var(--green);
			}
		}
		.chartDrop {
			width: 100%;
			position: absolute;
			top: 20px;
			left: 0px;
			width: 20px;
		}
		&:before {
			position:absolute;
			left:50%;
			top:2px;
			content:'';
			background:#ffffff;
			width:2px;
			margin-left:-1px;
			height:25px;
		}

		&.red:before {
			background:var(--red);
		}

		&.orange:before {
			background: var(--yellow);
		}

		&.green:before {
			background: var(--green);
		}
	}
	.chartDrop .chartValue {
		position: absolute !important;
		width: 100% !important;
		text-align: center !important;
		padding-top: 0 !important;
		color: white !important;
		font-size: 10px !important;
		left: 0;
		z-index: 999;
		top: 9px;
	}
}
.completedTests {
	padding: 0 15px;
	padding-bottom: 40px;
	.chartDrop {
		position: relative;
		top: 0;
		width: 30px;
		left: 0;
		fill: #f0f2f3;
		&.red {
			fill: var(--red);
		}
		&.orange {
			fill: var(--yellow);
		}
		&.purple {
			fill: #766ecd;
		}
		&.green {
			fill: var(--green);
			.chartValue {
				display: block;
			}
		}
		.chartValue {
			display: none;
			color: white !important;
			font-size: 12px !important;
		    position: absolute !important;
		    width: 100% !important;
		    text-align: center !important;
		    padding-top: 9px !important;
		    color: white !important;
		    font-size: 11px !important;
		    top: 0;
		    left: 0;
		}
	}
}
#bioMarkers {
	.bottomButton {
	  bottom: 30px;
	}

	h2{
		color: var(--title-color);
		font-weight: 400;
		font-size: 14px;
	}
}
.introText {
	font-size: 16px;
}
.lifeHub {
	padding: 50px 0;
	h3 {
		font-size: 22px;
	}
	h2 span {
		color: #5abbb4;
	}
}
.card.consultant {
	h4 {
		font-size: 15px;
		margin: 0!important;
	}
	.card-text {
		font-size: 12px;
	}
}


#latestResults {

	h3 {
		font-size: 12px;
	}

	.alert-danger .fa-check {
	    display:none;
	  }
	  .alert-warning .fa-check {
	    display:none;
	  }
}
.slick-dots li {
	width: 20px!important;
	height: 20px!important;
	button::before {
		border: 2px solid #5abbb4;
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 360px;
		background: white;
		content: ''!important;
		opacity: 1;
		width: 10px!important;
		height: 10px!important;
		left: 5px;
	}
	&.slick-active button::before {
		border-color: #d8d8d8;
		background: #d8d8d8;
		opacity: 1;
	}
}
.marker.completedTests {
	padding-bottom: 0;

	.marker-title{
		color: var(--title-color);
	}
}


@keyframes dash {
  to {
    transition: stroke-dashoffset;
  }
}
.welcomeStatus {
	.inside {    	
		padding: 0 1.5em;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;

		.handWriting {
			font-family: 'Over the Rainbow', cursive;
		}

		h1 {
			color:#ffffff;
		}

		p {
			font-size:16px;
			line-hight:25px;
			color:#ffffff;

			&.signOff {
				font-size:15px;
				letter-spacing: 0.5px;
				font-weight:700;
			}
		}
	}
}

.recomendedStatusBox {
	.websiteId1 {
		background: url('../img/reorderLife.png') bottom no-repeat;
		background-size: cover;
	}

	.websiteId2 {
		background: url('../img/reorderEdge.png') bottom no-repeat;
		background-size: cover;
	}

	.orderInformation { 
		padding:30px 50px 0 50px;
		padding-bottom: 5%;

		h1 {
			font-size:30px;
			font-weight:600;
		}

		p {
			font-size:16px;
			font-weight:300;
			line-height:23px;
		}
	}
}

@media only screen and (max-width: 576px) {

	.recomendedStatusBox {
		min-height:400px;

		.orderInformation {
			height:auto;
			padding-bottom:20px;

		}

	}
}


@media only screen and (max-width: 991px) {
  #bioMarkers {
	.bottomButton {
	  bottom: 15px;
	}
  }
}

.onboardingBg {
	
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
}

.fullscreen-bg__video {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 100%;
    height: auto;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: auto;
    height: 100%;
  }
}

.introjs-helperNumberLayer {
	display:none !important;
}
