 
 
body.nonforth {
 

	.activate-page .login-page, .activate-page, #activationArea, #checkoutArea, .cameFromActivate {

		background-image: linear-gradient(to bottom, #ffffff 0%, #e4e4e4 100%);
		height: 100vh;

		.forthLogo {
			display:none;
		}

		.forth {
			fill:#000000 !important;
		}
	    
		.activationForm {
			.form-control {
				border:1px solid #000000;
				color:#ffffff;
			}
		}

		h1, h2, h3, h4, p {
			color:#000000;
		}

		a, .forgotLink a {
			color:#000000;
			text-decoration:underline; 
			
			&:hover {
				text-decoration:none;
		 	}
		}


		.btn, .nextBtn {
			border: 1px solid #313131 !important;
			background: #313131 !important;
			color:#ffffff !important;
			text-decoration:none;

			&:hover {
				border: 1px solid #000000 !important;
				background: #000000 !important;
				color:#ffffff !important;
			}
		}

		.shareAccessTickBox {
			background:#ffffff;
			padding:20px;
			border:1px solid #bebebe;

			.tickBoxSection {
				font-weight:bold;
				font-size:16px;
			}
		}

		.overlayStyleContainer {
		    float: none;
		    margin: 0;
		    width: 100%;
		    background: none;
		    border: none;
		    border-radius: 0px;
		    box-shadow: none;
		    position: relative;


		    .alreadyHaveAccount {

		    	font-weight:bold;
		    	color:#313131;
		    	font-size:16px;
		    	margin-bottom:20px;

		    	a {
		    		color:#313131;
		    		text-decoration:underline;
		    	}
		    }
		}

		


	}

}
 

#activationForm {
	.title{
		font-weight: bold;
	}

	.well small{
		font-weight: 300 !important;
		font-size: 70% !important;
	}

	.input-group-text{
		background-color: var(--primary-color);
		border-color: var(--primary-color);
	}
}
 