.emailBtn {
  border-radius: 10px;
  overflow: hidden;
  background-color: var(--bs-primary) !important;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: white;
    margin: 0 auto;
    font-weight: 500;
  }

  svg {
    display: inline-block;
    rect {
      fill: var(--bs-primary);
    }
  }
}

.separator {
  --opacity: 0.5;
  --line-width: 20px;
  --line-height: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  opacity: var(--opacity);
  font-weight: 500;
  padding-block: 15px;
  width: fit-content;
  margin-inline: auto;

  &::before {
    content: "";
    display: block;
    width: var(--line-width);
    height: var(--line-height);
    background-color: black;
    opacity: var(--opacity);
  }

  &::after {
    content: "";
    display: block;
    width: var(--line-width);
    height: var(--line-height);
    background-color: black;
    opacity: var(--opacity);
  }
}

.rounded {
  width: var(--iconSize);
  aspect-ratio: 1;
  border-radius: 50%;
}

.shareBtn {
  --btn-default-font-color: white;
  --btn-default-color: #3e3c3a;
  --btn-default-color-hover: #000000;

  border-radius: 10px;
  padding: 8px 15px;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.1s ease-in-out;
  user-select: none;

  color: var(--btn-default-font-color);
  background-color: var(--btn-default-color);
  border: 1px solid var(--btn-default-color);

  &:hover {
    background-color: var(--btn-default-color-hover);
  }

  svg{
    color: currentColor;
  }
}