body.light .partner,
body.life .partner {
  --base: #fff;
  --base2: #eeeeee;

  --primary: #70898d;
  --primary-lighter: #819fa3;
  --primary-darker: #5f7477;

  --secondary: #faa240;
  --secondary-lighter: #e6963c;
  --secondary-darker: #bb792f;

  --white: #fff;
  --black: #2c2c2c;
  --noActive: #23b7b7;
  --textColour: #2c2c2c;
  --textNav: #70898d;
  --separator: #d3d3d3;
  --biomarkerColour: #47b27a;
  --input-text: #2c2c2c;

  --card-offer: #df5e5e;
  --card-text-label: #fff;
  --biomarkerBtn-border: #70898d;
  --biomarkerBtn-text: #70898d;
  --input-border: #70898d;

  --select-arrow: url(../img/downArrowDark.png);
  --checkbox-icon: url(../img/check.png);

  --closeButtonModal: var(--secondary);
}

body.dark .partner {
  --bgBody: #212121;

  --base: #000;
  --base2: #424242;

  --primary: #444444;
  --primary-lighter: #e2e2e2;
  --primary-darker: #000000;

  --secondary: #faa240;
  --secondary-lighter: #e6963c;
  --secondary-darker: #bb792f;

  --white: #fff;
  --black: #2c2c2c;
  --textColour: #ebebeb;
  --separator: #474646;
  --biomarkerColour: #47b27a;

  --btn-primary-text: #fff;
  --btn-primary-text-hover: #2c2c2c;

  --btn-default-text: #e2e2e2;
  --btn-default-text-hover: #444444;

  --card-offer: #df5e5e;
  --card-text-label: #eeeeee;
  --biomarkerBtn-border: #c2c2c2;
  --biomarkerBtn-text: #c2c2c2;
  --input-border: #fff;

  --closeButtonModal: var(--secondary);

  --select-arrow: url(../img/downArrow.png);
  --checkbox-icon: url(../img/checkDark.png);
  --input-text: #fff;
}

body.life {
  --base: #fff;
  --base2: #eeeeee;

  --primary: #70898d;
  --primary-lighter: #819fa3;
  --primary-darker: #5f7477;

  --secondary: #faa240;
  --secondary-lighter: #e6963c;
  --secondary-darker: #bb792f;
}

.partner {
  margin-top: 60px;
  background: var(--base2);
  color: var(--textColour);

  h1:not(.companyName) {
    font-size: 60px;
    font-weight: lighter;
    letter-spacing: 8px;
    text-transform: uppercase;
  }

  p {
    margin: 0;
  }

  #sidebar-wrapper {
    left: 0;
    top: 81px;
    margin-top: 0px !important;
    max-width: 325px;
    transition: all 0.1ms;
    background: linear-gradient(
      to bottom left,
      var(--base) 45%,
      var(--primary) 100%
    );
    a.nav-link {
      color: var(--textColour)!important;
    }

    .versionNumber {
      bottom: 130px;
      color: var(--textColour);
      z-index: 1;
    }

    .active {
      font-weight: 800 !important;
      color: var(--primary) !important;
    }

    @media (max-width: 990px) and (min-width: 575px) {
      margin-top: 20px;
    }
  }

  .separator {
    border-bottom: 1px solid var(--separator);
  }

  .wrapper {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 0 auto;
    max-width: 1460px;
  }

  .bg-base {
    background: var(--base) !important;

    * {
      color: var(--textNav) !important;
      font-weight: 400;
    }
  }

  .navbar {
    max-height: 81px;
    height: 100%;

    li {
      position: relative;
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      line-height: 14px;
      min-height: 81px;
      display: flex;
      align-items: center;
      overflow: hidden;

      .companyName {
        font-size: 1.6rem !important;
        margin: 0px;
        color: var(--textColour) !important;
        max-width: 200px;
      }

      .active {
        position: relative;
        font-weight: 800 !important;
        color: var(--primary) !important;
        &::before {
          content: "";
          width: calc(100% - 1rem);
          height: 5px;
          background-color: var(--primary);
          position: absolute;
          bottom: -26px;
        }
      }
    }
  }

  .banner {
    position: relative;
    background: var(--primary);
    color: var(--white);
    overflow: hidden;
    margin-top: 81px;

    .caption {
      padding: 100px 50px 50px 50px;
      h1 {
        font-size: 60px;
        font-weight: lighter;
        letter-spacing: 8px;
        text-transform: uppercase;
      }

      strong,
      span {
        font-weight: 600;
      }

      p {
        font-size: 16px;
        font-weight: 300;
      }
    }

    img {
      object-fit: cover;
    }
  }

  @media (max-width: 991px) {
    .banner {

      .caption {
        text-align: center;
        padding: 50px;

        h1,
        h2 {
          font-size: 40px;
          font-weight: lighter;
          letter-spacing: 8px;
          text-transform: uppercase;
          span {
            font-weight: bold;
          }
        }

        p {
          margin-top: 2rem;
          letter-spacing: 0.5px;
          line-height: 22px;
          font-size: 14px;
          font-weight: 300;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  @media (max-width: 576px) {
    margin-top: 37px;

    .banner {
      position: relative;
      width: 100%;
      overflow: hidden;

      .caption {
        text-align: center;
        padding: 50px;

        h1,
        h2 {
          font-size: 40px;
          font-weight: lighter;
          letter-spacing: 8px;
          text-transform: uppercase;
          span {
            font-weight: bold;
          }
        }

        p {
          margin-top: 2rem;
          letter-spacing: 0.5px;
          line-height: 22px;
          font-size: 14px;
          font-weight: 300;
        }
      }

      div:nth-child(2) {
        overflow: hidden;
        max-height: 340px;
      }
    }
  }

  @media (max-width: 768px) {
    .companyName {
      margin: 0px;
      color: var(--textColour) !important;
      max-width: 200px;
    }

    .hideOverflow {
      overflow: hidden;
      max-height: 81px;
    }
  }

  .searchForm {
    padding-top: 35px;
    padding-bottom: 28px;
    position: relative;

    label {
      font-size: 16px;
      font-weight: 500;
      padding-left: 3px;
      margin-bottom: 0;
      padding-bottom: 15px;
      text-transform: uppercase;
    }

    input {
      border-radius: 33px;
      background: transparent;
      border: 1px solid var(--input-border);
      color: var(--input-text);
      padding-left: 30px;

      &::placeholder {
        color: var(--input-text);
        font-weight: 300;
      }
    }

    svg {
      position: absolute;
      color: var(--input-text);
      &.search-md {
        top: 71px;
        left: 10px;
      }
      &.search-lg {
        top: 82px;
        left: 10px;
      }
      &.times-lg {
        bottom: 39px;
        right: 12px;
      }
      &.times-md {
        bottom: 37px;
        right: 14px;
      }
    }
  }

  .listTest__header {
    p {
      font-size: 14px;
    }

    select {
      padding: 4px;
      border-radius: 33px;
      color: var(--black);
      &:focus {
        font-size: inherit;
      }
    }
  }

  .sortByFilter {
    padding-bottom: 15px;
    label {
      font-size: 16px;
      font-weight: 500;
      padding-left: 3px;
      margin-bottom: 0;
      padding-bottom: 15px;
      text-transform: uppercase;
    }
    select {
      width: 100%;
      padding: 10px 25px 10px 15px;
      font-size: 1rem;
      font-weight: 300;
      color: var(--input-text);
      background: transparent;
      border: 1px solid var(--input-border);

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: var(--select-arrow) transparent no-repeat 98.5% !important;
      background: var(--select-arrow) transparent no-repeat calc(100% - 10px) !important;

      &::placeholder {
        color: var(--primary);
      }

      &:focus {
        font-size: 1rem;
        outline: transparent;
      }

      option {
        color: black!important;
      }
    }
  }

  .typeFilter {
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 14px;
    label.title {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      padding-left: 3px;
      margin-bottom: 0;
      padding-bottom: 15px;
    }

    .custom-text {
      color: var(--input-text);
    }

    input[type="checkbox"] {
      -webkit-appearance: none;
      background: transparent;
      border: 1px solid var(--primary);
      padding: 6px;
      border-radius: 3px;
      display: inline-block;
      position: relative;

      &:checked {
        background-image: var(--checkbox-icon);
        background-repeat: no-repeat;
        background-color: var(--primary);
        background-position: center;
        background-size: 10px;
        border: 1px solid transparent;
      }
    }
  }

  .card {
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column;
    flex: 1 1 auto;
    padding: 0;
    background: var(--base);

    .card__subscription {
      background-color: var(--primary-darker);
      font-size: 12px;
      letter-spacing: 0.3px;
      color: var(--card-text-label);
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    .card__newProduct {
      background-color: var(--primary-lighter);
      font-size: 12px;
      letter-spacing: 0.3px;
      color: var(--primary-darker);
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    .card__offer {
      background-color: var(--card-offer);
      font-size: 12px;
      letter-spacing: 0.3px;
      color: var(--card-text-label);
      padding: 3px 10px;
      margin-left: auto;
      width: fit-content;
      border-top-right-radius: 3px;
    }

    .card__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: row nowrap;
      padding: 0.3rem 1rem 0.8rem 1rem;
      border-bottom: 1px solid var(--separator);

      .card__header-title {
        flex: 1 1 40%;
        font-size: 1rem;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 4px;
        padding-top: 10px;
      }

      .card__header-price {
        position: relative;
        font-weight: 700;
        font-size: 2rem;
        text-align: right;

        span:nth-child(1) {
          font-size: 14px;
          font-weight: normal;
        }

        span.perTest {
          position: absolute;
          font-size: 0.7rem;
          font-weight: normal;
          width: 100%;
          bottom: -6px;
          left: 0;
          text-align: right;
        }

        .poundSymbol {
          font-size: 1.5rem;
        }
      }
    }

    .card__body {
      display: flex;
      flex-flow: row wrap;
      flex: 1 1 auto;
      padding: 1rem;
      text-align: center;
      border-bottom: 1px solid var(--separator);

      .biomarkersIncludedBtn {
        background: var(--base);
        border: 1px solid var(--biomarkerBtn-border);
        color: var(--biomarkerBtn-text);
        font-weight: 400;
        letter-spacing: 2px;
        padding: 10px 20px;
        &:hover {
          background: var(--base) !important;
          border: 1px solid var(--biomarkerBtn-border);
          color: var(--biomarkerBtn-text) !important;
        }
        .marker_counter {
          font-weight: 300;
          color: var(--primary);
          font-size: 10px;
          // background: url(/img/biomarkerCounter.svg) bottom no-repeat;
          background-size: contain;
          padding: 9px 8px 5px 8px;
          letter-spacing: 0.1px;
          margin-right: 10px;
        }
      }

      .card__body-intro {
        padding: 1rem 0;
      }

      .card__body-info {
        display: flex;
        font-size: 10px;
        justify-content: space-evenly;
        font-weight: 700;
        padding: 1rem 0 0 0;
        svg{
          color: green;
        }
        div {
          padding-bottom: 5px;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .card__footer {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      padding: 1rem;
    }
  }

  .btn-primary {
    background: var(--secondary);
    border: 1px solid var(--secondary);
    color: var(--white) !important;
    &:hover {
      border: 1px solid var(--secondary-lighter) !important;
      background: var(--secondary-darker) !important;
      color: var(--white) !important;
    }
  }

  .btn-default {
    background: var(--base);
    border: 1px solid var(--primary);
    color: var(--primary);
    &:hover {
      border: 1px solid var(--primary);
      background: var(--primary) !important;
      color: var(--white) !important;
    }
  }

  .btn-filter {
    background: transparent;
    border: 0px;
    text-transform: uppercase;
    color: var(--primary);
  }

  .partner_footer {
    padding: 1rem;
    background: var(--base);
    color: var(--textColour) !important;

    p {
      font-size: 12px;
      font-weight: 300;
      line-height: 20px;
    }

    a {
      color: var(--textColour) !important;
    }

    .footer_item {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;
      p {
        padding: 2rem 0;
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 4px;
      }
      img {
        margin-bottom: auto;
        padding-bottom: 2rem;
      }

      .forth-logo{
        max-width: 205px;
      }
    }

    .footer_payments{
      svg{
        padding: 2px;
      }

      img{
        padding: 2px;
        max-width: 49px;
        margin: 0;
      }
    }
  }

  .partnership {
    padding: 10px;

    .intro {
      font-size: 22px;
      line-height: 32px;
      padding: 30px 0;
      font-weight: 200;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    p {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .listTest__header {
      padding: 5px 0px 10px 0px;
      border-bottom: 1px solid var(--separator);
      margin-bottom: 5px;
    }
    .modal-dialog {
      padding: 0px;
      margin: 0px;
      width: 100%;
    }
  }

  @media (max-width: 576px) {
    margin-top: 37px;

    .card__header .card__header-title {
      flex: 1 1 35% !important;
    }
  }
}

.informativeCard {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 446px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  .no1 {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    position: relative;
    padding: 10px;

    .bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 45%;
      background-color: var(--primary-lighter);
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p,
    small {
      color: white;
    }

    .percent {
      margin-top: 100px;
      font-size: 100px;
      font-weight: 700;
      position: relative;
      z-index: 2;
      small {
        font-size: 50px;
        vertical-align: top;
        position: absolute;
        top: 15px;
      }
    }

    .text {
      text-align: center;
      font-size: 22px;
      font-weight: 300;
      line-height: 28px;
    }
  }

  .no2 {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    position: relative;
    padding: 10px;

    .bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 45%;
      background-color: var(--primary-lighter);
      clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .header {
      font-size: 22px;
      text-transform: uppercase;
      line-height: 28px;
      letter-spacing: 3px;
      color: white;
      z-index: 2;
      strong {
        color: white;
      }
    }

    .img2 {
      position: absolute;
      bottom: 5px;
      right: -6px;
    }

    .img1 {
      width: 90%;
    }

    .footer {
      font-size: 22px;
      line-height: 28px;
      letter-spacing: 0.5px;
      color: white;
      text-align: center;
      font-weight: 300;
    }
  }

  .no3 {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    position: relative;
    padding: 10px;

    .img1 {
      position: absolute;
      top: 0;
      left: 0;
    }

    .info {
      z-index: 1;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      .header {
        text-align: center;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        letter-spacing: 0.5px;
        color: var(--base);
        margin-bottom: 0px;
        z-index: 2;
      }
      .footer {
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: var(--base);
      }
      .img2 {
        margin: 1rem 0;
      }
    }
  }

  .no4 {
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    background-image: url(../img/womanfit.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 10px;

    .bgCover {
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: #00000083;
    }

    .img1 {
      margin: 1rem 0;
      z-index: 2;
    }

    .info {
      z-index: 2;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;

      .header {
        font-size: 22px;
        text-transform: uppercase;
        line-height: 28px;
        letter-spacing: 3px;
        padding-bottom: 1rem;
        color: white;
        strong {
          color: white;
        }
      }
      .footer {
        text-align: center;
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: white;
      }
    }
  }
}

.body.partner .errorPage h2 {
  color: #666;
}

.alert-preview-shop {
  width: 100%;
  background-color: #cf0000;
  color: #fff;
  min-height: 25px;
  font-weight: bold;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: fixed;
  top: 0;
  z-index: 999;
}

.alert-preview-shop-top {
  position: fixed;
  top: 25px;
  left: 0px;
  right: 0px;
  z-index: 1030;
}

@media (max-width: 768px) {
  .alert-preview-shop-top {
    top: 33.8px;
  }
}

.sidebar-preview-shop-top{
  top: 100px !important;
}