#CheckoutProgressBar {
  position: relative; 
  .step {
    width: min-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    &:hover {
      cursor: pointer;
    }
    .stepCircle {
      border-left: 10px solid #fff;
      border-right: 10px solid #fff;

      .stepsBorder {
        border:2px solid #D4D5D8;
        border-radius: 360px;
        width:36px!important;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        line-height: 0;
        color: #D4D5D8;
        font-size: 18px;
        background: white;
        margin-bottom: 10px;
      }
    }

    .stepTitle {
      color: #D4D5D8;
      font-weight:400;
      text-align: center;
      min-height: 33.8px;
    }

    &.active .stepsBorder{
      border: 2px solid var(--primary-color);
      color: var(--primary-color);
    }

    &.complete .stepsBorder{
      border: 2px solid var(--primary-color);
      background: var(--primary-color);
      color: white;
    }

    &.complete .stepTitle,
    &.active .stepTitle {
      color: var(--primary-color);
      font-weight:400;
    }

    &.disabled {
      pointer-events: none;
    }
  } 
  .stepBreak {
    z-index:0;
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}