#forth-input{
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #70898D;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 0.5rem;

  .count-border{
    border-top: 1px solid #70898D;
  }

  &.disabled{
    background-color: #F6F6F6;
    border-color: #ADAEAF;

    input, textarea, select {
      color: #ADAEAF;
    }
  }

  input:disabled,
  textarea:disabled,
  select:disabled{
    background-color: #F6F6F6;
    border-color: #ADAEAF;
    color: #ADAEAF;
  }

  &:has(.disabled),
  &:has(input:disabled),
  &:has(textarea:disabled),
  &.disabled + label,
  input:disabled + label,
  textarea:disabled + label {
    background-color: #F6F6F6;
  }

  &:not(.disabled):hover{
    border-color: #445C60;
  }

  &:focus-within{
    box-shadow:  0px 0px 1px 2px rgba(67, 71, 79, 0.25)
  }

  label{
    color: #70898D;
    margin-bottom: 0px;
    order: 1;
    padding: 10px 10px 0px 10px !important;
    transition: all 200ms;
    width: 100%;
    &.error {
      color: red;
    }
  }

  input ~ label,
  select ~ label{
    transform: translateY(calc(50% - 4px)) translateX(calc(10% - 2px)) scale(1.2);
  }

  // textarea ~ label{
  //   transform: translateY(calc(50% + 6px)) translateX(calc(10% - 2px)) scale(1.2);
  // }

  // textarea.longLabel ~ label{
  //   transform: translateY(calc(50% - 4px));
  // }

  // textarea.noScale ~ label{
  //   transform: translateY(calc(50% + 6px));
  // }

  .row-1{
    transform: translateY(calc(50% + 5px)) translateX(calc(10% - 2px)) scale(1.2);
  }

  .row-2{
    transform: translateY(calc(50% + 10px)) translateX(calc(10% - 2px)) scale(1.2);
  }

  .row-3{
    transform: translateY(calc(50% + 20px)) translateX(calc(10% - 2px)) scale(1.2);
  }

  .row-4{
    transform: translateY(calc(50% + 30px)) translateX(calc(10% - 2px)) scale(1.2);
  }

  .row-5{
    transform: translateY(calc(50% + 40px)) translateX(calc(10% - 2px)) scale(1.2);
  }
  
  &:focus-within label,
  textarea:not(:placeholder-shown) ~ label, 
  input:not(:placeholder-shown) ~ label, 
  select:not(:placeholder-shown) ~ label {
    transform: translateY(calc(-50% + 10px)) scale(1);
  }

  &:focus-within textarea.longLabel ~ label,
  textarea.longLabel:not(:placeholder-shown) ~ label{
    transform: translateY(calc(-50% + 25px));
  }
  
  &:focus-within textarea.noScale ~ label,
  textarea.noScale:not(:placeholder-shown) ~ label{
    transform: translateY(calc(-50% + 10px));
  }

  input, select, textarea{
    background-color: transparent;
    border: none !important;
    color: #000;
    font-weight: 300;
    order: 2;
    padding: 3px 10px !important;

    &:focus{
      background-color: transparent;
      box-shadow: none;
    }
  }

  textarea{
    resize: vertical;
    padding-bottom: 25px!important;
  }

  input[name="customerHeight"]::before{
    display: inline-block;
    content: "cm";
    position: absolute;
    top: 0px;
    width: 22px;
    height: 22px;
  }
}