:root{
  --white: #fff;
  --black: #222;
  --gray: #b6b6b6;

  --default-color: #ffffff80;
  --default-color-hover: #ffffff99;
}

.button {
  border-radius: 10px;
  padding: 10px 26px;
  letter-spacing: 1px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  font-size: 14px;

  &::first-letter{
    text-transform: uppercase;
  }

  &[variant="default"]{
    color: var(--default-font-color);
    background-color: var(--default-color);
    border: 1px solid var(--default-color-hover);
    
    &:hover{
      background-color: var(--default-color-hover);
    }
  }

  &[variant="default-outline"]{
    color: var(--default-outline-font-color);
    background-color: transparent;
    border: 1px solid var(--default-color);
    
    &:hover{
      color: var(--default-outline-font-color-hover);
      background-color: var(--default-color);
    }
  }

  &[variant="primary"]{
    color: var(--white);
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color-hover);

    &:hover{
      background-color: var(--primary-color-hover);
    }
  }

  &[variant="primary-outline"]{
    color: var(--primary-color);
    background-color: transparent;
    border: 1px solid var(--primary-color-hover);

    &:hover{
      color: var(--white);
      background-color: var(--primary-color);
    }
  }

  &[variant="link"]{
    color: var(--primary-color);
    background-color: transparent;
    border:none;
    
    &:hover{
      text-decoration: underline;
      color: var(--primary-color-hover);

    }
  }

  &[variant="link-default"]{
    color: var(--default-color);
    background-color: transparent;
    border:none;
    
    &:hover{
      text-decoration: underline;
    }
  }

  &:disabled{
    color: var(--gray);
    filter: grayscale(1);
    cursor: not-allowed;
  }

  &[data-font-size="default"]{
    font-size: 14px;
  }

  &[data-font-size="sm"]{
    font-size: 10px;
  }

  &[data-font-size="md"]{
    font-size: 14px;
  }

  &[data-font-size="lg"]{
    font-size: 18px;
  }

  &[data-font-size="xl"]{
    font-size: 24px;
  }
}


body:global(.edge) { 
  .button {
    &[variant="default"]{
      color: var(--white); 
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color-hover);
      &:hover{
        background-color: var(--primary-color-hover);
      }
    }
    &[variant="default-outline"]{
      color: var(--primary-color);
      background-color: transparent;
      border: 1px solid var(--primary-color);
      
      &:hover{
        color: var(--white);
        background-color: var(--primary-color);
      }
    }
  }
}