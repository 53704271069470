.yourOrderWrapper {
  background: #F6F7F9;
  border-radius: 30px;
  padding: 50px;
  .mobileSummaryLeft {
    font-size: 14px;
    display: flex;
    align-items: center;
    svg{
      font-size:18px;
    }
  }
  .mobileSummaryRight {
    font-size: 22px;
    font-weight: 500;
    svg{
      font-size: 16px;
    }
  }
}

@media (min-width: 768px) {
  .dontCollapseMd {
    display: block!important;
    height: auto !important;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .yourOrderWrapper { 
    padding: 0px;
    border-radius:0px;
    border-bottom: 2px solid #D2D8E4;
  }
}

@media (min-width: 768px) and (max-width: 979px) { //tablet devices
  .yourOrderWrapper { 
    padding: 25px; 
  }
}