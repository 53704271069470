@media only screen and (min-width: 992px) {  
  #category-details .chart span {
    margin: 0 auto;
    width:15px;
  }
  .progressSlider .chart span {
    margin: 0 auto;
    width:15px;
  }
}
#category-details {
  color: black;
  .alert { 
    margin-bottom: 10px;
    svg{
      margin-right: 10px;
      position: initial;
    }
  }
  .biomarker-count {
    font-size: 12px;
  }
  .health-type svg{
    color: #5abbb4;
  }
  .arrow:after {
    content: '\203A';
    position: absolute;
    right: 15px;
    top: -119%;
    font-size: 40px;
    color: var(--primary-color);
  }
}
#your-progress {
  padding-bottom:50px;
}
.categoryResults {
    width: 100vw;
    background: #FAFBFB;
    padding: 50px 0px 50px 0;
    position: relative;
    left: 50%;
    margin-left: -50vw;
}
.progressSlider {
  .tab-title {
    color: var(--title-color);
    font-size: 16px;
  }
  .tab-date {
    color: #767676;
    font-size: 11px;
  }
  .chart {
    margin-top: 60px;
  }
  .bg-white {
    height: auto;
  }

  .slick-track{
    height: 100%;
  }

  .slick-slide {
    height: auto;
    // max-height: 410px;
  }

  .slick-prev {
    top: initial;
    bottom: -15%;
  }
  .slick-next {
    top: initial;
    bottom: -15%;
  }
  .slick-dots {
    bottom: -35px;
  }
  .dueTest h3 {
    font-size: 14px !important;
  }
  .slick-prev:before, .slick-next:before {
    font-size:25px;
  }
}

#category-details {
  .marker-value {
    font-weight:bold;
    margin-right: 5px;
  }
  .marker-result {
    display: inline-block;
    font-weight:normal;
  }

}


@media only screen and (max-width: 991px) {
  .progressSlider {
    .chart {
      span {
        margin: 0 1.6em;
      }
    }
  }
}


@media only screen and (max-width: 767px) {
  .progressSlider {
    .chart {
      span {
        margin: 0 1.8em;
      }
    }
  }
  
}
    