label {
  font-size: 12px;
  width: 100%;
  display: block;
  font-weight: 500;
}

.forthInput{
  position: relative;
  
  input{
    color: #000000;
    border: 1px solid #E1E2E6;
    border-radius: 10px;
    
    &:disabled {
      color: #797979;
    }

    &:focus{
      color: #000000;
      background-color: #fff;
      border-color: var(--primary-color);
    }
  
    &::placeholder{
      color: #797979;
      opacity: 1; /* Firefox */
    }
  
    &:-ms-input-placeholder,
    &::-ms-input-placeholder{
      color: #797979;
    }
  }

  .spinnerContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }
}