#ovarianHormoneTabs,
#controlHormoneTabs,
#linkingYourHormones,
#hormoneChartTabs {


    .nav-tabs {

      .nav-item {

        .nav-link {
          color: #666;
          font-weight: 500;
          font-size: 15px;
          background: none;
          background-color: #d8edef;
          border: 0;
          padding:10px 15px;
          border-radius: 0;
          &:hover, &.active {
            color: white;
            background-color: #23B7B7;       
          }
          &.active{

            &:after {
              width: 0;
              height: 0;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              border-top: 10px solid #23B7B7;
              content: " ";
              position: absolute;
              bottom: -10px;
              left: 47%;
            }
          }
      }
      }
    }

  
  .sampleResult {
    background: var(--female-report-box-bg-color);
    color: var(--font-color);
    font-weight: 400;
    
    .dayText {
      font-size: 10px;
      margin-right:5px;
      display: inline-block;
    }
    .dayValueOne {
      background: #44B17A;
      color: white;
      display: inline-block;
      border-radius: 360px;
      font-size: 15px; 
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      font-size:13px;
    } 
    .dayValueTwo {
      margin: 0px;
      width: 25px;
      height: 25px;
      background: #44B17A;
      transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      display: inline-block; 
      font-size:13px;
    }
    .dayValueTwo-inner {
      color: #fff;
      font-size: 15px; 
      line-height: 25px;
      text-align: center;
      transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    .resultValue {
      font-size: 17px;
      font-weight: 600;
      line-height: 12px;
      margin-top: 5px;
    }
    .resultRange {
      font-weight:100;
      font-size: 10px;
    }

    @media(min-width: 768px) {
      .dayText {
        font-size: 15px;
        margin-right:15px;
      }
      .dayValueOne {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size:20px;
        margin-right: 15px;
      }
      .dayValueTwo {
        width: 33px;
        height: 33px;
        margin-right: 15px;
      }
      .dayValueTwo-inner {
        font-size: 20px; 
        line-height: 35px;
      }
      .resultValue {
        font-size: 35px;
        line-height: 30px;
        margin-top: 0px;
      }
      .resultRange {
        font-size: 15px;
      }
    }
  }
}

.sampleResultModal {
  background: #ffffff;
  font-weight: 400;
  .dayText {
    font-size: 10px;
    margin-right:5px;
    display: inline-block;
  }
  .dayValueOne {
    background: #756ECD;
    color: white;
    display: inline-block;
    border-radius: 360px;
    font-size: 15px; 
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size:13px;
  } 
  .dayValueTwo {
    margin: 0px;
    width: 25px;
    height: 25px;
    background: #44B17A;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    display: inline-block; 
    font-size:13px;
  }
  .dayValueTwo-inner {
    color: #fff;
    font-size: 15px; 
    line-height: 25px;
    text-align: center;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .resultValue {
    font-size: 17px;
    font-weight: 600;
    line-height: 12px;
    margin-top: 5px;
  }
  .resultRange {
    font-weight:100;
    font-size: 10px;
  }

  @media(min-width: 768px) {
    .dayText {
      font-size: 15px;
      margin-right:15px;
    }
    .dayValueOne {
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size:20px;
      margin-right: 15px;
    } 
    .dayValueTwo {
      width: 35px;
      height: 35px;
      margin-right: 15px;
    }
    .dayValueTwo-inner {
      font-size: 20px; 
      line-height: 35px;
    }
    .resultValue {
      font-size: 35px;
      line-height: 30px;
      margin-top: 0px;
    }
    .resultRange {
      font-size: 15px;
    }
  }
}


#hormoneKeys {
  .key {
    text-transform: uppercase;
    display: inline-block;
    margin: 0 10px;
    .line {
      display: inline-block;
      width: 13px;
      hr {
        margin:0;
      }
    }
    .keyValue {
      display: inline-block;
      letter-spacing: 2px;
      font-size: 12px;
    }
    &.high {
      .line hr {
        border-top: 2px dashed #5BC5F2;
      }
    }
    &.average {
      .line hr {
        border-top: 2px solid #f9c19b;
      }
    }
    &.low {
      .line hr {
        border-top: 2px dashed #EF94DD;
      }
    }
    &.personalised {
      .line hr {
        border-top: 5px solid #d8edef;
      }
      .keyValue {
        text-transform: none;
      }
    }
  }
}

#personalisedCurveBox {
  background: #d8edef;
  border-radius: 60px;
  font-size: 12px;
  
  .title {
    display: inline-block;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0 15px;
  }
  .score {
    width:30%;
    border: 4px solid white;
    background: #756ECD;
    color: white;
    display: inline-block;
    text-align: center;
    border-radius: 60px;
    padding: 10px 0px;
    line-height: 15px;
    font-size:12px;
    .value {
      font-size:16px;
      font-weight:700;
    }
  }

}

#infoButton {
  color: #23B7B7;
  border: 0;
  font-size: 25px;
  background: none;
  text-align:left;
  margin-left:-15px;
}



#latestHormonesChart {
  border-radius: 10px;

  &.card{
    background-color: var(--female-report-box-bg-color);
  }

  .formScoreTitle {
    color: var(--title-color);
    font-weight: 500;
    margin-bottom:6px !important;
  }

  .card-title {
    color: var(--title-color);
  }

  #infoButton {
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 20;
  }

  p {
    color: #000000;
  }
}


.hormoneReport {

  margin-bottom:100px;

  .hormoneLineChart {
      height:250px;
      padding:0;
    }

   @media(min-width: 768px) {
    .hormoneLineChart {
      height:400px;
      padding:0 50px;
    }


   }

}

#hormoneReport {
  .profilePicture {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 360px;
    background-size: cover !important;
    margin: 0 auto;
    margin-bottom: 20px!important;
    border: 2px solid transparent;
    background-clip: padding-box;
    box-sizing: border-box;

    &.Male {
      background-image:url('../img/dpm.jpg');
    }
    &.Female {
      background-image:url('../img/dpf.jpg');
    }

    &:before{
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      margin: -2px;
      border-radius: inherit;
      background: linear-gradient(45deg, var(--profile-gradient-color-1), var(--profile-gradient-color-2));
      z-index: -1;
    }
  }
}

