#testUpseller {
  background:#F5ECE7;
  color: #000;
  padding: 15px;
  border-radius:10px;
  position:relative;
  --arrow-size: 15px;

  // &::before {
  //   content: "";
  //   position: absolute;
  //   top: -29px;
  //   left: 10%;
  //   transform: translateX(-15%);
  //   aspect-ratio: 1;
  //   border-left: var(--arrow-size) solid transparent;
  //   border-right: var(--arrow-size) solid transparent;
  //   border-bottom: var(--arrow-size) solid #F5ECE7;
  // }

  h2, h5 {
  	font-weight:500;
  }

  h2{
    font-size: 19px;
  }

  h5 {
  	font-size: 15px;
  }

  .testItem {
  	background:#fff;
  	border-radius:10px;
  	padding:15px 10px;
  	cursor:pointer;

    .checkBoxCircle {
      border: 2px solid #ADADAD;
      border-radius: 360px;
      width: 20px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active .checkBoxCircle {
      border: 2px solid var(--primary-color);
      background-color: var(--primary-color);
      color: #fff;
    }
  
    &:not(.active):hover {
      .checkBoxCircle {
        position: relative;
        background: #ADADAD;
  
        &::after{
          content: '\f00c';
          position: absolute;
          width: 14px;
          font-family: "Font Awesome 6 Pro";
          right: 50%;
          top: 50%;
          transform: translateX(50%) translateY(-50%);
          color: #fff;
          font-size: 14px;
        }
      }
    }

  }

  .price {
  	font-size:18px;
  	font-weight:300;
  }
  .modalToggler {
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    font-weight:400;
  }
  .upsellImage {
    width: 50px;
    margin-right: 10px;
  }
}

.modifyTest{
  line-height: 10px;
}



body:global(.edge) {
  #testUpseller {
    background: #fff;
    border: 1px solid #e1e2e6;
    .testItem {
      background: #f6f6f6;
      border: 2px solid #f6f6f6;
    }
  }
}