.item {
  height: 62.9px;
  border-radius: 5px;
  opacity: 0.3;
}

.md {
  height: 46px;
}

.button {
  background-color: var(--primary-color);
  border-radius: 10px;
  color: var(--primary-color);
  padding: 5px 10px;
  user-select: none;
}

.method {
  position: relative;
  span {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 25px;
    height: 20px;
    background-color: white;
    border-radius: 3px;
  }
}

.cards {
  position: relative;
  span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 25px;
    height: 20px;
    background-color: white;
    border-radius: 3px;

    &:nth-child(1){
      right: 10px;
    }

    &:nth-child(2){
      right: 40px;
    }

    &:nth-child(3){
      right: 70px;
    }

    &:nth-child(4){
      right: 100px;
    }
  }
}