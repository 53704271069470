.styledCheckbox {
  background:#fff;
  border-radius:10px;
  padding:15px 10px;
  cursor:pointer;
  border: 1px solid #E1E2E6;

  & > div {
    gap: 1rem
  }
  
  h5 {
    font-size: 14px;
    font-weight: 400;
  } 
  
  .checkBoxCircle {
    border: 2px solid #ADADAD;
    border-radius: 360px;
    width: 20px;
    min-width: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      font-size: 12px;
    }
  }
  
  &.active {
    border: 1px solid var(--primary-color);
    .checkBoxCircle {
      border: 2px solid var(--primary-color);
      background: var(--primary-color);
      color: #fff;
    }
    .user_tickBoxes{
      svg{
        color: var(--primary-color);
      }
    }
  }
  
  &:not(.active):hover {
    .checkBoxCircle {
      position: relative;
      border: 2px solid #ADADAD!important;
      background: #ADADAD!important;
      color: #fff!important;
      font-size: 14px;
      
      &::after{
        content: '\f00c';
        position: absolute;
        width: 14px;
        font-family: "Font Awesome 6 Pro";
        right: 50%;
        top: 50%;
        transform: translateX(50%) translateY(-50%);
        color: #fff;
        font-size: 14px;
      }
    }
  }
  
  &.selected{
    border: 1px solid var(--primary-color);
    svg{
      color: var(--primary-color);
    }
  }
  
  &.hover:hover i::before{
    content: '\f14a';
    font-family: "Font Awesome 6 Pro";
    color: #ADADAD;
    cursor: pointer;
  }
  
  &.unselected{
    svg{
      color: #ADADAD;
    }
  }
  
  &.disabled{
    filter: grayscale(1);
    pointer-events: none;
  }
}