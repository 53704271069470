#selectedSubscriptionOption {
  h4 {
    font-weight:600;
  }
  a {
    color: #000;
    text-decoration:underline;
    font-weight:400;
  }
}
.card {
  border-radius:10px;
  border:1px solid #E1E2E6;
  .cardHeader {
    background: #fff;
    border-bottom:none;
    border-radius: 10px; 
    padding:0;
    a {
      font-weight:400;
      color: #000;
      padding: 20px;
      text-decoration:none!important;
    }
    h5 {
      font-weight: 400;
      font-size: 16px;
    }
  }
  .checkBoxCircle {
    border: 2px solid #ADADAD;
    border-radius: 360px;
    width: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.active, &:hover {
    .checkBoxCircle {
      border: 2px solid var(--primary-color)!important;
      background: var(--primary-color)!important;
      color: #fff!important;
      font-size: 14px;
    }
  }
}

.subscriptionPill {
  display:flex;
  align-items: center; 
  background:#fff;
  border-radius:10px;
  padding:15px 10px;
  cursor:pointer;
  border: 1px solid #E1E2E6;
  margin-bottom: 20px;

  h2, h5 {
    font-weight:500;
  }

  h5 {
    font-size: 16px;
  }

  .pillCheckBoxCircle {
    border: 2px solid #ADADAD;
    border-radius: 360px;
    width: 20px;
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
      visibility:hidden;
    }
  } 

  &.active {
    .pillCheckBoxCircle {
      border: 2px solid var(--primary-color)!important;
      background: var(--primary-color)!important;
      color: #fff!important;
      svg{
        visibility:visible;
      }
    }
  }

  &:hover {
    .pillCheckBoxCircle {
      border: 2px solid #ADADAD!important;
      background: #ADADAD!important;
      color: #fff!important;
      font-size: 14px;
      svg{
        visibility:visible;
      }
    }
  }

  &.disabled {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.2;
    cursor: not-allowed;
  }
}





body:global(.edge) {
  .subscriptionPill {
    &.active, &:hover {
      .pillCheckBoxCircle {
        border: 2px solid var(--primary-color)!important;
        background: var(--primary-color)!important;
        color: #fff!important;
        svg{
          visibility:visible;
        }
      }
    }
  }

  .card {
    &.active, &:hover {
      .checkBoxCircle {
        border: 2px solid var(--primary-color)!important;
        background: var(--primary-color)!important;
        color: #fff!important;
        font-size: 14px;
      }
    }
  }
}

