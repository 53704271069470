#partnerClinicFinder {
  .partnerClinicSearch {
    .searchForm {
      width:100%;

      input {
        width:85%;
        border:1px solid #3E3C3A;
        border-radius:10px;
        padding:20px;
      }

      .buttonContainer {
        width:15%;
      }

      button {
        height: 54px!important;
        width: 54px!important;
        border-radius:360px!important;
        background:#3E3C3A;
        border-color:#3E3C3A;
        &:hover {
          background:#5474E5!important;
          border-color:#5474E5!important;
        }
      }
    }
    
    .useLocation {
      background:none;
      color:#3E3C3A;
      &:hover {
        background: none!important;
        color: #5474E5!important;
      }
    }

    .selectAllBtn {
      font-style: italic;
      letter-spacing: normal;
      font-size: 12px;
      padding: 0px;
      margin-inline-start: 10px;
    }
  }

  .mapContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    min-height: 350px;
  }

  .rounded{
    border-radius: 50px;
    aspect-ratio: 1;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
  }

  .badge {
    font-size: 12px;
    font-weight: 500;
    background-color: var(--primary-color);
    padding: 1px 8px;
    border-radius: 15px;
    color: white;
  }

  .providersList {
    overflow-x: auto;
    scroll-snap-align: start;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    margin-bottom: 5px;

    &::-webkit-scrollbar {
      height: 5px;
    }



    @media screen and (max-width: 768px){
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}