/**
 * General
 *  1. Header
 *  2. Spinner
 */

/**
 * Header
 */

.navbar {
  padding: 0;
  .forth {
    fill: url(#linear-gradient);
  }
  .back-button {
    margin: 0 auto;
    margin-left: 0px;
    background: none;
    border: none;
    color: var(--font-color);
    font-size: 15px;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: column!important;
    justify-content: flex-start;
    &.higher-zindex {
      z-index:6001;
    }
  }
}

.signUpModal {
  cursor:pointer;
  width:100%;
}

.navbar-brand svg{
  width: 40px;
}

.navbar-brand img{
  width: 40px;
}


@media only screen and (max-width: 576px) {
  
  
  .navbar-brand svg{
    width: 30px;
  }

  .navbar-brand img{
    width: 30px;
  }
  
  #menu-toggle {
    z-index:999;
  }
  .navbar-brand {
    display: block;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    padding: 0;
    padding-left: 0!important;
    z-index:1;
    width: 150px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }

}

.navbar-toggler {
  border: 0;
}

.notification-dropdown {
  display: none;
}

.head-nav.navbar > .container {
  /* CB removed on 18/02/19. While navigation is small, noneed ot be full width

  max-width: 100%;
  padding-right: 0;*/
}

.nav-notification {
  display: inline-block;
  padding: 10px 20px;
  background: #2E2E2E;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.notifcation-circle {
  background: #767676;
  border-radius: 360px;
  color: #D9D9D9;
  width: 44px;
  height: 44px;
  text-align: center;
  position: relative;
  svg{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
  }
}

.notification-count {
  background: white;
  border-radius: 360px;
  width: 16px;
  height: 16px;
  color: #2e2e2e;
  font-size: 12px;
  position: absolute;
  right: -7px;
  top: 15px;
}

.btn-burger {
  background:none;
  border:none;
  color: var(--font-color) !important;

  .fa-bars {
    font-size: 20px;
  }
}

.basket-wrapper {
  width: 50px;
  height: 60px;
  position: relative;
  text-align:left;
  .basket {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color:white;
    font-size:22px;
    width:100%;
    .basket-total {
      text-align: center;
      position: absolute;
      right: 7px;
      background: red;
      border-radius: 360px;
      font-size: 12px;
      width: 20px;
      height: 20px;
      top: 17px;
      vertical-align: middle;
      line-height: 20px;
    }
  }
}

.dropdowns-wrapper {
  float: left;
  width: 86px;
  height: 60px;
}

.dropdown-container {
  display: inline-block;
  position: relative;
  .dropdown {
    position: relative;
    cursor: pointer;
    z-index: 2;
  }
  .dropdown-menu {
    position: absolute;
    display: none;
    z-index: 1;
    left: -100px;
    top: 80px;
    min-height: 10px;
    min-width: 10px;
    width: 280px;
    background: #2e2e2e;
    color:#ffffff;
    &.show {
      z-index:999;
    }
    .dropdown-header {
      background: #2e2e2e;
      padding: 15px;
      position: relative;
      text-align: center;
      color: #ffffff;
      font-weight: bold;
      border-radius: 10px 10px 0 0;
      border: 0px;
      border-style: solid;
      border-bottom-width: 1px;
      -moz-border-image: -moz-linear-gradient(right, white, #cedae0, #cedae0, white) 1 20%;
      -o-border-image: -o-linear-gradient(right, white, #cedae0, #cedae0, white) 1 20%;
      border-image: linear-gradient(to right, white 0%, #cedae0 40%, #cedae0 60%, white 100%) 1 20%;
      box-shadow: 0px 2px 10px -2px #cedae0;
      .triangle {
        position: absolute;
        top: -15px;
        left: 134px;
        height: 15px;
        width: 15px;
        border-radius: 6px 0px 0px 0px;
        transform: rotate(45deg);
        background: #FFF;
      }
      .count {
        position: static;
        height: 25px;
        width: 25px;
        display: inline-block;
        line-height: 24px;
        margin-left: 8px;
        font-size: 12px;
        vertical-align: middle;
      }
    }
    .dropdown-body {
      max-height: 292px;
      background: #e9f0f3;
      overflow-y: auto;
      overflow-x: hidden;
      .notification {
        background: #FFF;
        padding: 15px;
        border: 0px;
        border-style: solid;
        border-bottom-width: 1px;
        -moz-border-image: -moz-linear-gradient(right, white, #cedae0, #cedae0, white) 1 20%;
        -o-border-image: -o-linear-gradient(right, white, #cedae0, #cedae0, white) 1 20%;
        border-image: linear-gradient(to right, white 0%, #cedae0 40%, #cedae0 60%, white 100%) 1 20%;
        &.new {
          -webkit-transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          transition: all 500ms ease;
          background: #e9f0f3;
        }
        .notification-image-wrapper {
          display: table-cell;
          vertical-align: middle;
        }
        .notification-image {
          display: inline-block;
          height: 32px;
          width: 32px;
          overflow: hidden;
          border-radius: 100%;
        }
        .notification-text {
          display: table-cell;
          padding-left: 15px;
          vertical-align: middle;
          color: #747F8B;
          cursor: pointer;
          font-size: 14px;
          word-spacing: 2px;
          line-height: 21px;
          .highlight {
            font-weight: bold;
          }
        }
      }
    }
  }
  .fa {
    color: #9FB6C3;
    font-size: 22px;
  }
  .count {
    position: absolute;
    top: -6px;
    right: -1px;
    height: 15px;
    width: 15px;
    overflow: hidden;
    background: #21B7B7;
    color: #FFF;
    text-align: center;
    border-radius: 100%;
    font-size: 9px;
    font-weight: bold;
    line-height: 15px;
  }
}

#sidebar-wrapper {
  margin-left: 0;
  display:inline-block;
  position: fixed;
  width: 75vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  min-height: 80vh; 
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  color:white;
  background-color: var(--sidebar-bg-color);
  z-index: 9999;

  &::-webkit-scrollbar{
    display: none;
  }

  .brand{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px 0px 25px;

    svg{
      cursor: pointer;
    }

    img,
    svg{
      max-height: 48px;
    }

  }

  li {
    list-style:none;
  }

  .list-group  {
    margin-top: 20px;
    height: 87vh;
    overflow: hidden;
    padding: 0;

    a.nav-link, .logout {
      text-transform: capitalize;
      color:#ffffff;
      padding:8px 10px 8px 30px;
      margin:4px 0;
      font-size:16px;

      &.active {
        font-weight: 500;
        border-left:3px solid #ffffff;
        padding-left:27px;
      }

      &:not(.active) {
        color: var(--profile-font-color)
      }
    }

    .logout{
      border-radius: 10px;
      margin: 10px 10px;
      text-align: center;
      padding: 10px;
      background-color: var(--sidebar-logout-btn);
      opacity: 0.9;
      &:hover{
        opacity: 1;
      }
    }

    hr{
      width: 95%;
      margin-inline: auto;
      border-color: var(--footer-hr-color);
    }
  }


  .logout { 
    background:rgba(255,255,255,0.1);
    border:none !important;
    padding-top:10px !important;
    padding-bottom:10px !important;

    &:hover {
      cursor: pointer;
    }
  }

  .versionNumber {
    text-align: center;
    bottom: 5px;
    width: 100%;
    color: #e2e2e2;
    margin-top: auto;
    font-size: 10px;
    font-weight: 300;
  }
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
  width:0;
}

#page-content-wrapper {
  min-width: 100vw;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  // TODO: Check this margin 
  margin-left: 75vw;

  main {
    flex-grow: 1;
  }
}

#wrapper.toggled #page-content-wrapper {
  margin:0;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: -25rem;
}


.notifications {
  margin-right: 10px;
}

.messages {
  margin-left: 10px;
}

.user {
  background: #9FB6C3;
  border-left: 0px;
  position: absolute;
  right: 0;
  top: -1px;
}

.box {
  background-color: var(--box-bg-color);
  border-radius: 10px;
}

.box-bg-accent {
  background-color: var(--order-box-bg-accent-color);
  color: white;
}

.box-shadow {
  box-shadow: 0 2px 5px #0000001A;
}

.title-color {
  color: var(--title-color);
}

.biomarkerListTitle{
  color: var(--title-color);
  padding-top: 20px;
}

.user-options-wrapper {
  .user-image {
    background: url("http://www.apnatimepass.com/tom-cruise-ms.jpg");
    background-size: cover;
    background-position: center;
    vertical-align: bottom;
    height: 32px;
    width: 32px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 10px;
  }
  .user-options {
    vertical-align: bottom;
    border: 2px solid #FFF;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    display: inline-block;
    text-align: center;
    position: relative;
    .fa {
      position: absolute;
      top: 9px;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      color: #FFF;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .navbar > .container {
    justify-content: left;
    padding-right: 0;
  }
  .manage-account-container {
    width: 70px !important;
    background:none !important;
  }
  .navbar-nav .nav-item {
    padding: 0 15px;
  }
  .dropdown-menu {
    left: -130%;
  }

  .nav-tabs .nav-item { 
    text-align:center;
  }
}

.btn {
  white-space: normal;
  &:hover {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    color:white!important;
    background: #21B7B7!important;
 
  } 
}

.btn-burger {
  &:focus {
    background: none!important;
  }
  &:hover { 
    background: none!important;
  }
}

.body-image {
  /*background: url('http://stock-wallpapers.com/wp-content/uploads/2014/05/nexus_5_wall_8.jpg');*/
  background: #577597;
  max-width: 100%;
  height: 440px;
  background-size: cover;
  background-position: center;
  margin-top: -2px;
  padding: 30px;
  position: relative;
  border-radius: 2px;
  .instruction {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: none;
    color: #FFF;
    font-size: 18px;
    width: 100%;
    height: 20px;
    text-align: center;
  }
  .info {
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: rgba(0, 0, 0, 0.2);
    padding: 10px;
    ul {
      list-style: square;
      padding-right: 20px;
    }
  }
}

.wrapper .body-image .info ul li {
  margin-bottom: 13px;
  font-size: 14px;
  word-spacing: 2px;
  line-height: 21px;
  /*border-bottom: 1px solid rgba(255, 255, 255, 0.2);*/
}

.body-image .info .ok-btn-wrapper {
  text-align: center;
  position: relative;
  padding: 4px 0px 10px 0px;
  .ok-btn {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    .text {
      z-index: 1;
      position: relative;
    }
    .hover-overlay {
      -webkit-transition: all 500ms ease;
      -moz-transition: all 500ms ease;
      -ms-transition: all 500ms ease;
      -o-transition: all 500ms ease;
      transition: all 500ms ease;
      position: absolute;
      height: 100%;
      width: 0;
      left: 0;
      top: 0;
      background: #21B7B7;
      opacity: 0.6;
      z-index: 0;
    }
    &:hover .hover-overlay {
      width: 100%;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  webkit-box-shadow: inset 0 0 6px #E3EBEF;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #E3EBEF;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #9FB6C3;
  -webkit-box-shadow: none;
  &:window-inactive {
    background: #9FB6C3;
  }
}

.new.notification {
  &.ng-enter {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    margin-top: -20%;
    background: #dde6eb !important;
  }
  &.ng-enter-active {
    margin-top: 0;
  }
}

.animated {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}


@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}


.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}


@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}


.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}


@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}


.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

header:has(.manage-account){
  background-color:  var(--nav-bg-color);
}

.manage-account {
  height: 80px;
  background-color:  var(--nav-bg-color);
  
  &:hover {
    cursor: pointer;
  }

  .nav-link{
    color: var(--nav-color) !important;

    &.active{
      color: var(--nav-active-color) !important;
      font-weight: 500;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 10px;
    }
  }
}

.manage-account-container {
  height: 64px;
  width: 220px;
  background: var(--profile-bg-color);
  padding: 10px 15px;
  padding-top: 12px;
  border-radius: 10px;
  isolation: isolate;

  &:has(* > .show){
    border-radius: 10px 10px 0px 0px;
    background-color: var(--profile-bg-color-active);
  }

  .profilePicture {
    position: relative;
    width: 44px;
    height: 44px;
    border-radius: 360px;
    border: solid 1px transparent;
    background-size: cover !important;
    background-clip: padding-box;
    box-sizing: border-box;

    &.Male {
      background-image:url('../img/dpm.jpg');
    }
    &.Female {
      background-image:url('../img/dpf.jpg');
    }

    &:before{
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      margin: -1px;
      border-radius: inherit;
      background: linear-gradient(45deg, var(--profile-gradient-color-1), var(--profile-gradient-color-2));
      z-index: -1;
    }
  }

  .welcomeName {
    color: var(--nav-color);
    font-size: 11px;
    margin:3px 0;
    transition: color 0.15s ease-in-out;
    
    span{
      font-weight: 500;
    }

    &:has(~ .show){
      color: var(--profile-font-color)
    }
  }

  .manageAccount {
    display: flex;
    color: #ABABAB;
    white-space: nowrap;

    button{
      letter-spacing: normal !important;
    }
  }

  .dropdown-button{
    background: transparent;
    border: none;
    color: var(--nav-color) !important;
    position: relative;

    .chevron-icon::after {
      position: relative;
      content: '\F078';
      font-family: "Font Awesome 6 Pro";
      left: 2px;
      top: 0px;
      color: inherit;
      font-size: 10px;
    }

    &.show{
      color: var(--profile-font-color) !important;
      .chevron-icon::after {
        content: '\F077';
      }
    }
  }

  .dropdown-menu{
    background-color: var(--profile-bg-color-active);
    border: none;
    border-radius: 0px 0px 10px 10px;
    padding: 0px 10px;
    text-align: start;
    top: 90%;

    a{
      font-weight: 400;
      font-size: 14px;
      text-transform: capitalize;
      color: var(--profile-font-color) !important;
      transition: none;
      margin: 3px 0;
      &:hover{
        background-color: var(--profile-accent-color);
        border-radius: 10px;
      }
    }

    .nav-link.active{
      color: var(--profile-font-color) !important;
      text-decoration: none;
      background-color: var(--profile-accent-color);
      border-radius: 10px;
    }

    .logout{
      border-radius: 10px;
      text-align: center;
      font-weight: 500;
      margin: 10px 0px;
      letter-spacing: 1px;
      background-color: var(--profile-accent-color);
      text-transform: capitalize;
      opacity: 0.9;
      color: var(--profile-font-color) !important;

      &:hover{
        color: var(--profile-font-color) !important;
        opacity: 1;
      }
    }
  }
}

/**
 * Spinner
 */

#root > .loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.loader {
  .loader-spinner,
  .loader-spinner:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader-spinner {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(0, 0, 0, 0.2);
    border-right: 1.1em solid rgba(0, 0, 0, 0.2);
    border-bottom: 1.1em solid rgba(0, 0, 0, 0.2);
    border-left: 1.1em solid #000000;
    transform: translateZ(0);
    animation: spinner 1.1s infinite linear;
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

/**
 * Animated loader
 */

 .fullscreenloader {
  position:fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  width:100%;
  height:100%;
  background:url('../img/loadingAnimation.gif') center no-repeat #F0F2F3;
  background-size: 160px 160px;
  z-index: 9999;


 }

.tooltip > .tooltip-inner:not(.forth-partner-tooltip) {
  background-color: var(--accent-color);
}

.tooltip.bs-tooltip-top .tooltip-arrow:not(.forth-partner-tooltip)::before {
	border-top-color: var(--accent-color);
}

.tooltip.bs-tooltip-end .tooltip-arrow:not(.forth-partner-tooltip)::before {
	border-right-color: var(--accent-color);
}

.tooltip.bs-tooltip-start .tooltip-arrow:not(.forth-partner-tooltip)::before {
	border-left-color: var(--accent-color);
}

.tooltip.bs-tooltip-bottom .tooltip-arrow:not(.forth-partner-tooltip)::before {
	border-bottom-color: var(--accent-color);
}


.latestResultsArea {
  .quoteBlock {
    font-size:15px;
    line-height:21px;
    font-weight:300;
    margin:0px !important;
    padding:0px;
    height:220px !important;
    background-color: var(--box-bg-accent-color-lighter);
    border-radius: 10px;
  }

  .viewFullResults {
    margin-top:-5px;
  }

  .chartContainer {
    margin-top:50px;
  }

  .alert{
    font-size: 12px;
  }
}

.border {
  border-color:#F0F2F3;
}



@media only screen and (max-width: 576px) {
  .latestResultsArea .quoteBlock {
    height:auto !important;
  }
}



  .modal {
    img {
      max-width:100%;
    }
  }

.greyscale {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.errorPage {
  position:fixed;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  width:100%;
  height:100%;
  background:#F0F2F3;
  z-index: 7000;

  .container {
    padding: 60px 0 !important;

    .errorImage {
      margin-top:30px;
      max-width:300px;
    }
  }
}


@media only screen and (max-width: 576px) {
  .url-padding{  
    top: 4rem;
  } 
}

.blog-img-container {
  max-height: 200px;
  height:160px;
  overflow: hidden;
  background-size:cover;
}

.forthModal {
    background: var(--bg-color);
    color: var(--font-color);

    .close {
      padding:15px;
      :hover {
        color:#ffffff;
      }
    }

    .modal-header {
      display:block;
      text-align:center;
      border:none;
      padding:0;

      .modal-title {
        text-align: center;
        font-weight: 300;
        font-size: 20px;
        text-transform: uppercase;
        color: var(--title-color);
      }
    }

    .modal-content { 
      background:none;
      border:none;
      margin-top:45px;
      margin-bottom:45px;
      padding: 10px;
      
      p, ul, li {
        font-size:16px;
        line-height:21px;
        font-weight: 300;
        // color: var(--font-color);
      }

      ul {
        padding-bottom:5px;
        margin:0;
      }

      li {
        padding-bottom:10px;
        margin:0;
      }

      a {
        text-decoration:underline;

        &:hover {
          text-decoration:none
        }
      }

      .samplePosted {
        input {
          padding:15px 5px;
          text-align:center;
          background:none;
          border:1px solid var(--accent-color);
          color: var(--font-color);
          text-transform:uppercase;
        }

        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
          background:#60c2dc;
        }
      }

      .subscriptionStatus {
         -webkit-appearance: button;
         -webkit-border-radius: 2px;
         -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
         -webkit-padding-end: 20px;
         -webkit-padding-start: 2px;
         -webkit-user-select: none;
         background: url(../img/br_down.png) no-repeat right #ddd;
        -webkit-appearance: none;
        background-position-x: 270px;
        background-position: 97% center;
         background-repeat: no-repeat;
         border: 1px solid #AAA;
         color: #555;
         font-size: inherit;
         overflow: hidden;
         padding: 5px 10px;
         text-overflow: ellipsis;
         white-space: nowrap;
         width: 400px;
         padding: 10px;
         padding-right: 35px;

      }

      .textInput {
        -webkit-appearance: button;
        -webkit-border-radius: 2px;
        -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        -webkit-padding-end: 20px;
        -webkit-padding-start: 2px;
        -webkit-user-select: none; 
        -webkit-appearance: none;
        background-position-x: 270px;
        background-position: 97% center;
        background-repeat: no-repeat;
        border: 1px solid #AAA;
        color: #555;
        font-size: inherit;
        overflow: hidden;
        padding: 5px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 400px;
        padding: 10px;
        padding-right: 35px;
      }
    }
    .modal-footer {
      padding: 0;
      border-top: none;

      div span {
        margin: 0 auto;
        display: inline-block;
        width:100%;
      }

      // .btn {
      //   background:#faba25 !important;
      //   color:#ffffff !important;
      //   border-radius:0;
      //   border:none;

      //   &:hover {
      //     color:#59BBB2 !important ;
      //     background: #ffffff !important;
      //   }

      //   &.btn-secondary {
      //     background:#bfffff;
      //     color:#6fcacc !important;

      //     &:hover {
      //       color:#59BBB2 !important ;
      //       background: #ffffff !important;
      //     }
      //   }
      // }
    }
    
    .contrast{
      filter: contrast(0.3);
    }

    .closeLink {
      font-weight:700;
      text-decoration:underline;

      &:hover{
        cursor:pointer;
      }
    }
}

.receipt {
  font-size:18px;
  line-height:22px;
  text-align:center;
  &.customerName {
    p {
      margin-bottom:0px!important;
    }
  }
}

.forth-table, .forth-table td, .forth-table th, .forth-table tr , .forth-table thead {
  border:none;
}

.thead-forth {
  background: var(--accent-color);
  color:#ffffff;
  
  th{
    font-weight: 500;
  }

  &+tbody{
    td{
      vertical-align: middle;
    }

    tr:last-child(){
      border-color: transparent;
    }
  }
}

.priceArea, .priceArea td, .priceArea th {
  background:#efefef;
}

.slick-arrow.slick-disabled {
  display: none!important;
}
.slick-slider.h-100 {
    
    .slick-list {

      height:100%;

      .slick-track {

        height:100%;

        .slick-slide {

          height:100%;

          >div{
            height:100%;
          }
        }
      }
    }

}

.close {
  float: right;
  font-size: 0px;
  font-weight: 700;
  line-height: 0.9;
  color: rgab(255,255,255,0);
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  position: absolute;
  right: 15px;
  background: transparent;
  border: none;
  text-decoration: none;

  &:after {
    position:relative;
    content:'\f057';
    font-family: "Font Awesome 6 Pro";
    top:0px;
    right:0px;
    color: var(--accent-color);
    font-size:30px;
  }  

  &:hover {
    color:#ffffff;
  }
}


.alert {
  padding:5px 15px;
}

.alert-dismissible .close {
  padding-top:0px;
  padding-bottom:0px; 
}


@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus, textarea:focus, input:focus {
    font-size: 16px;
  }
}


.form-check-input{
  background-color: #F0F2F3;
  border-color: var(--primary-color-hover);
  font-size: 14px;
  margin-left: -1em;

  &:checked{
    border-color: var(--primary-color-hover);
    background-color: var(--primary-color);
  }

  &:focus{
    background-color: #F0F2F3;
    border-color: var(--primary-color-hover);
    box-shadow: none;
    font-size: 14px;
    outline: 0;

    &:checked{
      background-color: var(--primary-color);
    }
  }
}

 .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}

.custom-checkbox{
  accent-color: green;
  transform: scale(1.3);
  flex: 0 0 auto;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.custom-switch {
    padding-left: 2.25rem;
}
.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #4CAF50;
    background-color: #4CAF50;
}
.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem;
}
.custom-control-label::before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(.75rem);
    transform: translateX(.75rem);
}
.custom-switch .custom-control-label::after {
    top: calc(.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #efeded;
    border-radius: .5rem;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
}
.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}

.chartContainer {
  z-index:997;
}

.card-body.backgroud-dark {
  background:#f5f5f5;
  color: #2c2c2c;
}

.card-horizontal {
  // background:#f5f5f5;
  display: flex;
  flex: 1 1 auto;
  .img-square-wrapper {
    border-right: 5px solid white;
    max-width:33%;
    padding: 15px 0;
  }
}

.card {
  border-radius: 10px;
  overflow: hidden;
  .img-square-wrapper {
    padding: 15px 0;
  }

}

.vh-90 {
  height: 90vh!important;
}

.text-warning {
  color: #fc8e0e !important;
  font-weight: bold;
}

.creditBalance {
  font-size: 18px;
  font-weight: 300;

  .balanceText {
    font-size: 50px;
    color: var(--primary-color);
  }

  small{
    font-size: 25px;
  }
}

.creditCTA {
  .btn {
    text-transform: uppercase;
    text-align: center;
    background: #FAA240;
    color: white;
    border-radius: 100px;
    padding: 10px 15px;
    font-size: 16px;
    transition: .5s;
    border:0;
    &:hover {
      background: #c98234!important;
      color: white;
      cursor: pointer;
    }
  }
}

.edge .creditCTA {
  .btn {
    background: #ec4f32!important;
    color:white!important;
    &:hover { 
      color: white;
      cursor: pointer;
    }
  }
}




.modal.top .modal-dialog,
.modal.bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Top and Bottom */

.modal-dialog{
  width: 90%;
  margin: 1.75rem auto;
}

.viewMarkers{
  max-width: 90%;
}

@media(min-width: 960px) {
  .viewMarkers{
    width: 50%;
    max-width: 90%;
  }
}
@media(max-width: 768px) {

  .modal.top .modal-dialog,
  .modal.bottom .modal-dialog {
    position: fixed;
    margin: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  
  .modal.top .modal-content,
  .modal.bottom .modal-content {
    height: auto;
    overflow-y: auto;
  }
  
  .modal.top .modal-body,
  .modal.bottom .modal-body {
    padding: 15px 15px;
  }
  
  /* Top */
  
  .modal.top.fade .modal-dialog {
    top: -100%;
    -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
    -o-transition: opacity 0.3s linear, top 0.3s ease-out;
    transition: opacity 0.3s linear, top 0.3s ease-out;
  }
  
  .modal.top.fade.show .modal-dialog {
    top: 0;
  }
  
  /* Bottom */
  
  .modal.bottom {
    .modal-header {
      background: #F4F4F4;
      .close {
        padding: 1.3rem 1rem;
        margin: -1rem -1rem -1rem auto;
        top: 5px;
        right: 20;
        color: #23B7B7;
        opacity: 1;
        font-size: 14px;
        &:after {
          content:'';
        }  
      }
      .modal-title {
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 2px;
      }
    }
    .modal-body {
      height: 85vh;
      overflow-y: scroll;
    }
    .modal-content {
      border-radius: 20px 20px 0px 0px;
      border: none;
    }
  }
  
  .modal.bottom.fade .modal-dialog {
    bottom: -100%;
    -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
    transition: opacity 0.3s linear, bottom 0.3s ease-out;
  }
  
  .modal.bottom.fade.show .modal-dialog {
    bottom: -30px;
  }
  
  .modal.bottom.fade .modal-dialog {
    bottom: -100%;
  }
  
  
  
  /* Footer */
  
  .modal-footer-fixed {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-radius: 0;
  }
}

.viewBiomarkers{
  font-size: 12px;
}
  
label{
  margin-bottom: 0.5rem;
}

.tabbable .nav-tabs .nav-link {
  border: none;
}