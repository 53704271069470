.explainer {
  color: #797979;
  background: #F6F7F9;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-weight: 500;
}

.cancelRemove {
    background: var(--primary-color) !important; 
    color: #fff !important;
    &:hover { 
      color: #fff !important; 
      background:#222 !important;
    }
  }

.userForm {
  label {
    font-size: 12px;
    width: 100%;
    display: block;
    font-weight: 500;

    &[helpercolor="red"] {
      color: var(--red);
    }

    &[helpercolor="green"] {
      color: var(--green);
    }
  }

  input, select {
    position:relative;
    color: #000000!important;
    border: 1px solid #E1E2E6;
    border-radius: 10px;

    &[helpercolor="red"] {
      outline: 2px solid var(--red);
      &+svg{
        color: var(--red);
      }
    }

    &[helpercolor="green"] {
      outline: 2px solid var(--green);
      &+svg{
        color: var(--green);
      }
    }

    &+svg {
      position:absolute;
      top: 50%;
      transform: translateY(50%);
      right: 10px;
    }
  }

  .alignCenter {
    margin-top: -0.75rem;
    margin-right: 0.80rem;
  }

  select:focus, input:focus {
    background-color: #f2f2f2;
    border-color: var(--primary-color);
  }

   
  
  .removeFromCart {
    color: #fff !important; 
    background: var(--primary-color-hover) !important;
    &:hover { 
      background: #fff !important; 
      color: var(--primary-color-hover) !important;
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #797979;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #797979;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #797979;
  }

  .styledCheckbox {
    background:#fff;
    border-radius:10px;
    padding:15px 10px;
    cursor:pointer;
    border: 1px solid #E1E2E6;

    h5 {
      font-size: 14px;
      font-weight: 400;
    } 

    .checkBoxCircle {
      border: 2px solid #ADADAD;
      border-radius: 360px;
      width: 20px;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 12px;
      }
    }

    &.active {
      border: 1px solid var(--primary-color);
      .checkBoxCircle {
        border: 2px solid var(--primary-color);
        background: var(--primary-color);
        color: #fff;
      }
      .user_tickBoxes{
        svg{
          color: var(--primary-color);
        }
      }
    }

    &:not(.active):hover {
      .checkBoxCircle {
        position: relative;
        border: 2px solid #ADADAD!important;
        background: #ADADAD!important;
        color: #fff!important;
        font-size: 14px;
  
        &::after{
          content: '\f00c';
          position: absolute;
          width: 14px;
          font-family: "Font Awesome 6 Pro";
          right: 50%;
          top: 50%;
          transform: translateX(50%) translateY(-50%);
          color: #fff;
          font-size: 14px;
        }
      }
    }

    &.selected{
      border: 1px solid var(--primary-color);
      svg {
        color: var(--primary-color);
      }
    }

    &.hover:hover i::before{
      content: '\f14a';
      font-family: "Font Awesome 6 Pro";
      color: #ADADAD;
    }

    &.unselected{
      svg {
        color: #ADADAD;
      }
    }

    &.disabled{
      filter: grayscale(1);
      pointer-events: none;
    }
  }
  .user_tickBoxes{
    svg{
      font-size: 20px;
    }
  }

}
 
.inputPassword {
  border-radius: 10px 0 0 10px !important;
}