#tabbed-results {
  width: 99.9vw;
  background: #FAFBFB;
  padding: 30px 0px;
  position: relative;
  left: 50%;  
  margin-left: -50vw;
  #biomarkers {
    .col-lg-9 {
      width: 100%!important;
      max-width: 100%!important;
      flex: 0 0 100%;
    }
  }
  .nav-tabs {
    border: 0;
    .nav-link, .nav-item.show .nav-link {
      background-color: #fafbfb;
      border: 0;
      color: var(--title-color);
      text-transform: uppercase;
      margin: 0 10px;
      font-weight:500;
      font-size:16px;
    }
    .nav-link.active {
      background-color: #fafbfb;
      border: 0;
      color: var(--title-color);;
      text-transform: uppercase;
      margin: 0 10px;
      border-bottom: 2px solid var(--title-color);
    }
  }
  .tab-title {
    color: var(--title-color);
    font-size: 16px;
  }
  .tab-date {
    color: #767676;
    font-size: 11px;
  }
}


#category-details .markerCategory, #tabbed-results .markerCategory {
  font-size: 16px;
  padding: 15px 0px;
  border-bottom: 3px solid #DBDFE1;
}

#category-details .test-name, 
#tabbed-results .test-name {
  color: var(--title-color);
  font-size: 15px;
}

#category-details .test-date {
  font-size: 11px;
  margin-top: 5px;
}

#tabbed-results {
  .test-date {
    font-size: 11px;
    margin-top: 5px;
    color:#404040;
  }
  .marker .chartDrop {
    width: 40px;
    .chartValue {
      padding-top: 13px !important;
    }
  }
  .marker-value {
    font-size: 14px;
  }
  .resultLink:hover {
    text-decoration:none;
  }
}

#category-details .marker-result, #tabbed-results .marker-result {
  font-size: 11px;
  color: #707070;
  width:100%;
}

#category-details .marker-value.green, #tabbed-results .marker-value.green {
  color: #4AB724;
}

#category-details .marker-value.orange, #tabbed-results .marker-value.orange {
  color: #FC8E0F;
}

#category-details .marker-value.red, #tabbed-results .marker-value.red {
  color: #FF0000;
}

#category-details .arrow:after, #tabbed-results .arrow:after {
  content: '\203A';
  position: absolute;
  right: 30px;
  top: -20px;
  font-size: 40px;
  color: var(--primary-color);
}

@media only screen and (max-width: 992px) {
  #category-details .arrow:after, #tabbed-results .arrow:after {
    right: 15px;
  }
}

@media only screen and (max-width: 576px) {
  #category-details .arrow:after, #tabbed-results .arrow:after {
    right: 115px;
  }

  #category-details .arrow a, #tabbed-results .arrow a {
    display:block;
    text-align:right;
    padding-right:20px;
  }

  #category-details .marker-result, #tabbed-results .marker-result {
    display:inline-block;
  }
}

#category-details .untested-biomarker, #tabbed-results .untested-biomarker {
  background: #F4F6F6;
  padding: 15px;
  margin:0 5px;
}

#category-details .untested-biomarker .btn, #tabbed-results .untested-biomarker .btn {
  margin:0 5px 0 0;
  display: inline-block;
  margin-top: 5px;
}


