.backToShopButton {
  text-align: center;
  font-weight:500;
  display:block;
  color: var(--primary-color); 
  font-size: 14px;
} 

.scrollButton {
  height: 55px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  user-select: none;
  bottom: 0;
  animation: slideUp 0.1s forwards linear;

  &:hover{
    background-color: var(--primary-color-hover);
  }
}

@keyframes slideUp {
  from{
    opacity: 0;
    transform: translateY(100%)
  }
  to{
    opacity: 1;
    transform: translateY(0%)
  }
}